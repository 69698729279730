
import {
    FAILURE,
    REQUEST,
    SUCCESS,
    MAIN_LIST_SUCCESS,
    NEWS_DETAIL_SUCCESS,
    NEWS_LIST_SUCCESS,
    ABOUT_US_SUCCESS
  } from '../Types/appTypes'
const ghostState = {
    loading: false,
    mainlist: {},
    newsdetail:{},
    newslist:{},
    messages: {
      messagetext: "",
      messagestatus: ""
    },
    aboutinfo:{}
};
const ghostreducer = (state = ghostState, action) => {
    switch (action.type) {
        case REQUEST:
            return {
                ...state,
                loading: true,
                messages: {
                  messagetext: "",
                  messagestatus: ""
                }
            }
        case SUCCESS: 
        const successState = {
            ...state,
            loading: false,
            messages: {
              messagetext: action.payload.messagetext,
              messagestatus: action.payload.messagestatus
            }
        }
        localStorage.setItem("ghost", JSON.stringify(successState))
            return successState
        case FAILURE:
            return {
                ...state,
                loading: false,
                messages: {
                  messagetext: action.payload.messagetext,
                  messagestatus: action.payload.messagestatus
                }
            }
        case MAIN_LIST_SUCCESS:
          const mainlistAppState = {
            ...state,
            loading: false,
            mainlist: action.payload.list,
            messages: {
              messagetext: action.payload.messagetext,
              messagestatus: action.payload.messagestatus
            }
          };
          localStorage.setItem("ghost", JSON.stringify(mainlistAppState));
        return mainlistAppState
        case NEWS_DETAIL_SUCCESS:
          const newsdetailAppState = {
            ...state,
            loading: false,
            newsdetail: action.payload.newsdetail,
            messages: {
              messagetext: action.payload.messagetext,
              messagestatus: action.payload.messagestatus
            }
          };
          localStorage.setItem("ghost", JSON.stringify(newsdetailAppState));
        return newsdetailAppState
        case NEWS_LIST_SUCCESS:
          const newslistlAppState = {
            ...state,
            loading: false,
            newslist: action.payload.newslist,
            messages: {
              messagetext: action.payload.messagetext,
              messagestatus: action.payload.messagestatus
            }
          };
          localStorage.setItem("ghost", JSON.stringify(newslistlAppState));
        return newslistlAppState
        case ABOUT_US_SUCCESS:
          const aboutinfoAppState = {
            ...state,
            loading: false,
            aboutinfo: action.payload,
            messages: {
              messagetext: "The operation was completed successfully!",
              messagestatus: "mute"
            }
          };
          localStorage.setItem("ghost", JSON.stringify(aboutinfoAppState));
        return aboutinfoAppState
        default: return state
    }
}
export default ghostreducer