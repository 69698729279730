import React, { useEffect, useState } from "react";
import { FaUserGraduate, FaUniversity, FaRegEdit } from "react-icons/fa";
import { BiTimeFive } from "react-icons/bi";
import { IoLocationOutline } from "react-icons/io5";
import { GiPhone } from "react-icons/gi";
import { MdEmail, MdWork, MdOutlineAddPhotoAlternate } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import cliTruncate from "cli-truncate";
import { Link } from "react-router-dom";
import axios from "axios";
import { ProfileAction } from "../redux/Action/appAction";
import { useTranslation } from "react-i18next";
import useScrollBlock from "../hooks/scrollBlock";
import ViewImage from "../components/ViewImage";

const StudentProfile = () => {
  const [blockScroll, allowScroll] = useScrollBlock();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { studentuser } = useSelector((state) => state.app);
  const [userEdit, setUserEdit] = useState(false);
  const [userPhoto, setUserPhoto] = useState(null);
  const [viewImage, setViewImage] = useState("");
  const [toggleViewImage, setToggleViewImage] = useState(false);
  const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];
  useEffect(() => {
    dispatch(ProfileAction());
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (toggleViewImage) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [toggleViewImage]);

  function PrintYearMonth(start, end) {
    if (start && end) {
      const years = moment(end).diff(moment(start), "years");
      const months = moment(end).diff(moment(start), "months");
      if (years > 0) {
        if (months > 0) {
          return `(${years} - yil,  ${months - years * 12} - oy)`;
        } else {
          return `(${years} - yil)`;
        }
      } else if (months > 0) {
        return `(${months} - oy)`;
      }
    }
  }


  return (
    <>
      {toggleViewImage && (
        <ViewImage image={viewImage} calbackFunc={setToggleViewImage} />
      )}
      <div
        className="container mx-auto flex flex-col xl:flex-row gap-[15px] my-[30px]"
        data-aos="fade-down"
      >
        {/* SIDEBAR */}
        <div className="flex flex-col w-full xl:w-3/12">
          <div className="relative h-min flex flex-col items-center rounded-sm bg-white-light p-[15px]">
            {/* edit-button */}
            <div className="absolute top-0 right-0 p-[5px]">
              <FaRegEdit
                onClick={() => setUserEdit(!userEdit)}
                className="text-grey xl-text hover:text-blue-light cursor-pointer"
              />
            </div>
            {/* image */}
            <div
              style={
                userPhoto && userEdit
                  ? {
                      backgroundImage: `url(${URL.createObjectURL(userPhoto)})`,
                    }
                  : { backgroundImage: `url(${studentuser?.image})` }
              }
              className={`${
                userEdit && "cursor-pointer"
              } w-[100px] h-[100px] relative flex justify-center items-center rounded-full bg-cover bg-center bg-grey-light my-[15px] group cursor-pointer`}
              onClick={() => {
                if (studentuser?.image) {
                  setViewImage(studentuser?.image);
                  !userEdit && setToggleViewImage(true);
                }
              }}
            >
              {!studentuser?.image && (
                <FaUserGraduate className="text-grey xl2-text" />
              )}
              {userEdit && (
                <>
                  <MdOutlineAddPhotoAlternate className="xl2-text text-white group-hover:text-white-light" />
                  <input
                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                    type="file"
                    onChange={(e) => setUserPhoto(e.target.files[0])}
                  />
                </>
              )}
            </div>
            {/* info */}
            <div className="text-center pb-[10px] border-b border-grey-light drop-shadow-sm">
              <h3 className="lg-text text-grey font-semibold">
                {studentuser?.full_name}
              </h3>
              <p className="md-text mt-[5px]">
                {studentuser?.faculty} ({studentuser?.major})
              </p>
            </div>
            {/* personal info */}
            <div className="w-full sm:w-1/2 xl:w-full flex flex-col">
              <div className="w-full flex justify-between items-center mt-[10px]">
                <div className="flex flex-col">
                  <p className="sm-text text-grey">{t("studentProfile.age")}</p>
                  <span className="sm-text text-black">
                    {moment().diff(`${studentuser?.birth}`, "years")}{" "}
                    {t("studentProfile.age")}
                  </span>
                </div>
                <div className="border border-grey p-[5px] rounded-full">
                  <BiTimeFive className="xl-text text-grey" />
                </div>
              </div>
              <div className="w-full flex justify-between items-center mt-[10px]">
                <div className="flex flex-col">
                  <p className="sm-text text-grey">
                    {t("studentProfile.location")}
                  </p>
                  <span className="sm-text text-black">
                    {studentuser?.current_address}
                  </span>
                </div>
                <div className="border border-grey p-[5px] rounded-full">
                  <IoLocationOutline className="xl-text text-grey" />
                </div>
              </div>
              {/* switch */}
              <div className={`${userEdit ? "hidden" : ""}`}>
                <div className="w-full flex justify-between items-center mt-[10px]">
                  <div className="flex flex-col">
                    <p className="sm-text text-grey">
                      {t("studentProfile.mail")}
                    </p>
                    <span className="sm-text text-black">
                      {studentuser?.email}
                    </span>
                  </div>
                  <div className="border border-grey p-[5px] rounded-full">
                    <MdEmail className="xl-text text-grey" />
                  </div>
                </div>
                <div className="w-full flex justify-between items-center mt-[10px]">
                  <div className="flex flex-col">
                    <p className="sm-text text-grey">
                      {t("studentProfile.phone")}
                    </p>
                    <span className="sm-text text-black">
                      {studentuser?.phone_number}
                    </span>
                  </div>
                  <div className="border border-grey p-[5px] rounded-full">
                    <GiPhone className="xl-text text-grey" />
                  </div>
                </div>
              </div>
              {/* /switch */}
              {/* switch to edit */}
              <Formik
                initialValues={{
                  phone_number: studentuser?.phone_number,
                  email: studentuser?.email,
                }}
                validationSchema={Yup.object().shape({
                  phone_number: Yup.string(),
                  email: Yup.string().email("Email is invalid"),
                })}
                onSubmit={(fields) => {
                  const formData = new FormData();
                  fields.phone_number &&
                    formData.append("phone_number", fields.phone_number);
                  fields.email && formData.append("email", fields.email);
                  userPhoto && formData.append("image", userPhoto);
                  axios
                    .patch(
                      "en/api/s1/student-update/" + studentuser?.id + "/",
                      formData
                    )
                    .then((res) => {
                      dispatch(ProfileAction());
                      setUserEdit(false);
                    })
                    .catch((err) => {
                    });
                }}
              >
                {({ errors, touched, handleBlur, handleChange }) => (
                  <Form className={`${userEdit ? "" : "hidden"}`}>
                    <div className="w-full flex justify-between items-end mt-[10px]">
                      <div className="flex flex-col w-full">
                        <p className="sm-text text-grey">
                          {t("studentProfile.mail")}
                        </p>
                        <Field
                          name="email"
                          type="email"
                          placeholder={`${studentuser?.email}`}
                          className={
                            "w-[95%] focus:outline-blue-light focus:border-r-0 border border-grey-light rounded-sm py-[3px] px-[5px] mt-[5px]" +
                            (errors.email && touched.email ? " border-red" : "")
                          }
                        />
                      </div>
                      <div className="border border-grey p-[5px] rounded-full">
                        <MdEmail className="xl-text text-grey" />
                      </div>
                    </div>
                    <div className="w-full flex justify-between items-end mt-[10px]">
                      <div className="flex flex-col w-full">
                        <p className="sm-text text-grey">
                          {t("studentProfile.phone")}
                        </p>
                        <Field name="phone_number">
                          {({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={phoneNumberMask}
                              id="phone"
                              placeholder={`${studentuser?.phone_number}`}
                              type="tel"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                "w-[95%] focus:outline-blue-light focus:border-r-0 border border-grey-light rounded-sm py-[3px] px-[5px] mt-[5px]" +
                                (errors.phone_number && touched.phone_number
                                  ? " border-red"
                                  : "")
                              }
                            />
                          )}
                        </Field>
                      </div>
                      <div className="border border-grey p-[5px] rounded-full">
                        <GiPhone className="xl-text text-grey" />
                      </div>
                    </div>
                    {/* submit */}
                    <div className="col-span-1 lg:col-span-2">
                      <button
                        type="submit"
                        className="w-full bg-blue-white hover:bg-blue-light text-white border mt-[15px] border-grey-light py-[5px] px-[10px] rounded-sm transition"
                      >
                        {t("buttons.save")}
                      </button>
                      <button
                        onClick={() => setUserEdit(false)}
                        type="reset"
                        className="w-full bg-red-dark hover:bg-red text-white border mt-[15px] border-grey-light py-[5px] px-[10px] rounded-sm transition"
                      >
                        {t("buttons.cancel")}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          {/* Applyed vacancies */}
          <div className="my-[15px]">
            <h3 className="text-center lg-text font-semibold">
              {t("studentProfile.appliedvacancies")}
            </h3>
            {studentuser?.vacancy?.length ? (
              studentuser?.vacancy?.map((vacancy) => {
                return (
                  <div
                    key={vacancy.id}
                    className="border border-grey-light my-[15px] px-[15px] hover:border-blue-light group"
                  >
                    <Link to={`/offers/job${vacancy.id}`}>
                      <div className="md-text group-hover:text-blue-light">
                        {vacancy.title}
                      </div>
                      <div className="lg-text">{vacancy.employer.name}</div>
                      <div className="md-text">{vacancy.salary} uzs</div>
                      <div className="sm-text">{vacancy.region}</div>
                      <div className="xs-text">{vacancy.updated_at}</div>
                    </Link>
                  </div>
                );
              })
            ) : (
              <div className="md-text text-center">
                Ishlar ko'rib chiqilmagan{" "}
                <Link to={"/offers"} className="underline text-blue-light">
                  Ishlar
                </Link>
              </div>
            )}
          </div>
        </div>
        {/* MAIN */}
        <main
          className="relative w-full flex flex-col bg-white-light pb-[30px] px-[15px]"
          id="resume"
        >
          {/* edit-button */}
          <h2 className="xl2-text font-bold text-blue-light mb-[15px]">
            Resume
          </h2>
          {studentuser?.student_resume ? (
            <>
              <Link to={"/editCV"} className="absolute top-0 right-0 p-[5px]">
                <FaRegEdit className="text-grey xl-text hover:text-blue-light cursor-pointer" />
              </Link>
              <div>
                {studentuser?.student_resume?.image && (
                  <div
                    style={{
                      backgroundImage: `url(${studentuser?.student_resume?.image})`,
                    }}
                    className="w-[250px] h-[250px] border border-grey-light rounded-full mb-[30px] bg-cover bg- bg-center cursor-pointer"
                    onClick={() => {
                      setViewImage(studentuser?.student_resume?.image);
                      studentuser?.student_resume?.image &&
                        setToggleViewImage(true);
                    }}
                  ></div>
                )}
                <div className="w-full flex flex-col mb-[30px]">
                  <h4 className="xl-text font-semibold">O‘zim haqimda</h4>
                  <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                  <p className="text-grey my-[5px]">
                    {studentuser?.student_resume?.description}
                  </p>
                </div>
                {/*  */}
                <div className="w-full flex flex-col mb-[30px]">
                  <h4 className="xl-text font-semibold">Mahorat</h4>
                  <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                  <div className="flex gap-[15px]">
                    <div className="text-white w-max my-[5px] p-[5px] rounded-[20px] bg-teal-dark">
                      {studentuser?.student_resume?.skill}
                    </div>
                    {/* <div className="text-white w-max my-[5px] p-[5px] rounded-[20px] bg-teal-dark">
                Ko'nikmalar Tashkiliy
              </div> */}
                  </div>
                </div>
                {/*  */}
                <div className="w-full flex flex-col mb-[30px]">
                  <h4 className="xl-text font-semibold">
                    Til bilish ko’nikmasi
                  </h4>
                  <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                  <div className="flex gap-[15px] text-grey">
                    <div className="text-white w-max my-[5px] p-[5px] rounded-[20px] bg-teal-dark">
                      {studentuser?.student_resume?.foreign_language}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="w-full flex flex-col mb-[30px]">
                  <h4 className="xl-text font-semibold">Xobbi</h4>
                  <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                  <p className="text-grey my-[5px]">
                    <div className="text-white w-max my-[5px] p-[5px] rounded-[20px] bg-teal-dark">
                      {studentuser?.student_resume?.hobby}
                    </div>
                  </p>
                </div>
                {/* current_address */}
                <div className="w-full flex flex-col mb-[30px]">
                  <h4 className="xl-text font-semibold">Manzil</h4>
                  <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                  <p className="text-grey my-[5px]">
                    {studentuser?.student_resume?.current_address}
                  </p>
                </div>
                {/* email */}
                <div className="w-full flex flex-col mb-[30px]">
                  <h4 className="xl-text font-semibold">Elektron pochta</h4>
                  <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                  <p className="text-grey my-[5px]">
                    {studentuser?.student_resume?.email}
                  </p>
                </div>
                {/* phone_number */}
                <div className="w-full flex flex-col mb-[30px]">
                  <h4 className="xl-text font-semibold">Telefon raqam</h4>
                  <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                  <a
                    tel={studentuser?.student_resume?.phone}
                    className="w-max text-grey my-[5px] hover:text-blue hover:underline cursor-pointer"
                  >
                    {studentuser?.student_resume?.phone}
                  </a>
                </div>
                {/* links */}
                <div className="w-full flex flex-col mb-[30px] overflow-hidden">
                  <h4 className="xl-text font-semibold">Bog'lanish</h4>
                  <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                  <div className="flex items-center">
                    <div className="md-text mr-[5px]">Linkedin:</div>
                    <a
                      href={studentuser?.student_resume?.linkedin}
                      className="text-grey my-[5px] w-max hover:text-blue hover:underline"
                    >
                      {studentuser?.student_resume?.linkedin &&
                        cliTruncate(studentuser?.student_resume?.linkedin, 50)}
                    </a>
                  </div>
                  <div className="flex items-center">
                    <div className="md-text mr-[5px]">Telegram:</div>
                    <a
                      href={studentuser?.student_resume?.telegram}
                      className="text-grey my-[5px] w-max hover:text-blue hover:underline"
                    >
                      {studentuser?.student_resume?.telegram &&
                        cliTruncate(studentuser?.student_resume?.telegram, 50)}
                    </a>
                  </div>
                  <div className="flex items-center">
                    <div className="md-text mr-[5px]">Resume:</div>
                    <a
                      href={studentuser?.student_resume?.portfolio_link}
                      className="text-grey my-[5px] w-max hover:text-blue hover:underline"
                    >
                      {studentuser?.student_resume?.portfolio_link &&
                        cliTruncate(
                          studentuser?.student_resume?.portfolio_link,
                          50
                        )}
                    </a>
                  </div>
                </div>
                {/* education */}
                <div className="w-full flex flex-col mb-[30px]">
                  <h4 className="xl-text font-semibold">Taʼlim</h4>
                  <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                  {studentuser?.student_resume?.resume_education?.map(
                    (education, index) => {
                      return (
                        <div key={index} className="flex mt-[10px]">
                          <div className="h-min bg-grey-light p-[10px] rounded-sm">
                            <FaUniversity className="text-grey xl2-text" />
                          </div>
                          <div className="flex flex-col ml-[10px]">
                            <h4 className="xl-text font-semibold">
                              {education.unv_title}
                            </h4>
                            <p className="text-grey">
                              {education.specialty} ({education.degree})
                            </p>
                            <div className="flex sm-text text-grey">
                              <span>{education.begin_date}</span>
                              <span className="mx-[5px] block">-</span>
                              <span>
                                {education.status
                                  ? "H.V"
                                  : education.finish_date}
                              </span>
                              <span className="ml-[5px]">
                                {PrintYearMonth(
                                  education.begin_date,
                                  education.finish_date
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                {/* experiance */}
                <div className="w-full flex flex-col mb-[30px]">
                  <h4 className="xl-text font-semibold">Tajriba</h4>
                  <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                  {studentuser?.student_resume?.resume_experience?.map(
                    (experiance, index) => {
                      return (
                        <div key={index} className="flex mt-[10px]">
                          <div className="h-min bg-grey-light p-[10px] rounded-sm">
                            <MdWork className="text-grey xl2-text" />
                          </div>
                          <div className="flex flex-col ml-[10px]">
                            <h4 className="xl-text font-semibold">
                              {experiance.title}
                            </h4>
                            <p className="text-grey">{experiance.specialty}</p>
                            <div className="flex sm-text text-grey">
                              <span>{experiance.begin_date}</span>
                              <span className="mx-[5px] block">-</span>
                              <span>
                                {experiance.status
                                  ? "H.V"
                                  : experiance.finish_date}
                              </span>
                              <span className="ml-[5px]">
                                {PrintYearMonth(
                                  experiance.begin_date,
                                  experiance.finish_date
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </>
          ) : (
            <div>
              <p>{t("studentProfile.notuploaded")}</p>
              <Link
                to="/createCv"
                className="text-blue-light hover:text-blue hover:underline"
              >
                {t("studentProfile.createresume")}
              </Link>
            </div>
          )}
        </main>
      </div>
    </>
  );
};

export default StudentProfile;
