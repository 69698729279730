import React, { useEffect, useState } from "react";
import { FaUserGraduate } from "react-icons/fa";
import { Link } from "react-router-dom";
import Selections from "../components/Selections";
import { useSelector, useDispatch } from "react-redux";
import { courses, degrees, coursesMag } from "../static";
import {
  SearchAction,
  FilterAction,
  RegionAction,
  FacultyListAction,
  MajorListAction,
  DistrictListAction,
} from "../redux";
import Input from "../components/Input";
import { IoIosSearch } from "react-icons/io";
import Pagination from "rc-pagination";
import { useTranslation } from "react-i18next";

const Candidates = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [course, setCourse] = useState(null);
  const [faculty, setFaculty] = useState(null);
  const [degree, setDegree] = useState(null);
  const [region, setRegion] = useState(null);
  const [district, setDistrict] = useState([]);
  const [studentDistrict, setStudentDistrict] = useState("");
  const [major, setMajor] = useState("");
  const [studentMajor, setStudentMajor] = useState("");
  const students = useSelector((state) => state.app?.studentusers);
  const regions = useSelector((state) => state.app?.region);
  const faculties = useSelector((state) => state.app?.faculty);
  const districts = useSelector((state) => state.app?.district);
  const majors = useSelector((state) => state.app?.major);
  console.log(course, faculty, degree, district);
  useEffect(() => {
    let data = [];
    if (districts) {
      data = districts?.results?.filter((item) => item.title === region);
    }
    data?.length && setDistrict(data[0].district_student);
    setStudentDistrict("");
  }, [region]);
  useEffect(() => {
    sortMajors();
  }, [faculty]);
  async function sortMajors() {
    const data =
      (await majors.count) > 0
        ? majors?.results?.filter((item) => item.title === faculty)
        : [];

    let res =
      (await data[0]?.major?.length) &&
      data[0]?.major?.map((item) => item.title);
    res = (await res?.length) && [...new Set(res)];
    res = await res?.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    data.length && (await setMajor(res));
    setStudentMajor("");
  }

  function Filter() {
    const param = {
      course: course,
      faculty__title: faculty,
      major__title: studentMajor,
      degree: degree,
      region__title: studentDistrict,
      region__region__title: region,
    };
    dispatch(FilterAction(param));
  }

  useEffect(() => {
    dispatch(SearchAction());
    dispatch(RegionAction());
    dispatch(FacultyListAction());
    dispatch(MajorListAction());
    dispatch(DistrictListAction());
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  function ChangePage(page) {
    const param = {
      page: page,
      course: course,
      faculty__title: faculty,
      major__title: studentMajor,
      degree: degree,
      region__title: studentDistrict,
      region__region__title: region,
    };
    dispatch(FilterAction(param));
  }
  function Clear() {
    setCourse(null);
    setFaculty(null);
    setDegree(null);
    setRegion(null);
    setDistrict([]);
    setStudentDistrict("");
    setMajor("");
    setStudentMajor("");
    dispatch(SearchAction());
  }

  useEffect(() => {
    Filter();
  }, [course, faculty, degree, region, district, major, studentDistrict, studentMajor]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <div className="py-[30px]">
      <div
        className="container mx-auto flex flex-col xl:flex-row gap-[30px] py-[30px]"
        data-aos="fade-down"
      >
        {/* SIDEBAR */}
        <div className="w-full xl:w-2/12  xl:mt-[60px]">
          <div className="max-h-[80vh] overflow-y-auto">
            <Selections
              title={t("candidates.degree")}
              selections={degrees}
              selectType={"single"}
              customClass={"mt-[30px]"}
              callback={setDegree}
              value={degree}
            />
            <Selections
              title={t("candidates.course")}
              selections={degree==="Magistr" ? coursesMag : courses}
              selectType={"single"}
              callback={setCourse}
              value={course}
            />
            <Selections
              title={t("candidates.faculty")}
              selections={faculties?.results}
              selectType={"single"}
              customClass={"mt-[30px]"}
              callback={setFaculty}
              value={faculty}
            />
            {faculty && (
              <Selections
                title={t("candidates.major")}
                selections={major}
                selectType={"single"}
                customClass={"mt-[30px]"}
                callback={setStudentMajor}
                value={studentMajor}
              />
            )}
            <Selections
              title={t("candidates.region")}
              selections={regions?.results}
              selectType={"single"}
              customClass={"mt-[30px]"}
              callback={setRegion}
              value={region}
            />
            {region && (
              <Selections
                title={t("candidates.district")}
                selections={district}
                selectType={"single"}
                customClass={"mt-[30px]"}
                callback={setStudentDistrict}
                value={studentDistrict}
              />
            )}

            {/* <button
              onClick={Filter}
              className={`${
                course || faculty || degree || region
                  ? "bg-blue-light hover:bg-blue-white cursor-pointer"
                  : "bg-grey-light cursor-auto"
              } w-full text-white font-semibold py-[10px] mt-[30px]`}
            >
              {t("buttons.search")}
            </button> */}
          </div>
          <button
            onClick={Clear}
            className={`bg-blue-light hover:bg-blue-white cursor-pointer w-full text-white font-semibold py-[10px] mt-[30px]`}
          >
            {t("buttons.clear")}
          </button>
        </div>
        {/* MAIN */}
        <main className="w-full xl:w-10/12">
          {/* Top */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(SearchAction({ search: e.target[0].value }));
            }}
            className="flex flex-col sm:flex-row justify-between items-center mb-[20px] md:mb-0"
          >
            <h3 className="xl2-text font-bold text-blue-light mb-[7px]">
              {t("candidates.students")}
            </h3>
            <div className="flex">
              <div className="relative flex">
                <Input
                  placeholder={t("candidates.search_placeholder")}
                  customClass={"h-min pr-[35px]"}
                />
                <div className="flex items-center h-full absolute top-0 right-0">
                  <IoIosSearch className="xl-text text-grey mr-[10px]" />
                </div>
              </div>
              <button
                type="submit"
                className="bg-blue-light text-white font-semibold px-[25px]"
              >
                {t("buttons.search")}
              </button>
            </div>
          </form>
          {/* Candidates */}
          <div
            className={`grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-[15px]`}
          >
            {students?.results?.length
              ? students?.results?.map((student) => {
                  return (
                    <Link
                      to={`cv${student.id}`}
                      key={student.id}
                      className={`grid-cols-1 cursor-pointer hover:shadow-lg flex flex-col justify-between items-center shadow-md border border-grey-light rounded-sm`}
                      data-aos="fade-down"
                    >
                      {/* image */}
                      <div
                        style={{ backgroundImage: `url(${student.image})` }}
                        className="w-[100px] h-[100px] flex justify-center items-center rounded-full bg-grey-light my-[15px] bg-center bg-cover"
                      >
                        {!student.image && (
                          <FaUserGraduate className="text-grey xl2-text" />
                        )}
                      </div>
                      {/* info */}
                      <div className="px-[10px] text-center">
                        <p className="text-grey lg-text">{student.full_name}</p>
                        <p className="md-text text text-teal">
                          {student.faculty}
                        </p>
                        <p className="sm-text">{student.major}</p>
                      </div>
                      <div className="w-full bg-grey-light flex justify-between xs-text p-[10px] mt-[15px]">
                        <span>{student.type}</span>
                        <span>{student.status}</span>
                      </div>
                    </Link>
                  );
                })
              : "Topilmadi"}
          </div>
          <div
            className={`${
              students?.count > 19 ? "flex" : "hidden"
            } justify-center mt-[30px]`}
          >
            <Pagination
              className="ant-pagination"
              current={currentPage}
              pageSize={20}
              total={students?.count}
              showTitle={false}
              onChange={(page) => {
                setCurrentPage(page);
                ChangePage(page);
              }}
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export default Candidates;
