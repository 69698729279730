import React, { useState } from "react";
import { MdWork } from "react-icons/md";
import { Link } from "react-router-dom";
import Selections from "../components/Selections";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  CategoryAction,
  OffersListSearchAction,
  OffersListAction,
  RegionAction,
} from "../redux";
import {
  job_experiance,
  job_remote,
  job_time,
  job_intern,
  api_url2,
} from "../static";
import Pagination from "rc-pagination";
import { useTranslation } from "react-i18next";
import Input from "../components/Input";
import { IoIosSearch } from "react-icons/io";

const JobOffers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(OffersListAction());
    dispatch(CategoryAction());
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const offerslist = useSelector((state) => state.app.offerslist);
  var { category, district } = useSelector((state) => state.app);
  const categories = category?.results?.map((item) => {
    return {
      value: item.name,
      label: item.name,
    };
  });
  const [_category, setCategory] = useState("");
  const [experiance, setExperiance] = useState("");
  const [remote, setRemote] = useState("");
  const [time, setTime] = useState("");
  const [intern, setInter] = useState("");
  const [salary, setSalary] = useState({
    from: "",
    to: "",
  });
  function Filter(action) {
    const param = {
      category__name: _category,
      job_time_type: time,
      online_ofline: remote,
      internship: intern,
      experiance: experiance,
      min_salary: salary.from,
      max_salary: salary.to && salary.to,
    };
    if (action === "clear") {
      dispatch(OffersListAction());
      setCategory("");
      setExperiance("");
      setRemote("");
      setTime("");
      setInter("");
      setSalary({
        from: "",
        to: "",
      });
    } else {
      dispatch(OffersListAction(param));
    }
    setCurrentPage(1);
  }
  function ChangePage(page) {
    const param = {
      category__name: _category,
      job_time_type: time,
      online_ofline: remote,
      internship: intern,
      experiance: experiance,
      min_salary: salary.from,
      max_salary: salary.to && salary.to,
    };
    dispatch(OffersListAction(param));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);


  useEffect(() => {
    Filter();
  }, [_category,time,remote,intern,experiance])

  return (
    <div
      className="container mx-auto flex flex-col xl:flex-row gap-[30px] my-[30px]"
      data-aos="fade-down"
    >
      {/* SIDEBAR */}
      <div className="w-full xl:w-3/12 xl:mt-[60px]">
        <Selections
          title={t("jobOffers.category")}
          selections={categories}
          selectType={"single"}
          value={_category}
          callback={setCategory}
        />
        <Selections
          title={t("jobOffers.employment")}
          selections={job_time}
          selectType={"single"}
          customClass={"mt-[30px]"}
          value={time}
          callback={setTime}
        />
        <Selections
          title={t("jobOffers.worktype")}
          selections={job_remote}
          selectType={"single"}
          customClass={"mt-[30px]"}
          value={remote}
          callback={setRemote}
        />
        <Selections
          title={t("jobOffers.experience")}
          selections={job_experiance}
          selectType={"single"}
          customClass={"mt-[30px]"}
          value={experiance}
          callback={setExperiance}
        />
        <Selections
          title={t("jobOffers.practice")}
          selections={job_intern}
          selectType={"single"}
          customClass={"mt-[30px]"}
          value={intern}
          callback={setInter}
        />
        {/* salary */}
        <div className="h-min border border-grey-light rounded-sm mt-[30px]">
          <div className="w-full flex justify-between lg-text text-blue-light font-semibold items-center p-[5px] cursor-pointer bg-white-light">
            {t("jobOffers.salary")}
          </div>
          <div className="flex">
            <input
              onChange={(e) => setSalary({ ...salary, from: e.target.value })}
              onBlur={Filter}
              value={salary.from}
              placeholder={t("jobOffers.from")}
              className="w-full border border-grey-light outline-blue-white p-[5px]"
              type="number"
            />
            <input
              onChange={(e) => setSalary({ ...salary, to: e.target.value })}
              onBlur={Filter}
              value={salary.to}
              placeholder={t("jobOffers.to")}
              className="w-full border border-grey-light outline-blue-white p-[5px]"
              type="number"
            />
          </div>
        </div>

        {/* <button
          onClick={Filter}
          className={`${
            intern ||
            experiance ||
            remote ||
            time ||
            _category ||
            salary.from ||
            salary.to
              ? "bg-blue-light hover:bg-blue-white cursor-pointer"
              : "bg-grey-light cursor-auto"
          } w-full text-white font-semibold py-[10px] mt-[30px]`}
        >
          {t("buttons.search")}
        </button> */}
        <button
          onClick={() => Filter("clear")}
          className={`${"bg-blue-light hover:bg-blue-white cursor-pointer"} w-full text-white font-semibold py-[10px] mt-[30px]`}
        >
          {t("buttons.clear")}
        </button>
      </div>

      {/* MAIN */}
      <main className="w-full xl:w-9/12">
        {/* Top */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(OffersListSearchAction({ search: e.target[0].value }));
          }}
          className="flex flex-col sm:flex-row justify-between items-center mb-[20px] md:mb-0"
        >
          <h3 className="xl2-text font-bold text-blue-light mb-[7px]">
            {t("jobOffers.vacancy")}
          </h3>
          <div className="flex">
            <div className="relative flex">
              <Input
                placeholder={t("candidates.search_placeholder")}
                customClass={"h-min pr-[35px]"}
              />
              <div className="flex items-center h-full absolute top-0 right-0">
                <IoIosSearch className="xl-text text-grey mr-[10px]" />
              </div>
            </div>
            <button
              type="submit"
              className="bg-blue-light text-white font-semibold px-[25px]"
            >
              {t("buttons.search")}
            </button>
          </div>
        </form>
        {/* <h3 className="xl-text font-semibold text-grey mb-[15px] lg:mb-[30px]">
          {t('jobOffers.vacancy')}
        </h3> */}
        {/* candidates */}
        <div className={`grid grid-cols-1 xl:grid-cols-2 gap-[15px]`}>
          {offerslist?.results?.length
            ? offerslist?.results?.map((offer, index) => {
                return (
                  <div key={index}>
                    <Link
                      to={`job${offer?.id}`}
                      className={`grid-cols-1 cursor-pointer hover:shadow-lg flex flex-col justify-between shadow-md border border-grey-light hover:border-blue-light rounded-sm p-[15px]`}
                      data-aos="fade-down"
                    >
                      <div className="flex items-center">
                        {/* logo */}
                        <div
                          style={{
                            backgroundImage: `url(${
                              api_url2 + offer?.employer?.image
                            })`,
                          }}
                          className="flex justify-center items-center min-w-[80px] h-[80px] bg-grey-light bg-center bg-cover rounded-full overflow-hidden"
                        >
                          {!offer?.employer?.image && (
                            <MdWork className="text-grey xl2-text" />
                          )}
                        </div>
                        <div className="felx ml-[15px]">
                          <h4 className="lg-text font-semibold">
                            {offer?.title}
                          </h4>
                          <p className="text-grey">{offer?.employer?.name}</p>
                        </div>
                      </div>
                      {/* info */}
                      <div className="w-full flex justify-between items-end mt-[15px]">
                        <div className="flex flex-col">
                          <p className="text">{offer?.salary} UZS</p>
                          <p className="sm-text text-grey">{offer?.region}</p>
                        </div>
                        <div className="w-min-[]">{offer?.created_at}</div>
                      </div>
                    </Link>
                  </div>
                );
              })
            : t("jobOffers.notfound")}
        </div>
        {/*  */}
        <div
          className={`${
            offerslist?.count > 20 ? "flex" : "hidden"
          } justify-center mt-[30px]`}
        >
          <Pagination
            className="ant-pagination"
            current={currentPage}
            pageSize={20}
            total={offerslist?.count}
            showTitle={false}
            onChange={(page) => {
              setCurrentPage(page);
              ChangePage(page);
            }}
          />
        </div>
      </main>
    </div>
  );
};

export default JobOffers;
