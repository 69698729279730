import { Field, Form, Formik } from "formik";
import React, {useEffect} from "react";
import Iframe from "react-iframe";
import Button from "../components/Button";
import { BsFacebook, BsTelegram, BsInstagram, BsYoutube } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { MainListAction } from "../redux";
import {useTranslation} from 'react-i18next'

const Contacts = () => {
  const {t}=useTranslation();
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(MainListAction(t('header.lang')))
  },[t('header.lang')])
  const {social, footer} = useSelector(state => state.ghost.mainlist)
  return (
    <div>
      <div
        className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-[50px] my-[30px]"
        data-aos="fade-down"
      >
        {/* info */}
        <div className="col-span-1">
          <h2 className="xl-text text-grey font-semibold mb-[15px]">
            {t('contacts.location')}
          </h2>
          <div className="flex bg-grey-light py-[15px] px-[10px] lg-text">
            <div className="font-semibold mr-[10px] lg-text">{t('contacts.address')}</div>
            <div className="lg-text">{footer&& parse(footer.location)}</div>
          </div>
          <div className="flex bg-grey-light py-[15px] px-[10px] lg-text">
            <div className="font-semibold mr-[10px] lg-text">{t('contacts.phone')}</div>
            <a href={`tel:${footer?.phone}`} className="lg-text">{footer?.phone}</a>
          </div>
          <div className="flex bg-grey-light py-[15px] px-[10px] lg-text">
            <div className="font-semibold mr-[10px] lg-text">{t('contacts.mail')}</div>
            <a href={`email:${footer?.email}`} className="lg-text">{footer?.email}</a>
          </div>
          <div className="flex bg-grey-light py-[15px] px-[10px] lg-text border-b border-white-light">
            <div className="font-semibold mr-[10px] lg-text">{t('contacts.worktime')}</div>
            <div className="lg-text">{t('contacts.workschedule')}</div>
          </div>
          {/* social */}
          <div className="w-full flex flex-col bg-grey-light py-[15px]">
            <ul className="flex items-center mt-[5px]">
              <li>
                <button>
                  <a target="_blank" rel="noopener noreferrer" href={social?.telegram}>
                    <BsTelegram className="xl2-text ml-[10px] text-grey hover:text-[#229ED9]" />
                  </a>
                </button>
              </li>
              <li>
                <button>
                  <a target="_blank" rel="noopener noreferrer" href={social?.instagram}>
                    <BsInstagram className="xl2-text ml-[10px] text-grey hover:text-[#F77737]" />
                  </a>
                </button>
              </li>
              <li>
                <button>
                  <a target="_blank" rel="noopener noreferrer" href={social?.facebook}>
                    <BsFacebook className="xl2-text ml-[10px] text-grey hover:text-blue" />
                  </a>
                </button>
              </li>
              <li>
                <button>
                  <a target="_blank" rel="noopener noreferrer" href={social?.youtube}>
                    <BsYoutube className="xl2-text ml-[10px] text-grey hover:text-[#FD1D1D]" />
                  </a>
                </button>
              </li>
            </ul>
          </div>
        </div>

        {/* form */}
        <div className="col-span-1">
          <h2 className="xl-text text-grey font-semibold mb-[15px]">
            {t('contacts.contactsform')}
          </h2>
          <Formik
            initialValues={{
              fish: "",
              phone: "",
              email: "",
            }}
            onSubmit={async (values) => {
              await new Promise((r) => setTimeout(r, 500));
              alert(JSON.stringify(values, null, 2));
            }}
          >
            <Form className="flex flex-col">
              <Field
                id="fish"
                name="fish"
                placeholder={t('contacts.name')}
                className="focus:outline-teal-light border border-grey-light rounded-md py-[10px] px-[15px]"
              />
              <Field
                id="phone"
                name="phone"
                placeholder="99 000 00 00"
                className="focus:outline-teal-light border border-grey-light rounded-md py-[10px] px-[15px] mt-[10px]"
              />
              <Field
                as="textarea"
                id="text"
                name="text"
                placeholder={t('contacts.message')}
                type="textarea"
                className="focus:outline-teal-light border border-grey-light rounded-md py-[10px] px-[15px] mt-[10px]"
              />
              <Button
                text={t('buttons.send')}
                customClass="mt-[15px] p-[10px] text-grey bg-white-light hover:text-white"
              />
            </Form>
          </Formik>
        </div>
      </div>
      {/* map */}
      <div className="col-span-2 w-full">
        <Iframe
          url={`${footer?.map}`}
          width="100%"
          height="450px"
          id="myId"
          className="w-full"
          allowFullScreen="true"
          display="initial"
          position="relative"
        />
      </div>
    </div>
  );
};

export default Contacts;
