import React from "react";
import {VscLoading} from "react-icons/vsc"

const Loading = () => {
  return (
    <div className="w-screen h-screen fixed top-0 left-0 flex justify-center flex-wrap items-center bg-white-light z-50">
      <div className="relative w-[100px] lg:w-[200px]  h-[100px] lg:h-[200px] flex justify-center items-center rounded-full bg-white">
        <div className="absolute top-0 left-0 animate-ping w-[100px] lg:w-[200px] h-[100px] lg:h-[200px] rounded-full bg-white"></div>
        <VscLoading className="animate-spin text-[50px] lg:text-[150px] text-teal" />
      </div>
    </div>
  );
};

export default Loading;
