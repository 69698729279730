import uzFlag from "../assets/imgs/uz-flag.svg";
import ruFlag from "../assets/imgs/ru-flag.svg";
import enFlag from "../assets/imgs/en-flag.svg";

export const api_url = 'https://apicareers.tsue.uz/';
export const api_url2 = 'https://apicareers.tsue.uz';

export const languages = [
  {
    Id: 0,
    title: "O'zbekcha",
    flag: uzFlag,
    value: "uz",
  },
  {
    Id: 1,
    title: "Русский",
    flag: ruFlag,
    value: "ru",
  },
  {
    Id: 2,
    title: "English",
    flag: enFlag,
    value: "en",
  },
];

export const districts = [
  {
    value: "Toshkent",
    label: "Toshkent",
  },
  {
    value: "Toshkent viloyati",
    label: "Toshkent viloyati",
  },
  {
    value: "Andijon viloyati",
    label: "Andijon viloyati",
  },
  {
    value: "Buxoro viloyati",
    label: "Buxoro viloyati",
  },
  {
    value: "Fargʻona viloyati",
    label: "Fargʻona viloyati",
  },
  {
    value: "Jizzax viloyati",
    label: "Jizzax viloyati",
  },
  {
    value: "Namangan viloyati",
    label: "Namangan viloyati",
  },
  {
    value: "Navoiy viloyati",
    label: "Navoiy viloyati",
  },
  {
    value: "Qashqadaryo viloyati",
    label: "Qashqadaryo viloyati",
  },
  {
    value: "Qoraqalpogʻiston Respublikasi",
    label: "Qoraqalpogʻiston Respublikasi",
  },
  {
    value: "Samarqand viloyati",
    label: "Samarqand viloyati",
  },
  {
    value: "Sirdaryo viloyati",
    label: "Toshkent",
  },
  {
    value: "Surxondaryo viloyati",
    label: "Surxondaryo viloyati",
  },
  {
    value: "Xorazm viloyati",
    label: "Xorazm viloyati",
  },
];

export const faculties = [
  {
    value: "Economics",
    label: "Economics",
  },
  {
    value: "Managment",
    label: "Managment",
  },
  {
    value: "Banking system",
    label: "Banking system",
  },
];

export const courses = [
  {
    value: "1-kurs",
    label: "1-kurs",
  },
  {
    value: "2-kurs",
    label: "2-kurs",
  },
  {
    value: "3-kurs",
    label: "3-kurs",
  },
  {
    value: "4-kurs",
    label: "4-kurs",
  },
  {
    value: "5-kurs",
    label: "5-kurs",
  },
];
export const coursesMag = [
  {
    value: "1-kurs",
    label: "1-kurs",
  },
  {
    value: "2-kurs",
    label: "2-kurs",
  },
];
export const types = [
  {
    value: "Kunduzgi",
    label: "Kunduzgi",
  },
  {
    value: "Sirtqi",
    label: "Sirtqi",
  },
  {
    value: "Maxsus sirtqi",
    label: "Maxsus sirtqi",
  },
  {
    value: "Ikkinchi Oliy",
    label: "Ikkinchi Oliy",
  },
];

export const statuses = [
  {
    value: "O'qiydi",
    label: "O'qiydi",
  },
  {
      value: "O'qishni tamomlagan",
      label: "O'qishni tamomlagan",
    },
    {
        value: "Akademik ta'til olgan",
        label: "Akademik ta'til olgan",
    },
    {
      value: "O'qishdan haydalgan",
      label: "O'qishdan haydalgan",
    },
];

export const degrees = [
  {
    value: "Bakalavr",
    label: "Bakalavr",
  },
  {
    value: "Magistr",
    label: "Magistr",
  },
];

export const major = [
  {
    value: "O'qiydi",
    label: "O'qiydi",
  },
  {
    value: "O'qishdan haydalgan",
    label: "O'qishdan haydalgan",
  },
  {
    value: "Ikkinchi Oliy",
    label: "Ikkinchi Oliy",
  },
  {
    value: "Ikkinchi Oliy",
    label: "Ikkinchi Oliy",
  },
];

// JOB OFFERS
export const job_time = [
  {
    value: "FullTime",
    label: "To'liq stavkada bandlik",
  },
  {
    value: "PartTime",
    label: "To'liq bo'lmagan bandlik",
  },
  {
    value: "Temporary",
    label: "Vaqtinchalik",
  },
];

export const job_remote = [
  {
    value: "Online",
    label: "Online",
  },
  {
    value: "Ofline",
    label: "Ofline",
  },
];

export const job_experiance = [
  {
    value: "WithExperiance",
    label: "Tajribali",
  },
  {
    value: "WithoutExperiance",
    label: "Tajribasiz",
  },
];

export const job_intern = [
  {
    value: true,
    label: "Amaliyot",
  },
];

export const job_salary = [
  {
    value: '000 - 1,000,000',
    label: "000 - 1,000,000",
  },
  {
    value: "1,000,000 - 3,000,000",
    label: "1,000,000 - 3,000,000",
  },
  {
    value: "3,000,000 - 5,000,000",
    label: "3,000,000 - 5,000,000",
  },
  {
    value: "5,000,000 - 7,000,000",
    label: "5,000,000 - 7,000,000",
  },
  {
    value: "7,000,000 - 9,000,000",
    label: "7,000,000 - 9,000,000",
  },
  {
    value: "9,000,000 - ------",
    label: "9,000,000 - ------",
  },
]