export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const SEARCH_STUDENT_USER_SUCCESS = 'SEARCH_STUDENT_USER_SUCCESS'
export const PROFILE_STUDENT_USER_SUCCESS = 'PROFILE_STUDENT_USER_SUCCESS'
export const FAILURE = 'FAILURE'
export const REQUEST = 'REQUEST'
export const APP_REQUEST = 'APP_REQUEST'
export const APP_FAILURE = 'APP_FAILURE'
export const SUCCESS = 'SUCCESS'
export const MAIN_LIST_SUCCESS = 'MAIN_LIST_SUCCESS'
export const NEWS_DETAIL_SUCCESS = 'NEWS_DETAIL_SUCCESS'
export const NEWS_LIST_SUCCESS = 'NEWS_LIST_SUCCESS'
export const FILTER_SUCCESS = 'FILTER_SUCCESS'
export const STUDENT_DETAIL_SUCCESS = 'STUDENT_DETAIL_SUCCESS'
export const OFFERS_LIST_SUCCESS = 'OFFERS_LIST_SUCCESS'
export const OFFER_DETAIL_SUCCESS = 'OFFER_DETAIL_SUCCESS'
export const OFFER_CREATE_SUCCESS = 'OFFER_CREATE_SUCCESS'
export const EMPLOYER_DETAIL_SUCCESS = 'EMPLOYER_DETAIL_SUCCESS'
export const EMPLOYERS_LIST_SUCCESS = 'EMPLOYERS_LIST_SUCCESS'
export const EMPLOYER_PROFILE_SUCCESS = 'EMPLOYER_PROFILE_SUCCESS'
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS'
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS'
export const REGION_LIST_SUCCESS = 'REGION_LIST_SUCCESS'
export const VREGION_LIST_SUCCESS = 'REGION_LIST_SUCCESS'
export const ABOUT_US_SUCCESS = 'ABOUT_US_SUCCESS'
export const DISTRICT_LIST_SUCCESS = 'DISTRICT_LIST_SUCCESS'
export const FACULTY_LIST_SUCCESS = 'FACULTY_LIST_SUCCESS'
export const MAJOR_LIST_SUCCESS = 'MAJOR_LIST_SUCCESS'
export const VACANCY_LIST_SUCCESS = 'VACANCY_LIST_SUCCESS'
export const APPYED_STUDENTS_SUCCESS= 'APPYED_STUDENTS_SUCCESS'