import { VscFolderOpened, VscBriefcase, VscCalendar } from "react-icons/vsc";
import { BiShowAlt } from "react-icons/bi";
import { AiOutlineFileText } from "react-icons/ai";
import { MdWork } from "react-icons/md";
import cliTruncate from "cli-truncate";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Poll from "../components/Poll";
import { useTranslation } from "react-i18next";
import { api_url } from "../static";
import image from "../assets/imgs/bg/1.jpg";
import image2 from "../assets/imgs/bg/2.jpg";
import image3 from "../assets/imgs/bg/3.jpg";
import image4 from "../assets/imgs/bg/4.jpg";
import image5 from "../assets/imgs/bg/5.jpg";
import { useEffect, useState } from "react";

const Home = () => {
  const { t } = useTranslation();
  var mainlist = useSelector((state) => state.ghost.mainlist);

  const [bg, setBg] = useState()
  const backgrounds = [image, image2, image3, image4, image5];
  
  useEffect(() => {
    const random = Math.floor(Math.random() * 5)
    setBg(backgrounds[random])
  },[])

  return (
    <div className={`page-animation w-full h-auto`} data-aos="fade-down">
      <div
        style={{ backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, 0.5) 0%,rgba(20,116,178, 0.3) 100%), url(${bg})` }}
        className="w-full h-[400px] lg:h-[500px] xl:h-[600px] bg-center bg-cover"
      >
        <div className="container mx-auto relative w-full h-full">
          <h1 className="text-white  brightness-200 xl2-text font-bold pt-[10%] max-w-[500px]">
            {t("home.title")}
          </h1>
          {/* info */}
          <div className="mt-[15px] xs:mt-[30px] sm:mt-[100px] xl:mt-[200px] w-full flex justify-between sm:items-center flex-col sm:flex-row flex-wrap">
            <div
              style={{ background: "rgba(255, 255, 255, .7)" }}
              className="flex items-center justify-center sm:justify-start w-full sm:w-[30%] sm:h-[100px] border border-grey-light rounded-sm mt-[20px] sm:mt-0 p-1 sm:p-[10px] lg:p-[5px]"
            >
              <VscFolderOpened className="hidden lg:block text-[36px] mx-[15px]" />
              <div className="flex items-center lg:items-start lg:flex-col">
                <p className="md-text font-medium">{t("home.vacancy")}</p>
                <span className="ml-[10px] lg:ml-0 text-[24px]">
                  {mainlist?.vacancy}
                </span>
              </div>
            </div>
            <div
              style={{ background: "rgba(255, 255, 255, .7)" }}
              className="flex items-center justify-center sm:justify-start w-full sm:w-[30%] sm:h-[100px] border border-grey-light rounded-sm mt-[20px] sm:mt-0 p-1 sm:p-[10px] lg:p-[5px]"
            >
              <VscBriefcase className="hidden lg:block text-[36px] mx-[15px]" />
              <div className="flex items-center lg:items-start lg:flex-col">
                <p className="md-text font-medium">{t("home.company")}</p>
                <span className="ml-[10px] lg:ml-0 text-[24px]">
                  {mainlist.company && mainlist.company}
                </span>
              </div>
            </div>
            <div
              style={{ background: "rgba(255, 255, 255, .7)" }}
              className="flex items-center justify-center sm:justify-start w-full sm:w-[30%] sm:h-[100px] border border-grey-light rounded-sm mt-[20px] sm:mt-0 p-1 sm:p-[10px] lg:p-[5px]"
            >
              <AiOutlineFileText className="hidden lg:block text-[36px] mx-[15px]" />
              <div className="flex items-center lg:items-start lg:flex-col">
                <p className="md-text font-medium">{t("home.cv")}</p>
                <span className="ml-[10px] lg:ml-0 text-[24px]">
                  {mainlist.resume && mainlist.resume}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main className="container mx-auto flex justify-between flex-col lg:flex-row gap-[30px] mt-[30px]">
        {/* MAIN CONTENT */}
        <div className="w-full lg:w-10/12">
          {/* OFFERS */}
          <div className="mt-[30px]">
            {/* title */}
            <div className="flex justify-between items-end">
              <div className="xl2-text font-bold leading-9 text-blue-light">
                {t("home.vacancytitle")}
              </div>
              <Link
                to="/offers"
                className="text-grey cursor-pointer hover:text-blue-light"
              >
                {t("home.allvacancy")}
              </Link>
            </div>
            <div
              className={`grid grid-cols-1 xl:grid-cols-2 gap-[15px] mt-[30px]`}
            >
              {mainlist?.last_vacancy?.length &&
                mainlist.last_vacancy.map((singlvacancy, index) => {
                  const {
                    employer,
                    category,
                    title,
                    salary,
                    region,
                    id,
                    updated_at,
                  } = singlvacancy;
                  return (
                    <Link
                      key={index}
                      to={`/offers/job${id}`}
                      className={`grid-cols-1 cursor-pointer hover:shadow-lg flex flex-col shadow-md border border-grey-light rounded-sm p-[15px]`}
                      data-aos="fade-down"
                    >
                      <div className="flex items-center">
                        {/* logo */}
                        <div className="flex justify-center items-center min-w-[80px] h-[80px] bg-grey-light rounded-full">
                          <MdWork className="text-grey xl2-text" />
                        </div>
                        <div className="felx ml-[15px]">
                          <h4 className="lg-text font-semibold">{title}</h4>
                          <p className="text-grey">
                            {employer.name}, ({category})
                          </p>
                        </div>
                      </div>
                      {/* info */}
                      <div className="w-full flex justify-between items-end mt-[15px]">
                        <div className="flex flex-col">
                          <p className="text">{salary}</p>
                          <p className="sm-text text-grey">{region}</p>
                        </div>
                        <div>{updated_at}</div>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
          {/* NEWS */}
          <div className="mt-[30px]">
            {/* title */}
            <div className="flex justify-between items-end">
              <div className="xl2-text font-bold leading-9 text-blue-light">
                {t("home.newstitle")}
              </div>
              <Link
                to="news"
                className="text-grey cursor-pointer hover:text-blue-light"
              >
                {t("home.allnews")}
              </Link>
            </div>
            {/* content */}
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-[15px] my-[30px]">
              {mainlist?.news?.length &&
                mainlist.news.map((singlenews, index) => {
                  const { views, image, title, content, date, id } = singlenews;
                  return (
                    <Link
                      to={`news${id}`}
                      key={index}
                      className="flex flex-col border border-grey-light shadow-sm hover:shadow-md rounded-sm cursor-pointer p-[5px] pb-[10px]"
                      data-aos="fade-down"
                    >
                      <img
                        src={`${api_url}${image}`}
                        alt="newsImage"
                        className="h-[150px] object-cover"
                      />
                      <div className="md-text font-bold p-[5px] min-h-[60px] text-blue-light">
                        {" "}
                        {cliTruncate(`${title}`, 50)}{" "}
                      </div>
                      <p className="flex items-center justify-between text-[#A5A5A5]">
                        <span className="font-light px-[5px]  flex items-center">
                          <VscCalendar className="text-xl mr-1" />
                          {date}
                        </span>{" "}
                        <span className="flex items-center mr-3">
                          <BiShowAlt className="text-xl text-[#1474B2] mr-2" />
                          {views}
                        </span>{" "}
                      </p>
                      <div className="text text-grey p-[5px]">
                        {cliTruncate(`${content}`, 100)}
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>

        {/* SIDEBAR */}
        <div className="w-full lg:w-2/12 overflow-hidden">
          {/* POLL */}
          <Poll />
          <div className="mt-[15px]">
            <Sidebar apply={true} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
