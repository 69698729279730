import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import CreateCV from "./pages/CreateCV";
import Example from "./pages/Example";
import Home from "./pages/Home";
import Login from "./pages/Login";
import CV from "./pages/CV";
import Candidates from "./pages/Candidates";
import JobOffers from "./pages/JobOffers";
import Job from "./pages/Job";
import StudentProfile from "./pages/StudentProfile";
import Contacts from "./pages/Contacts";
import News from "./pages/News";
import NewsById from "./pages/NewsById";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Apply from "./pages/Apply";
import EmployerPage from "./pages/EmployerPage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import { useSelector } from "react-redux";
import AboutUs from "./pages/AboutUs";
import EditVacancy from "./pages/EditVacancy";
import CreateVacancy from "./pages/CreateVacancy";
import EmployerEditPage from "./pages/EmployerEditPage";
import EditResume from "./components/EditResume";

function App() {
  const { ghost } = useSelector((state) => state);
  useEffect(() => {
    AOS.init({
      once: true,
    });
    AOS.refresh();
  }, []);
  useEffect(() => {
    if (ghost.messages.messagestatus === "error") {
      toast.error(ghost.messages.messagetext, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (ghost.messages.messagestatus === "success") {
        toast.success(ghost.messages.messagetext, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [ghost.messages.messagetext]);

  return (
    <div className="text-black overflow-hidden flex flex-col justify-between min-h-screen">
      <BrowserRouter>
        <Header />
        <div className="__main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="createCV" element={ <ProtectedRoute pathFrom="createCV"> <CreateCV /> </ProtectedRoute>}/>
          <Route path="editCV" element={ <ProtectedRoute pathFrom="editCV"> <EditResume /> </ProtectedRoute>}/>
          <Route path="candidates" element={ <ProtectedRoute pathFrom="candidates" > <Candidates /> </ProtectedRoute>}/>
          <Route path="candidates/cv:id" element={ <ProtectedRoute pathFrom="candidates/cv:id"> <CV /> </ProtectedRoute>}/>
          <Route path="offers" element={ <ProtectedRoute pathFrom="offers"> <JobOffers /> </ProtectedRoute>}/>
          <Route path="offers/job:id" element={ <ProtectedRoute pathFrom="offers/job:id"> <Job /> </ProtectedRoute>}/>
          <Route path="contact" element={<Contacts />} />
          <Route path="news" element={<News />} />
          <Route path="news:id" element={<NewsById />} />
          <Route path="student-profile" element={ <ProtectedRoute pathFrom="student-profile" > <StudentProfile /> </ProtectedRoute>}/>
          <Route path="employer-profile" element={ <ProtectedRoute pathFrom="employer-profile"> <EmployerPage /> </ProtectedRoute>}/>
          <Route path="employer-edit" element={ <ProtectedRoute pathFrom="employer-edit"> <EmployerEditPage /> </ProtectedRoute>}/>
          <Route path="employer/edit-vacancy:id" element={ <ProtectedRoute pathFrom="employer/edit-vacancy:id"> <EditVacancy /> </ProtectedRoute>}/>
          <Route path="create-vacancy" element={ <ProtectedRoute pathFrom="create-vacancy"> <CreateVacancy /> </ProtectedRoute>}/>
          <Route path="apply" element={<Apply />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="example" element={<Example />} />
        </Routes>
        </div>
        <Footer />
      </BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
