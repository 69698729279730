import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {BsFacebook, BsTelegram, BsInstagram, BsYoutube, BsTwitter} from "react-icons/bs";
import {useSelector } from "react-redux";
import {useTranslation} from 'react-i18next'
import { api_url } from "../../static";
import htmlparser from 'html-react-parser'
import { ImLocation2, ImMail3, ImPhone } from "react-icons/im"

const Footer = () => {
  const {t}=useTranslation();
    const {logo, social, footer} = useSelector(state => state.ghost.mainlist)
    const {app} = useSelector(state => state)
    const [Items, setItems] = useState([])
    let menuItems = t('header.menuItems', {returnObjects: true})
    useEffect(() => {
      if (app.isLoggedIn) {
        switch (app.auth.role) {
          case "Student":
            menuItems.map((menuItem, index) => {
              menuItem.pageURL === "candidates" && menuItems.splice(index, 1)
                setItems(menuItems)
            })
            break;
          default:
            setItems(menuItems);
        }
      }else{
        setItems(menuItems)
      }
    }, [app.isLoggedIn]);

  return (
    <div className="w-full bg-grey-light py-[15px]">
      <div className="container mx-auto w-full flex justify-between flex-wrap">
        <Link to="/" className="lg-text mx-auto xs:ml-[5px]">
          <img src={logo && `${api_url}${logo.image}`} alt={logo && `logo ${logo.title}`} className="w-[150px] h-[45px] md:h-[70px] object-contain" />
        </Link>
        {/* nav */}
        <nav className="flex w-full xs:w-[75%] xs:justify-end mt-[10px] xs:mt-0">
          <ul className="w-full flex flex-col xs:flex-row xs:justify-end items-center xs:items-end md-text">
          {
            Items.map((menuItem, index) => {
                const { menuTitle, pageURL } = menuItem;
                return(
                  <li key={index} className="mr-[15px] hover:text-grey">
                    <Link to={pageURL} >{menuTitle}</Link>
                  </li>
                )
              }
            )
          }
          </ul>
        </nav>
            {/* social */}
            <div className="w-full flex flex-col sm:flex-row justify-between sm:items-end mt-[15px]">
              <div className="flex flex-col sm:items-start items-center">
                {
                  footer &&
                  <>
                  <p className="flex flex-row text-md sm:text-lg xl:text-xl items-center" ><ImLocation2 className="text-blue-light mr-1 text-lg sm:text-xl xl:text-2xl" /> {htmlparser(footer.location)}</p>
                  <a href={`email: ${footer?.email}`} className="flex flex-row text-sm sm:text-md  xl:text-lg items-center mt-1" ><ImMail3 className="text-blue-light mr-2" /> {htmlparser(footer.email)}</a>
                  <a href={`tel:${footer?.phone}`} className="flex flex-row text-sm sm:text-md  xl:text-lg items-center mt-1" ><ImPhone className="text-blue-light mr-2" /> {htmlparser(footer.phone)}</a>
                  </>
                }
              </div>
              <div className="flex flex-col mt-2 sm:mt-0 items-end">
                <p className="md-text">{t('footer')}</p>
                <ul className="flex mt-[5px]">
                    <li className={`${!social?.facebook && 'hidden'}`}>
                        <button><a target="_blank" rel="noopener noreferrer" href={social?.facebook}><BsFacebook className="xl-text ml-[10px] hover:text-blue"/></a></button>
                    </li>
                    <li className={`${!social?.telegram && 'hidden'}`}>
                        <button><a target="_blank" rel="noopener noreferrer" href={social?.telegram}><BsTelegram className="xl-text ml-[10px] hover:text-blue"/></a></button>
                    </li>
                    <li className={`${!social?.twitter && 'hidden'}`}>
                        <button><a target="_blank" rel="noopener noreferrer" href={social?.twitter}><BsTwitter className="xl-text ml-[10px] hover:text-blue"/></a></button>
                    </li>
                    <li className={`${!social?.instagram && 'hidden'}`}>
                        <button><a target="_blank" rel="noopener noreferrer" href={social?.instagram}><BsInstagram className="xl-text ml-[10px] hover:text-red"/></a></button>
                    </li>
                    <li className={`${!social?.youtube && 'hidden'}`}>
                        <button><a target="_blank" rel="noopener noreferrer" href={social?.youtube}><BsYoutube className="xl-text ml-[10px] hover:text-red"/></a></button>
                    </li>
                </ul>
              </div>
                
            </div>
        </div>
      </div>
  );
};

export default Footer;
