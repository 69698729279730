import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { NewsListAction } from "../redux";
import {useTranslation} from 'react-i18next'
import { BiShowAlt } from "react-icons/bi";

const News = () => {
  const {t}=useTranslation();
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(NewsListAction(t('header.lang')))
  }, [t('header.lang')]);
  const newslist = useSelector(state => state.ghost.newslist)
  return (
    <div className="container mx-auto my-[30px]" data-aos="fade-down">
      <h2 className="w-max xl2-text uppercase font-bold text-blue-light border-b border-teal">
        {t('home.newstitle')}
      </h2>
      <div className="w-full grid grid-cols-12 gap-[15px]">
        <div className="col-span-12 lg:col-span-10">
          {newslist.results?.map((singlenews, index)=>{
            const {views, image, title, content, date, id } = singlenews
            return(
              <div key={index} className="grid grid-cols-12 group" data-aos="fade-down">
                <div className="hidden sm:block col-span-1 relative mr-[15px] pt-[25px] text-grey">
                  <div className="xs-text">{date}</div>
                  <span className="flex items-center">
                          <BiShowAlt className="text-xl text-[#1474B2] mr-2" />
                          {views}
                        </span>
                  <div className="absolute top-[25px] -right-[23px] w-[15px] h-[15px] rounded-full bg-white border border-teal group-hover:bg-teal group-hover:border-grey-light"></div>
                </div>
                <Link
                  to={`/news${id}`}
                  className="basis-full col-span-11 border-l border-teal pl-[15px] pt-[25px] cursor-pointer"
                >
                  <div className="text-blue-white xl-text font-semibold group-hover:text-blue-light cursor-pointer mb-[5px]">
                    {title}
                  </div>
                  <div className="flex flex-col sm:flex-row">
                    <img
                      src={`${image}`}
                      alt="news-img"
                      className="w-auto h-[150px] object-cover border border-grey-light opacity-90 group-hover:opacity-100"
                    />
                    <div className="w-full sm:ml-[15px] text-grey sm-text group-hover:text-black">
                      {content}
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
        <div className="col-span-12 lg:col-span-2">
          <Sidebar apply={true} />
        </div>
      </div>
    </div>
  );
};

export default News;
