import React, { useEffect, useState } from "react";
import { FaUserGraduate, FaUniversity, FaRegEdit } from "react-icons/fa";
import { BiTimeFive } from "react-icons/bi";
import { IoLocationOutline } from "react-icons/io5";
import { GiPhone } from "react-icons/gi";
import { MdEmail, MdWork } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { StudentDetailAction } from "../redux";
import { useParams } from "react-router-dom";
import cliTruncate from "cli-truncate";
import moment from "moment";
import ViewImage from "../components/ViewImage";

const CV = () => {
  const dispatch = useDispatch();
  const { student } = useSelector((state) => state.app);
  const { id } = useParams();
  const [viewImage, setViewImage] = useState("");
  const [toggleViewImage, setToggleViewImage] = useState(false);
  useEffect(() => {
    dispatch(StudentDetailAction(id));
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {toggleViewImage && (
        <ViewImage image={viewImage} calbackFunc={setToggleViewImage} />
      )}
      <div
        className="container mx-auto flex flex-col xl:flex-row gap-[15px] my-[30px]"
        data-aos="fade-down"
      >
        {/* SIDEBAR */}
        <div className="w-full h-min xl:w-3/12 flex flex-col items-center rounded-sm bg-white-light p-[15px]">
          {/* image */}
          <div
            onClick={() => {
              if(student.image) {
                setToggleViewImage(true);
                setViewImage(student.image);
              }
            }}
            className="w-[100px] h-[100px] flex justify-center items-center rounded-full bg-grey-light bg-cover bg-center my-[15px] overflow-hidden cursor-pointer"
            style={student.image && { backgroundImage: `url(${student.image})` }}
          >
            {student.image ? (
              ''
            ) : (
              <FaUserGraduate className="text-grey xl2-text" />
            )}
          </div>
          {/* info */}
          <div className="text-center pb-[10px] border-b border-grey-light drop-shadow-sm">
            <h3 className="lg-text text-grey font-semibold">
              {student.full_name}
            </h3>
            <p className="md-text mt-[5px]">{student.faculty}</p>
          </div>
          {/* personal info */}
          <div className="w-full sm:w-1/2 xl:w-full flex flex-col">
            <div className="w-full flex justify-between items-center mt-[10px]">
              <div className="flex flex-col">
                <p className="sm-text text-grey">Yosh</p>
                <span className="sm-text text-black">
                  {moment().diff(student.birth, "year")} Yosh
                </span>
              </div>
              <div className="border border-grey p-[5px] rounded-full">
                <BiTimeFive className="xl-text text-grey" />
              </div>
            </div>
            <div className="w-full flex justify-between items-center mt-[10px]">
              <div className="flex flex-col">
                <p className="sm-text text-grey">Joylashuv</p>
                <span className="sm-text text-black">
                  {student.current_address}
                </span>
              </div>
              <div className="border border-grey p-[5px] rounded-full">
                <IoLocationOutline className="xl-text text-grey" />
              </div>
            </div>
            <div className="w-full flex justify-between items-center mt-[10px]">
              <div className="flex flex-col">
                <p className="sm-text text-grey">Elektron pochta</p>
                <a
                  href={`mailto:${student.email}`}
                  className="sm-text text-black cursor-pointer hover:text-blue hover:underline"
                >
                  {student.email}
                </a>
              </div>
              <div className="border border-grey p-[5px] rounded-full">
                <MdEmail className="xl-text text-grey" />
              </div>
            </div>
            <div className="w-full flex justify-between items-center mt-[10px]">
              <div className="flex flex-col">
                <p className="sm-text text-grey">Telefon raqami</p>
                <a
                  href={`tel:${student.phone_number}`}
                  className="sm-text text-black hover:text-blue hover:underline cursor-pointer"
                >
                  {student.phone_number}
                </a>
              </div>
              <div className="border border-grey p-[5px] rounded-full">
                <GiPhone className="xl-text text-grey" />
              </div>
            </div>
          </div>
        </div>
        {/* MAIN */}
        <main className="relative w-full flex flex-col bg-white-light pb-[30px] px-[15px]">
          <h2 className="xl2-text font-bold text-blue-light mb-[15px]">
            Resume
          </h2>
          {student.student_resume ? (
            <>
              <div
                style={{
                  backgroundImage: `url(${student.student_resume?.image})`,
                }}
                className="w-[250px] h-[250px] border border-grey-light mb-[30px] bg-cover bg-center rounded-full cursor-pointer"
                onClick={() => {
                  if(student.student_resume?.image) {
                    setToggleViewImage(true);
                    setViewImage(student.student_resume?.image);
                  }
                }}
              ></div>
              <div className="w-full flex flex-col mb-[30px]">
                <h4 className="xl-text font-semibold">O‘zim haqimda</h4>
                <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                <p className="text-grey my-[5px]">
                  {student.student_resume?.description}
                </p>
              </div>
              {/*  */}
              <div className="w-full flex flex-col mb-[30px]">
                <h4 className="xl-text font-semibold">Mahorat</h4>
                <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                <div className="flex gap-[15px]">
                  <div className="text-white w-max my-[5px] p-[5px] rounded-[20px] bg-teal-dark">
                    {student.student_resume?.skill}
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="w-full flex flex-col mb-[30px]">
                <h4 className="xl-text font-semibold">Til bilish ko’nikmasi</h4>
                <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                <div className="flex gap-[15px]">
                  {student.student_resume?.foreign_language}
                </div>
              </div>
              {/*  */}
              <div className="w-full flex flex-col mb-[30px]">
                <h4 className="xl-text font-semibold">Xobbi</h4>
                <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                <p className="text-grey my-[5px]">
                  {student.student_resume?.hobby}
                </p>
              </div>
              {/* current_address */}
              <div className="w-full flex flex-col mb-[30px]">
                <h4 className="xl-text font-semibold">Manzil</h4>
                <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                <p className="text-grey my-[5px]">
                  {student.student_resume?.current_address}
                </p>
              </div>
              {/* email */}
              <div className="w-full flex flex-col mb-[30px]">
                <h4 className="xl-text font-semibold">Elektron pochta</h4>
                <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                <p className="text-grey my-[5px]">
                  {student.student_resume?.email}
                </p>
              </div>
              {/* phone_number */}
              <div className="w-full flex flex-col mb-[30px]">
                <h4 className="xl-text font-semibold">Telefon raqam</h4>
                <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                <a
                  tel={student.student_resume?.phone}
                  className="w-max text-grey my-[5px] hover:text-blue hover:underline cursor-pointer"
                >
                  {student.student_resume?.phone}
                </a>
              </div>
              {/* links */}
              <div className="w-full flex flex-col mb-[30px] overflow-hidden">
                <h4 className="xl-text font-semibold">Bog'lanish</h4>
                <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                <div className="flex items-center">
                  <div className="md-text mr-[5px]">Linkedin:</div>
                  <a
                    href={student.student_resume?.linkedin}
                    className="text-grey my-[5px] w-max hover:text-blue hover:underline"
                  >
                    {student.student_resume?.linkedin &&
                      cliTruncate(student.student_resume?.linkedin, 50)}
                  </a>
                </div>
                <div className="flex items-center">
                  <div className="md-text mr-[5px]">Telegram:</div>
                  <a
                    href={student.student_resume?.telegram}
                    className="text-grey my-[5px] w-max hover:text-blue hover:underline"
                  >
                    {student.student_resume?.telegram &&
                      cliTruncate(student.student_resume?.telegram, 50)}
                  </a>
                </div>
                <div className="flex items-center">
                  <div className="md-text mr-[5px]">Resume:</div>
                  <a
                    href={student.student_resume?.portfolio_link}
                    className="text-grey my-[5px] w-max hover:text-blue hover:underline"
                  >
                    {student.student_resume?.portfolio_link &&
                      cliTruncate(student.student_resume?.portfolio_link, 50)}
                  </a>
                </div>
              </div>
              {/* education */}
              <div className="w-full flex flex-col mb-[30px]">
                <h4 className="xl-text font-semibold">Taʼlim</h4>
                <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                {student?.student_resume?.resume_education?.map(
                  (education, index) => {
                    return (
                      <div key={index} className="flex mt-[10px]">
                        <div className="h-min bg-grey-light p-[10px] rounded-sm">
                          <FaUniversity className="text-grey xl2-text" />
                        </div>
                        <div className="flex flex-col ml-[10px]">
                          <h4 className="xl-text font-semibold">
                            {education.unv_title}
                          </h4>
                          <p className="text-grey">
                            {education.degree} - {education.specialty}
                          </p>
                          <div className="flex sm-text text-grey">
                            <span>{education.begin_date}</span>
                            <span className="mx-[5px] block">-</span>
                            <span>
                              {education.finish_date
                                ? education.finish_date
                                : "hozirgi vaqt"}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              {/* experiance */}
              <div className="w-full flex flex-col mb-[30px]">
                <h4 className="xl-text font-semibold">Tajriba</h4>
                <span className="w-full border-b border-grey-light shadow-sm my-[5px]"></span>
                {student?.student_resume?.resume_experience?.map(
                  (experiance, index) => {
                    return (
                      <div key={index} className="flex mt-[10px]">
                        <div className="h-min bg-grey-light p-[10px] rounded-sm">
                          <MdWork className="text-grey xl2-text" />
                        </div>
                        <div className="flex flex-col ml-[10px]">
                          <h4 className="xl-text font-semibold">
                            {experiance.title}
                          </h4>
                          <p className="text-grey">{experiance.specialty}</p>
                          <div className="flex sm-text text-grey">
                            <span>{experiance.begin_date}</span>
                            <span className="mx-[5px] block">-</span>
                            <span>{experiance.finish_date}</span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </>
          ) : (
            "Resume yuklanmagan"
          )}
        </main>
      </div>
    </>
  );
};

export default CV;
