import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import MaskedInput from "react-text-mask";
import Modal from "./Modal";
import { VscAdd, VscRemove } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FaUserGraduate } from "react-icons/fa";
import {
  EducationCreateListAction,
  EducationDelateListAction,
  ExperienceDeleteListAction,
  ProfileAction,
  ResumeUpdateAction,
} from "../../redux/Action/appAction";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CVInfoPhoto = ({ photo, setPhoto }) => {
  return useMemo(() => {
    return (
      <div className="w-full md:w-4/12 mr-[15px] mb-[30px] md:mb-0">
        <div
          style={{
            backgroundImage: `url(${
              typeof photo === "string"
                ? photo
                : photo && URL.createObjectURL(photo)
            })`,
          }}
          className={`relative w-full h-[160px] md:h-[250px] bg-cover bg-center flex flex-col justify-center items-center rounded-sm border border-grey-light hover:bg-grey-light cursor-pointer`}
        >
          {!photo ? (
            <>
              <FaUserGraduate className="text-[40px] md:text-[50px] text-grey" />
              <p className="text-grey mt-[10px] lg-text">Foto yuklash</p>{" "}
            </>
          ) : (
            ""
          )}
          <input
            onChange={(e) => setPhoto(e.target.files[0])}
            type="file"
            className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
          />
        </div>
      </div>
    );
  }, [photo]);
};

const EditResume = () => {
  const { t } = useTranslation();
  const [cvPhoto, setCvPhoto] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resume = useSelector((state) => state.app.studentuser.student_resume);
  const [showModal, setShowModal] = useState("");
  const [educationList, setEducationList] = useState([]);
  const [experianceList, setExperianceList] = useState([]);
  const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];

  useEffect(() => {
    setEducationList(resume.resume_education);
    setExperianceList(resume.resume_experience);
    setCvPhoto(resume.image);
    window.scrollTo(0, 0);
  }, [resume]);

  function UpdateResume(values) {
    if (typeof cvPhoto !== "string") {
      const formData = new FormData();
      formData.append("image", cvPhoto);
      dispatch(ResumeUpdateAction(resume.id, formData, t));
    }
    const data = { ...values };
    dispatch(ResumeUpdateAction(resume.id, data, t));
    navigate("/student-profile");
  }

  function CreateEducation(data) {
    dispatch(EducationCreateListAction(data));
  }
  async function RemoveEducation(id) {
    await dispatch(EducationDelateListAction(id));
    await dispatch(ProfileAction());
  }

  async function RemoveExperiance(id) {
    await dispatch(ExperienceDeleteListAction(id));
    await dispatch(ProfileAction());
  }

  function PrintYearMonth(start, end) {
    if (start && end) {
      const years = moment(end).diff(moment(start), "years");
      const months = moment(end).diff(moment(start), "months");
      if (years > 0) {
        if (months > 0) {
          return `(${years} - yil,  ${months - years * 12} - oy)`;
        } else {
          return `(${years} - yil)`;
        }
      } else if (months > 0) {
        return `(${months} - oy)`;
      }
    }
  }

  return (
    <div className="container mx-auto my-[15px]">
      <CVInfoPhoto photo={cvPhoto} setPhoto={setCvPhoto} />
      <Formik
        initialValues={{
          phone: resume?.phone,
          email: resume?.email,
          current_address: resume?.current_address,
          skill: resume?.skill,
          foreign_language: resume?.foreign_language,
          hobby: resume?.hobby,
          description: resume?.description,
          portfolio_link: resume?.portfolio_link,
          linkedin: resume?.linkedin,
          telegram: resume?.telegram,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email(),
          portfolio_link: Yup.string().url(),
          website: Yup.string().url(),
          telegram: Yup.string().url(),
          linkedin: Yup.string().url(),
        })}
        onSubmit={(fields) => {
          UpdateResume(fields);
        }}
        render={({ errors, touched, handleBlur, handleChange }) => (
          <Form className="grid grid-cols-1 md:grid-cols-2 gap-[15px]">
            {/* phone */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="phone">
                Telefon raqamingiz
              </label>
              <Field
                name="phone"
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    mask={phoneNumberMask}
                    id="phone"
                    placeholder="Telefon raqamingizni kiriting"
                    type="tel"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                      (errors.phone && touched.phone ? " border-red" : "")
                    }
                  />
                )}
              />
            </div>
            {/* email */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="email">
                Elektron manzilingiz
              </label>
              <Field
                name="email"
                type="email"
                placeholder="example@gmail.com"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.email && touched.email ? " border-red" : "")
                }
              />
            </div>
            {/* address */}
            <div className="col-span-1">
              <label
                className="text-grey font-semibold"
                htmlFor="current_address"
              >
                Yashash manzilingiz
              </label>
              <Field
                name="current_address"
                type="text"
                placeholder="Shahar Tuman Ko'cha Uy"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.current_address && touched.current_address
                    ? " border-red"
                    : "")
                }
              />
            </div>
            {/* Skill */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="skill">
                Mahorat
              </label>
              <Field
                name="skill"
                type="text"
                placeholder="Shahar Tuman Ko'cha Uy"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.skill && touched.skill ? " border-red" : "")
                }
              />
            </div>
            {/* Foreign Languages */}
            <div className="col-span-1">
              <label
                className="text-grey font-semibold"
                htmlFor="foreign_language"
              >
                Qaysi chet tillarini
              </label>
              <Field
                name="foreign_language"
                type="text"
                placeholder="Shahar Tuman Ko'cha Uy"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.foreign_language && touched.foreign_language
                    ? " border-red"
                    : "")
                }
              />
            </div>
            {/* Hobby */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="hobby">
                Xobbi
              </label>
              <Field
                name="hobby"
                type="text"
                placeholder="Xobbi"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.hobby && touched.hobby ? " border-red" : "")
                }
              />
            </div>
            {/* Description */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="description">
                Men haqimda
              </label>
              <Field
                name="description"
                type="text"
                as="textarea"
                placeholder="O'zingiz haqingizda ma'lumot"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px] h-[150px]" +
                  (errors.description && touched.description
                    ? " border-red"
                    : "")
                }
              />
            </div>
            {/* Portfolio */}
            <div className="col-span-1">
              <label
                className="text-grey font-semibold"
                htmlFor="portfolio_link"
              >
                Portfolio link
              </label>
              <Field
                name="portfolio_link"
                type="text"
                placeholder="Portfolio link"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.portfolio_link && touched.portfolio_link
                    ? " border-red"
                    : "")
                }
              />
            </div>
            {/* Linkedin */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="linkedin">
                Linkedin link
              </label>
              <Field
                name="linkedin"
                type="text"
                placeholder="linkedin link"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.linkedin && touched.linkedin ? " border-red" : "")
                }
              />
            </div>
            {/* Telegram */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="telegram">
                Telegram link
              </label>
              <Field
                name="telegram"
                type="text"
                placeholder="telegram link"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.telegram && touched.telegram ? " border-red" : "")
                }
              />
            </div>
            {/* education and experiance */}
            <div className="col-span-1">
              {/* education list */}
              <h2 className="lg-text text-grey font-semibold mb-[5px]">
                Talim
              </h2>
              <div
                className={`${
                  !educationList?.length && "hidden"
                } border border-grey-light rounded-sm my-[15px] px-[15px] py-[5px] text-grey`}
              >
                {educationList?.length
                  ? educationList?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="my-[10px] flex justify-between items-center"
                        >
                          <div>
                            <h4 className="text-blue lg-text font-semibold">
                              {item.unv_title}
                            </h4>
                            <div className="flex items-end">
                              <p className="md-text mr-[10px]">
                                {item.specialty} ({item.degree})
                              </p>
                            </div>
                            <span className="sm-text">
                              {moment(item.begin_date).format("MM.YYYY")}
                            </span>
                            <span className="sm-text mx-[3px]">-</span>
                            <span className="sm-text">
                              {item.status
                                ? "H.V"
                                : moment(item.finish_date).format("MM.YYYY")}
                            </span>
                            <span className="ml-[5px]">
                              {PrintYearMonth(
                                item.begin_date,
                                item.finish_date
                              )}
                            </span>
                          </div>
                          <VscRemove
                            onClick={() => RemoveEducation(item.id)}
                            className="cursor-pointer xl-text hover:text-red-dark"
                          />
                        </div>
                      );
                    })
                  : ""}
              </div>
              <div
                onClick={() => setShowModal("education")}
                className="w-full border-grey-light bg-blue-light text-white font-semibold flex justify-between items-center py-[10px] px-[15px] hover:text-white focus:outline-teal cursor-pointer"
              >
                <div>o'qish joyi qo'shish</div>
                <VscAdd />
              </div>
            </div>
            <div className="col-span-1">
              {/* experiance list */}
              <h2 className="lg-text text-grey font-semibold mb-[5px]">
                Tajriba
              </h2>
              <div
                className={`${
                  !experianceList?.length && "hidden"
                } border border-grey-light rounded-sm my-[15px] px-[15px] py-[5px] text-grey`}
              >
                {experianceList?.length
                  ? experianceList?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="my-[10px] flex justify-between items-center"
                        >
                          <div>
                            <h4 className="text-blue lg-text font-semibold">
                              {item.title}
                            </h4>
                            <div className="flex items-end">
                              <p className="md-text mr-[10px]">
                                {item.speciality}
                              </p>
                              <span className="sm-text">
                                {moment(item.begin_date).format("MM.YYYY")}
                              </span>
                              <span className="sm-text mx-[3px]">-</span>
                              <span className="sm-text">
                                {item.status
                                  ? "H.V"
                                  : moment(item.finish_date).format("MM.YYYY")}
                              </span>
                              <span className="ml-[5px]">
                                {PrintYearMonth(
                                  item.begin_date,
                                  item.finish_date
                                )}
                              </span>
                            </div>
                          </div>
                          <VscRemove
                            onClick={() => RemoveExperiance(item.id)}
                            className="cursor-pointer xl-text hover:text-red-dark"
                          />
                        </div>
                      );
                    })
                  : ""}
              </div>
              <div
                onClick={() => setShowModal("experiance")}
                className="w-full border-grey-light bg-blue-light text-white font-semibold flex justify-between items-center py-[10px] px-[15px] hover:text-white focus:outline-teal cursor-pointer"
              >
                <div>ish joyi qo'shish</div>
                <VscAdd />
              </div>
            </div>
            {/* submit */}
            <div className="col-span-1 lg:col-span-2">
              <button
                type="submit"
                className="bg-blue-white hover:bg-blue-light text-white border mr-[15px] border-grey-light py-[5px] px-[10px] rounded-sm transition"
              >
                Yangliash
              </button>
            </div>
          </Form>
        )}
      />
      {showModal === "education" && (
        <Modal
          mode="education"
          modal={setShowModal}
          sendFileds={setEducationList}
          values={educationList}
        />
      )}
      {showModal === "experiance" && (
        <Modal
          mode=""
          modal={setShowModal}
          sendFileds={setExperianceList}
          values={experianceList}
        />
      )}
    </div>
  );
};

export default EditResume;
