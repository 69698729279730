import { useEffect, useState } from "react";
import { Squash as Hamburger } from "hamburger-react";
import DropDown from "../DropDown";
import uzFlag from "../../assets/imgs/uz-flag.svg";
import ruFlag from "../../assets/imgs/ru-flag.svg";
import enFlag from "../../assets/imgs/en-flag.svg";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useScrollBlock from "../../hooks/scrollBlock";
import { useTranslation } from "react-i18next";
import { HiUserCircle } from "react-icons/hi";
import { MainListAction, LogoutAction } from "../../redux";
import { ImExit } from "react-icons/im";
import { api_url } from "../../static"; 

const Header = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(MainListAction(t('header.lang')))
  },[t('header.lang')])
  const { ghost, app } = useSelector((state) => state);
  const studentuser = useSelector((state) => state.app.studentuser);
  const employeruser = useSelector((state) => state.app.employeruser);
  const role = useSelector((state) => state.app.role);
  const langs = [
    {
      Id: 0,
      title: "O'zbekcha",
      flag: uzFlag,
      value: "uz",
    },
    {
      Id: 1,
      title: "Русский",
      flag: ruFlag,
      value: "ru",
    },
    {
      Id: 2,
      title: "English",
      flag: enFlag,
      value: "en",
    },
  ];
  const [toggleMenu, setToggleMenu] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  useEffect(() => {
    if (toggleMenu) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [toggleMenu, allowScroll, blockScroll]);
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  return (
    <div className="bg-white shadow-sm mb-[130px] md:mb-[90px]">
      <div className="fixed top-0 z-40 w-full bg-white py-[10px] border-b border-b-grey-light shadow-md">
        <div className="bg-white container mx-auto flex justify-between flex-wrap items-center">
          <div className="flex items-center justify-between md:justify-start w-full md:w-3/12 mb-[15px] md:mb-0">
            <Link to="/">
              <img
                src={
                  ghost.mainlist.logo &&
                  `${api_url}${ghost.mainlist.logo.image}`
                }
                alt={ghost.mainlist.logo && `logo ${ghost.mainlist.logo.title}`}
                className="lg:w-[140px] h-[45px] md:h-[70px] object-contain"
              />
            </Link>
            <DropDown langs={langs} icon={"down"} onChange={changeLanguage} />
          </div>
          {/* navigation */}
          <nav className="xs:w-8/12 md:w-7/12 hidden md:flex justify-center text-grey">
            <ul className="w-full md:w-[90%] flex justify-between md:justify-around items-center md-text">
              {t("header.menuItems", { returnObjects: true }).map(
                (menuItem, index) => {
                  const { menuTitle, pageURL } = menuItem;
                  return (
                    <NavLink
                      key={index}
                      to={pageURL}
                      className={({ isActive }) =>
                        isActive
                          ? "border-b-[2px] border-blue-light text-blue-light font-bold hover:border-b-[2px]"
                          : "border-b-[2px] border-white hover:border-blue-light hover:border-b-[2px]" +
                            ` ${
                              pageURL === "candidates" && role === "Student"
                                ? "hidden"
                                : ""
                            }`
                      }
                    >
                      {menuTitle}
                    </NavLink>
                  );
                }
              )}
            </ul>
          </nav>
          <div
            className={`flex items-center ${
              studentuser?.full_name
                ? "justify-between md:justify-around"
                : "justify-between md:justify-end"
            }  w-full md:w-4/12 lg:w-2/12 md-text`}
          >
            <div
              onClick={() => setToggleMenu(!toggleMenu)}
              className={`md:hidden xl2-text text-black-dark z-20`}
            >
              <Hamburger toggled={toggleMenu} size={25} />
            </div>
            {app?.isLoggedIn ? (
              // profile
              <>
                <div className="relative flex items-center cursor-pointer group leading-5 text-grey">
                  <div
                    style={{
                      backgroundImage: `linear-gradient(to bottom, rgba(140,151,171, 0) 0%,rgba(140,151,171, 0.4) 100%), url(${
                        studentuser?.image || employeruser?.image
                      })`,
                    }}
                    className={`profile-image min-w-[45px] h-[45px] rounded-full border border-grey-light bg-cover bg-center bg-no-repeat bg-grey-light z-10`}
                  ></div>
                  <div className="sm-text ml-[5px] leading-4">
                    {studentuser?.full_name || employeruser?.name}
                  </div>
                  <div className="absolute top-full right-0 bg-white w-[150px] max-h-0 border-l border-r border-grey-light group-hover:max-h-[500px] overflow-hidden transition-all ease-in-out duration-500 mt-[5px] shadow-md">
                    <ul>
                      <li>
                        <div
                          onClick={() => {
                            app.role === "Employer"
                              ? navigate("/employer-profile")
                              : navigate("/student-profile");
                          }}
                          className="flex items-center hover:bg-blue-light text-grey border-y border-grey-light hover:text-white py-[10px] px-[15px]"
                        >
                          <HiUserCircle className="xl-text mr-[5px]" />
                          Profile
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            dispatch(LogoutAction(navigate, t));
                          }}
                          className="flex items-center hover:bg-red-dark text-grey border-y border-grey-light hover:text-white py-[10px] px-[15px]"
                        >
                          <ImExit className="lg-text mr-[5px]" />
                          {t("header.button1")}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>  
              </>
            ) : (
              <Link
                to="login"
                className="flex items-center cursor-pointer group"
              >
                <div className="text-grey group-hover:text-black border-b border-grey-light">
                  {t("header.button")}
                </div>
              </Link>
            )}
          </div>
        </div>
        {/* mobile-nav */}
        <nav
          style={{ backgroundColor: "rgba(255, 255, 255, 0.95)" }}
          className={`${
            toggleMenu ? "translate-x-0" : "-translate-x-full"
          } w-full h-full border-r border-grey-light fixed top-0 left-0 transition-all ease-out duration-300 z-10`}
        >
          <ul className="w-full h-full flex flex-col xs:justify-center items-end xs:items-center text-[22px] xs:xl-text text-grey pr-[15px] xs:pr-0 pt-[110px]">
            {t("header.menuItems", { returnObjects: true }).map(
              (menuItem, index) => {
                const { menuTitle, pageURL } = menuItem;
                return (
                  <NavLink
                    key={index}
                    onClick={() => setToggleMenu(false)}
                    to={pageURL}
                    className={({ isActive }) =>
                      isActive
                        ? "border-b-[2px] border-blue-white hover:border-b-[2px] mt-[20px]"
                        : "border-blue-white hover:border-b-[2px] mt-[20px]"
                    }
                  >
                    {menuTitle}
                  </NavLink>
                );
              }
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
