import axios from 'axios'
import jwt from 'jwt-decode'
import {
    LOGIN_USER_SUCCESS,
    LOGOUT_USER_SUCCESS,
    SEARCH_STUDENT_USER_SUCCESS,
    PROFILE_STUDENT_USER_SUCCESS,
    FILTER_SUCCESS,
    STUDENT_DETAIL_SUCCESS,
    OFFERS_LIST_SUCCESS,
    OFFER_DETAIL_SUCCESS,
    EMPLOYER_DETAIL_SUCCESS,
    EMPLOYERS_LIST_SUCCESS,
    EMPLOYER_PROFILE_SUCCESS,
    CATEGORY_LIST_SUCCESS,
    COUNTRY_LIST_SUCCESS,
    REGION_LIST_SUCCESS,
    DISTRICT_LIST_SUCCESS,
    FACULTY_LIST_SUCCESS,
    VREGION_LIST_SUCCESS,
    MAJOR_LIST_SUCCESS,
    VACANCY_LIST_SUCCESS,
    APPYED_STUDENTS_SUCCESS,
    APP_REQUEST,
    APP_FAILURE,
  } from '../Types/appTypes'


const appState = {
    isLoggedIn: false,
    auth: {},
    role: null,
    studentusers: {},
    studentuser:  {},
    offerslist: {},
    offer: {},
    employerslist: {},
    employerdetail:{},
    employeruser:{},
    student: {},
    category: {},
    country: {},
    region: {},
    vRegion: {},
    district: {},
    faculty: {},
    major: {},
    vacancylist: {},
    applyedStudents: []
};
const AppState = () => {
    const app = localStorage.getItem("app");
    try {
      const appobj = JSON.parse(app);
      const token = appobj.auth.access;
      const user = jwt(token);
      const { exp } = user;

      if (new Date(exp*1000) > new Date()) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        return appobj;
      }
      localStorage.removeItem("app");
      return appState;
    } catch (error) {
      return appState;
    }
  };

// getAppState()
const newAppState = AppState();
const reducer = (state = newAppState, action) => {
switch (action.type) {
    case APP_REQUEST:
      return state;
    case APP_FAILURE:
      return state;
    case LOGIN_USER_SUCCESS:
        const loginAppState = {
            ...appState,
            isLoggedIn: true,
            auth: action.payload,
            role: action.payload.role,
        };
        axios.defaults.headers.common[
        "Authorization"
        ] = `Bearer ${action.payload.access}`;
        localStorage.setItem("app", JSON.stringify(loginAppState));
    return loginAppState;
    case LOGOUT_USER_SUCCESS:
        localStorage.removeItem("app");
    return appState
    case SEARCH_STUDENT_USER_SUCCESS:
      const searchAppState = {
        ...state,
        studentusers: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(searchAppState));
    return searchAppState
    case PROFILE_STUDENT_USER_SUCCESS:
      const profileAppState = {
        ...state,
        studentuser: action.payload.results[0],
      };
      localStorage.setItem("app", JSON.stringify(profileAppState));
    return profileAppState
    case FILTER_SUCCESS:
      const filteredusersState = {
        ...state,
        studentusers: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(filteredusersState));
    return filteredusersState
    case STUDENT_DETAIL_SUCCESS:
      const studentfilterState = {
        ...state,
        student: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(studentfilterState));
    return studentfilterState
    case OFFERS_LIST_SUCCESS:
      const offerslistState = {
        ...state,
        offerslist: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(offerslistState));
    return offerslistState
    case OFFER_DETAIL_SUCCESS:
      const offerState = {
        ...state,
        offer: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(offerState));
    return offerState
    case EMPLOYERS_LIST_SUCCESS:
      const employerslistState = {
        ...state,
        employerslist: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(employerslistState));
    return employerslistState
    case EMPLOYER_DETAIL_SUCCESS:
      const employerdetailState = {
        ...state,
        employerdetail: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(employerdetailState));
    return employerdetailState
    case EMPLOYER_PROFILE_SUCCESS:
      const employerprofileState = {
        ...state,
        employeruser: action.payload.results[0],
      };
      localStorage.setItem("app", JSON.stringify(employerprofileState));
    return employerprofileState
    case CATEGORY_LIST_SUCCESS:
      const emcategorySate = {
        ...state,
        category: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(emcategorySate));
    return emcategorySate
    case COUNTRY_LIST_SUCCESS:
      const ecountryState = {
        ...state,
        country: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(ecountryState));
    return ecountryState
    case REGION_LIST_SUCCESS:
      const regionState = {
        ...state,
        region: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(regionState));
    return regionState
    case VREGION_LIST_SUCCESS:
      const vRegionState = {
        ...state,
        vRegion: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(vRegionState));
    return vRegionState
    case DISTRICT_LIST_SUCCESS:
      const districtState = {
        ...state,
        district: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(districtState));
    return districtState
    case FACULTY_LIST_SUCCESS:
      const facultyState = {
        ...state,
        faculty: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(facultyState));
    return facultyState
    case MAJOR_LIST_SUCCESS:
      const majorState = {
        ...state,
        major: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(majorState));
    return majorState
    case VACANCY_LIST_SUCCESS:
      const vacancylistState = {
        ...state,
        vacancylist: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(vacancylistState));
    return vacancylistState
    case APPYED_STUDENTS_SUCCESS:
      const applyedStudentsState = {
        ...state,
        applyedStudents: action.payload,
      };
      localStorage.setItem("app", JSON.stringify(applyedStudentsState));
    return applyedStudentsState
    
    default: return state
}
}
export default reducer