import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { CategoryAction, OfferDetailAction, RegionAction, VacancyUpdateAction, VRegionAction } from "../../redux/Action/appAction";
import { job_experiance, job_remote, job_time } from "../../static";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChangeVacancy = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const categoryList = useSelector((state) => state.app.category.results);
  const regionList = useSelector((state) => state.app.region.results);
  const { offer } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const job_intern = [
    {
      name: "Bor",
      value: true,
    },
    {
      name: "Yo'q",
      value: false,
    },
  ];
  useEffect(() => {
    dispatch(CategoryAction());
    dispatch(VRegionAction());
  }, []);
  useEffect(() => {
    dispatch(OfferDetailAction(id));
    window.scroll(0, 0);
  }, [id]);

  return (
    <div className="container mx-auto mb-[30px]">
      <h2 className="xl-text text-grey font-bold mb-[15px]">
        {t("changeVacancy.vacancyedit")}
      </h2>
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: offer?.title,
            salary: offer?.salary,
            description: offer?.description,
            job_time_type: offer?.job_time_type,
            online_ofline: offer?.online_ofline,
            internship: offer?.internship,
            experiance: offer?.experiance,
            region: offer?.region,
            category: offer?.category,
          }}
          validationSchema={Yup.object().shape({
            // title: Yup.string().required("required"),
            // salary: Yup.string().required("required"),
            // description: Yup.string().required("required"),
            // category: Yup.string().required("required"),
            // region: Yup.string().required("required"),
            // job_time_type: Yup.string().required("required"),
            // online_ofline: Yup.string().required("required"),
            // internship: Yup.string().required("required"),
            // experiance: Yup.string().required("required"),
          })}
          onSubmit={(fields) => {
            const regionId = regionList.find(
              (item) => item.name === fields.region
            );
            const categoryId = categoryList.find(
              (item) => item.name === fields.category
            );
            const data = {
              ...fields,
              region: regionId.id,
              category: categoryId.id,
            };
            dispatch(VacancyUpdateAction(id, data));
          }}
        >
          {({ errors, touched }) => (
            <Form className="grid grid-cols-1 md:grid-cols-2 gap-[15px]">
              {/* category select */}
              <div>
                <label className={`text-grey font-semibold`} htmlFor="category">
                  {t("changeVacancy.vacancyedit")}
                </label>
                <Field
                  name="category"
                  type="text"
                  className={
                    "w-full form-control bg-white text-grey focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.category && touched.category ? " border-red" : "")
                  }
                  as="select"
                >
                  {categoryList?.map((category) => {
                    return (
                      <option key={category.id} value={category.name}>
                        {category.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div>
                <label className={`text-grey font-semibold`} htmlFor="region">
                  {t("changeVacancy.region")}
                </label>
                <Field
                  name="region"
                  type="text"
                  className={
                    `w-full form-control bg-white text-grey focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]` +
                    (errors.region && touched.region ? " border-red" : "")
                  }
                  as="select"
                >
                  {regionList?.map((region) => {
                    return (
                      <option key={region.id} value={region.name}>
                        {region.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="col-span-1">
                <label className={`text-grey font-semibold`} htmlFor="title">
                  {t("changeVacancy.title")}
                </label>
                <Field
                  name="title"
                  type="text"
                  placeholder={t("changeVacancy.title")}
                  className={
                    "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.title && touched.title ? " border-red" : "")
                  }
                />
              </div>
              <div className="col-span-1">
                <label className={`text-grey font-semibold`} htmlFor="salary">
                  {t("changeVacancy.salary")}
                </label>
                <Field
                  name="salary"
                  type="number"
                  placeholder="5 000 000"
                  className={
                    "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.email && touched.email ? " border-red" : "") +
                    (errors.salary && touched.salary ? " border-red" : "")
                  }
                />
              </div>
              <div className="col-span-1">
                <label
                  className={`text-grey font-semibold" htmlFor="address`}
                  htmlFor="job_time_type"
                >
                  {t("changeVacancy.employmenttype")}
                </label>
                <Field
                  name="job_time_type"
                  type="text"
                  className={
                    "w-full form-control bg-white text-grey focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.job_time_type && touched.job_time_type
                      ? " border-red"
                      : "")
                  }
                  as="select"
                >
                  {job_time?.map((time) => {
                    return (
                      <option key={time.value} value={time.value}>
                        {time.label}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="col-span-1">
                <label
                  className={`text-grey font-semibold"`}
                  htmlFor="online_ofline"
                >
                  {t("changeVacancy.jobtype")}
                </label>
                <Field
                  name="online_ofline"
                  type="text"
                  placeholder={t("changeVacancy.jobtype")}
                  className={
                    "w-full form-control bg-white text-grey focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.online_ofline && touched.online_ofline
                      ? " border-red"
                      : "")
                  }
                  as="select"
                >
                  {job_remote?.map((job) => {
                    return (
                      <option key={job.value} value={job.value}>
                        {job.label}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="col-span-1">
                <label
                  className={`text-grey font-semibold`}
                  htmlFor="internship"
                >
                  {t("changeVacancy.practice")}
                </label>
                <Field
                  name="internship"
                  type="text"
                  placeholder="Amaliyot"
                  className={
                    "w-full form-control bg-white text-grey focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.internship && touched.internship
                      ? " border-red"
                      : "")
                  }
                  as="select"
                >
                  {job_intern?.map((intern) => {
                    return (
                      <option key={intern.value} value={intern.value}>
                        {intern.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="col-span-1">
                <label
                  className={`text-grey font-semibold`}
                  htmlFor="experiance"
                >
                  {t("changeVacancy.experience")}
                </label>
                <Field
                  name="experiance"
                  type="text"
                  placeholder="Amaliyot"
                  className={
                    "w-full form-control bg-white text-grey focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.experiance && touched.experiance
                      ? " border-red"
                      : "")
                  }
                  as="select"
                >
                  {job_experiance?.map((experiance) => {
                    return (
                      <option key={experiance.value} value={experiance.value}>
                        {experiance.label}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="col-span-1">
                <label
                  className={`text-grey font-semibold`}
                  htmlFor="description"
                >
                  {t("changeVacancy.definition")}
                </label>
                <Field
                  name="description"
                  type="text"
                  as="textarea"
                  placeholder="Murojat matnini kiriting"
                  className={
                    "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.description && touched.description
                      ? " border-red"
                      : "")
                  }
                />
              </div>
              {/* submit */}
              <div className="col-span-1 lg:col-span-2">
                <button
                  type="submit"
                  className="bg-blue-white hover:bg-blue-light text-white border mr-[15px] border-grey-light py-[5px] px-[10px] rounded-sm transition"
                >
                  {t("buttons.add")}
                </button>
                <button
                  type="reset"
                  className="bg-blue-white hover:bg-blue-light text-white border  border-grey-light py-[5px] px-[10px] rounded-sm transition"
                >
                  {t("buttons.delate")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangeVacancy;
