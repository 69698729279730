import { combineReducers } from 'redux'
import reducer from './Reducer/appReducer'
import ghostreducer from './Reducer/ghostReducer'

const rootReducer = combineReducers({
  app: reducer,
  ghost: ghostreducer,
})

export default rootReducer
