import axios from 'axios'

import {
    FAILURE,
    REQUEST,
    SUCCESS,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER_SUCCESS,
    SEARCH_STUDENT_USER_SUCCESS,
    PROFILE_STUDENT_USER_SUCCESS,
    MAIN_LIST_SUCCESS,
    NEWS_DETAIL_SUCCESS,
    NEWS_LIST_SUCCESS,
    FILTER_SUCCESS,
    STUDENT_DETAIL_SUCCESS,
    OFFERS_LIST_SUCCESS,
    OFFER_DETAIL_SUCCESS,
    EMPLOYER_DETAIL_SUCCESS,
    EMPLOYERS_LIST_SUCCESS,
    CATEGORY_LIST_SUCCESS,
    COUNTRY_LIST_SUCCESS,
    REGION_LIST_SUCCESS,
    VREGION_LIST_SUCCESS,
    OFFER_CREATE_SUCCESS,
    EMPLOYER_PROFILE_SUCCESS,
    ABOUT_US_SUCCESS,
    DISTRICT_LIST_SUCCESS,
    FACULTY_LIST_SUCCESS,
    MAJOR_LIST_SUCCESS,
    VACANCY_LIST_SUCCESS,
    APPYED_STUDENTS_SUCCESS,
    APP_REQUEST,
    APP_FAILURE,
  } from '../Types/appTypes'

// SIMILAR
  export const request = () => {
    return {
      type: REQUEST
    }
  }
  
  export const success = (text) => {
    return {
      type: SUCCESS,
      payload: text
    }
  }
  
  export const failure = text => {
    return {
      type: FAILURE,
      payload: text
    }
  }
  export const apprequest = () => {
    return {
      type: APP_FAILURE
    }
  }
  export const appfailure = () => {
    return {
      type: APP_REQUEST
    }
  }
// LOGIN
export const LoginAction = (loginState, navigate, t) => {
    return (dispatch) => {
      dispatch(request())
          dispatch(apprequest())
      axios.post("api/token/", loginState)
        .then(response => {
            const user = response.data
            dispatch(fetchLoginSuccess(user))
            dispatch(success({messagetext: t('message.loginsuccess'), messagestatus: "success"}))
            navigate("/");
        })
        .catch(error => {
          dispatch(failure({messagetext: t('message.loginerror'), messagestatus: "error"}))
          dispatch(appfailure())
        })
    }
  }
  
export const fetchLoginSuccess = user => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: user
  }
}

// LOGOUT 
export const LogoutAction = (navigate, t) => {
  navigate("/");
  return (dispatch) => {
    dispatch(fetchLogoutSuccess())
    dispatch(success({messagetext: t('message.logoutsuccess'), messagestatus: "success" }))
  }
}

export const fetchLogoutSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS
  }
}

// SEARCH
export const SearchAction = (param) => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get("en/api/s1/student-search", {params: param})
      .then(response => {
          const user = response.data
          dispatch(fetchSearchSuccess(user))
          dispatch(success({messagetext: "Searched successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "Searched failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchSearchSuccess = user => {
  return {
    type: SEARCH_STUDENT_USER_SUCCESS,
    payload: user
  }
}

// PROFILE_STUDENT
export const ProfileAction = () => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get('en/api/s1/student-profile')
      .then(response => {
          const user = response.data
          dispatch(fetchProfileSuccess(user))
          dispatch(success({messagetext: "Student profile was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "ProfileAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchProfileSuccess = user => {
  return {
    type: PROFILE_STUDENT_USER_SUCCESS,
    payload: user
  }
}

// MAIN_LIST
export const MainListAction = (lang) => {
  return (dispatch) => {
    dispatch(request())
    lang && axios.get(`${lang}/api/m1/main-list`, { headers:{ Authorization:"" } })
      .then(response => {
          const data = {list: response.data, messagetext: "Main List was uploaded successfully!", messagestatus: "mute" }
          dispatch(fetchMainListSuccess(data))
      })
      .catch(error => {
          dispatch(failure({messagetext: "MainListAction failed", messagestatus: "mute"}))
      })
  }
}

export const fetchMainListSuccess = data => {
  return {
    type: MAIN_LIST_SUCCESS,
    payload: data
  }
}
// NEWSDETAIL
export const NewsDetailAction = (id, lang) => {
  return (dispatch) => {
    dispatch(request())
    axios.get(`/${lang}/api/m1/news-detail/${id}/`, { headers:{ Authorization:"" } })
      .then(response => {
          const data = {newsdetail: response.data, messagetext: "News was uploaded successfully!", messagestatus: "mute" }
          dispatch(fetchNewsDetailSuccess(data))
      })
      .catch(error => {
          dispatch(failure({messagetext: "NewsDetailAction failed", messagestatus: "mute"}))
      })
  }
}

export const fetchNewsDetailSuccess = data => {
  return {
    type: NEWS_DETAIL_SUCCESS,
    payload: data
  }
}
// NEWSLIST
export const NewsListAction = (lang) => {
  return (dispatch) => {
    dispatch(request())
    axios.get(`/${lang}/api/m1/news-list`, { headers:{ Authorization:"" } })
      .then(response => {
          const data = {newslist: response.data, messagetext: "Main List was uploaded successfully!", messagestatus: "mute" }
          dispatch(fetchNewsListSuccess(data))
      })
      .catch(error => {
          dispatch(failure({messagetext: "NewsListAction failed", messagestatus: "mute"}))
      })
  }
}

export const fetchNewsListSuccess = data => {
  return {
    type: NEWS_LIST_SUCCESS,
    payload: data
  }
}// FILTER
export const FilterAction = (param) => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get("/en/api/s1/student-filter", {params: param})
      .then(response => {
          const user = response.data
          dispatch(fetchFilterSuccess(user))
          dispatch(success({messagetext: "Students filtered successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "FilterAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchFilterSuccess = user => {
  return {
    type: FILTER_SUCCESS,
    payload: user
  }
}
// StudentDetail
export const StudentDetailAction = (id) => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get(`/en/api/s1/student-detail/${id}/`)
      .then(response => {
          const studentdetail = response.data
          dispatch(fetchStudentDetailSuccess(studentdetail))
          dispatch(success({messagetext: "Student Detail was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "StudentDetailAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchStudentDetailSuccess = studentdetail => {
  return {
    type: STUDENT_DETAIL_SUCCESS,
    payload: studentdetail
  }
}
// OFFERS_LIST
export const OffersListAction = (param) => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get("/en/api/e1/vacancy-list/", {params: param})
      .then(response => {
          const offerslist = response.data
          dispatch(fetchOffersListSuccess(offerslist))
          dispatch(success({messagetext: "Offers List was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "OffersListAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}
// OFFERS_LIST_SEARCH
export const OffersListSearchAction = (param) => {
  return (dispatch) => {
    dispatch(request())
    axios.get("/en/api/e1/vacancy-search/", {params: param})
      .then(response => {
          const offerslist = response.data
          dispatch(fetchOffersListSuccess(offerslist))
          dispatch(success({messagetext: "Offers List was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure(error.message))
      })
  }
}

export const fetchOffersListSuccess = offerslist => {
  return {
    type: OFFERS_LIST_SUCCESS,
    payload: offerslist
  }
}// OFFER_CREATE
export const OfferCreateAction = (offerinfo, t) => {
  return (dispatch) => {
    dispatch(request())
    axios.post("/en/api/e1/vacancy-create/",offerinfo)
      .then(response => {
          const offerslist = response.data
          dispatch(fetchOfferCreateSuccess(offerslist))
          dispatch(success({messagetext: t('message.offercreatesuccess'), messagestatus: "success" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: t('message.offercreateerror'), messagestatus: "error"}))
      })
  }
}

export const fetchOfferCreateSuccess = offerslist => {
  return {
    type: OFFER_CREATE_SUCCESS,
    payload: offerslist
  }
}
// EMPLOYERS_LIST
export const EmployersListAction = () => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get("/en/api/e1/employer-list/")
      .then(response => {
          const employerslist = response.data
          dispatch(fetchEmployersListSuccess(employerslist))
          dispatch(success({messagetext: "Employers List was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "EmployersListAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchEmployersListSuccess = employerslist => {
  return {
    type: EMPLOYERS_LIST_SUCCESS,
    payload: employerslist
  }
}
// EMPLOYER_PROFILE 
export const EmployerProfileAction = (id) => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get('/en/api/e1/employer-profile/')
      .then(response => {
          const employerprofile = response.data
          dispatch(fetchEmployerProfileSuccess(employerprofile))
          dispatch(success({messagetext: "Employer Profile was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "EmployerProfileAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchEmployerProfileSuccess = employerprofile => {
  return {
    type: EMPLOYER_PROFILE_SUCCESS,
    payload: employerprofile
  }
}
// EMPLOYER_DETAIL
export const EmployerDetailAction = (id) => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get(`/en/api/e1/employer-detail/${id}/`)
      .then(response => {
          const employerdetail = response.data
          dispatch(fetchEmployerDetailSuccess(employerdetail))
          dispatch(success({messagetext: "Employer Detail was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "EmployerDetailAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchEmployerDetailSuccess = employerdetail => {
  return {
    type: EMPLOYER_DETAIL_SUCCESS,
    payload: employerdetail
  }
}
// EMPLOYER_UPDATE
export const EmployerUpdateAction = (id, data, t) => {
  return (dispatch) => {
    dispatch(request())
    axios.patch(`/en/api/e1/employer-update/${id}/`, data)
      .then(response => {
          dispatch(success({messagetext: (t('message.employerupdatesuccess')), messagestatus: "success" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: t('message.employerupdateerror'), messagestatus: "error"}))
      })
  }
}
// OFFER_DETAIL
export const OfferDetailAction = (id) => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get(`/en/api/e1/vacancy-detail/${id}/`)
      .then(response => {
          const offerdetail = response.data
          dispatch(fetchOfferDetailSuccess(offerdetail))
          dispatch(success({messagetext: "Offer Detail was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "OfferDetailAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchOfferDetailSuccess = offerdetail => {
  return {
    type: OFFER_DETAIL_SUCCESS,
    payload: offerdetail
  }
}
// CATEGORY_LIST
export const CategoryAction = () => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get('/en/api/e1/category-list/')
      .then(response => {
          const category = response.data
          dispatch(fetchCategorySuccess(category))
          dispatch(success({messagetext: "Category List was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "CategoryAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchCategorySuccess = category => {
  return {
    type: CATEGORY_LIST_SUCCESS,
    payload: category
  }
}
// COUNTRY_LIST
export const CountryAction = () => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get("/en/api/l1/country-list/")
      .then(response => {
          const country = response.data
          dispatch(fetchCountrySuccess(country))
          dispatch(success({messagetext: "Country List was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "CountryAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchCountrySuccess = country => {
  return {
    type: COUNTRY_LIST_SUCCESS,
    payload: country
  }
}
// REGION_LIST
export const RegionAction = () => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get('/en/api/s1/region-list')
      .then(response => {
          const region = response.data
          dispatch(fetchRegionSuccess(region))
          dispatch(success({messagetext: "Region List was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "RegionAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchRegionSuccess = region => {
  return {
    type: REGION_LIST_SUCCESS,
    payload: region
  }
}
export const VRegionAction = () => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get('/en/api/l1/region-list')
      .then(response => {
          const region = response.data
          dispatch(VfetchRegionSuccess(region))
          dispatch(success({messagetext: "Region List was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "VRegionAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const VfetchRegionSuccess = region => {
  return {
    type: VREGION_LIST_SUCCESS,
    payload: region
  }
}
// ABOUT_US
export const AboutAction = (lang) => {
  return (dispatch) => {
    dispatch(request())
    lang && axios.get(`${lang}/api/m1/aboutus-list`)
      .then(response => {
          const info = response.data
          dispatch(fetchAboutSuccess(info))
      })
      .catch(error => {
          dispatch(failure({messagetext: "AboutAction failed", messagestatus: "mute"}))
      })
  }
}

export const fetchAboutSuccess = info => {
  return {
    type: ABOUT_US_SUCCESS,
    payload: info
  }
}
// DISTRICT_LIST
export const DistrictListAction = () => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get('/en/api/s1/district-list')
      .then(response => {
          const info = response.data
          dispatch(fetchDistrictListSuccess(info))
          dispatch(success({messagetext: "District List was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "DistrictListAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchDistrictListSuccess = info => {
  return {
    type: DISTRICT_LIST_SUCCESS,
    payload: info
  }
}

// FACULTY_LIST
export const FacultyListAction = () => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get('/en/api/s1/faculty-list')
      .then(response => {
          const info = response.data
          dispatch(fetchFacultyListSuccess(info))
          dispatch(success({messagetext: "Faculty List was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "FacultyListAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchFacultyListSuccess = info => {
  return {
    type: FACULTY_LIST_SUCCESS,
    payload: info
  }
}
// MAJOR_LIST
export const MajorListAction = () => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get('/en/api/s1/major-list')
      .then(response => {
          const major = response.data
          dispatch(fetchMajorListSuccess(major))
          dispatch(success({messagetext: "Major List was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "MajorListAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchMajorListSuccess = major => {
  return {
    type: MAJOR_LIST_SUCCESS,
    payload: major
  }
}
// APPLY_VACANCY
export const ApplyVacancyAction = (data) => {
  return (dispatch) => {
    dispatch(request())
    axios.post('/en/api/e1/apply-vacancy/', data)
      .then(response => {
          dispatch(success({messagetext: "Vacancy successfully Applyed!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "Vacancy failed to Applyed!", messagestatus: "mute"}))
      })
  }
}
// VACANCY_LIST
export const VacancyListAction = () => {
  return (dispatch) => {
    dispatch(request())
          dispatch(apprequest())
    axios.get('/en/api/e1/employer/vacancy-list/')
      .then(response => {
          const vacancy = response.data
          dispatch(fetchVacancySuccess(vacancy))
          dispatch(success({messagetext: "Vacancy List was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "VacancyListAction failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
  }
}

export const fetchVacancySuccess = vacancy => {
  return {
    type: VACANCY_LIST_SUCCESS,
    payload: vacancy
  }
}

// VACANCY_UPDATE
export const VacancyUpdateAction = (id, data) => {
  return (dispatch) => {
    dispatch(request())
    axios.patch(`/en/api/e1/vacancy-update/${id}/`, data)
      .then(response => {
          dispatch(success({messagetext: "Vacancy List was uploaded successfully!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "VacancyUpdateAction failed", messagestatus: "mute"}))
      })
  }
}

// EDUCATION_CREATE
export const EducationCreateListAction = (data) => {
  return (dispatch) => {
    dispatch(request())
    axios.post(`/en/api/s1/resume-education-create/`, data)
      .then(response => {
          dispatch(success({messagetext: "Education successfully created!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "VacancyUpdateAction failed", messagestatus: "mute"}))
      })
  }
}

// EDUCATION_UPDATE
export const EducationUpdateListAction = (id, data) => {
  return (dispatch) => {
    dispatch(request())
    axios.patch(`/en/api/s1/resume-education-update-delete/${id}/`, data)
      .then(response => {
          dispatch(success({messagetext: "Education successfully updated!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "EducationUpdateListAction failed", messagestatus: "mute"}))
      })
  }
}

// EDUCATION_DELETE
export const EducationDelateListAction = (id) => {
  return (dispatch) => {
    dispatch(request())
    axios.delete(`/en/api/s1/resume-education-update-delete/${id}/`)
      .then(response => {
          dispatch(success({messagetext: "Education successfully deleted!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "EducationDelateListAction failed", messagestatus: "mute"}))
      })
  }
}

// EXPERIENCE_CREATE
export const ExperienceCreateListAction = (data) => {
  return (dispatch) => {
    dispatch(request())
    axios.post(`/en/api/s1/resume-experience-create/`, data)
      .then(response => {
          dispatch(success({messagetext: "Experience successfully created!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "ExperienceCreateListAction failed", messagestatus: "mute"}))
      })
  }
}

// EXPERIENCE_UPDATE
export const ExperienceUpdateListAction = (id, data) => {
  return (dispatch) => {
    dispatch(request())
    axios.patch(`/en/api/s1/resume-experience-update-delete/${id}/`, data)
      .then(response => {
          dispatch(success({messagetext: "ExperienceUpdateListAction successfully updated!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "ExperienceUpdateListAction failed", messagestatus: "mute"}))
      })
  }
}

// EXPERIENCE_DELETE  
export const ExperienceDeleteListAction = (id) => {
  return (dispatch) => {
    dispatch(request())
    axios.delete(`/en/api/s1/resume-experience-update-delete/${id}/`)
      .then(response => {
          dispatch(success({messagetext: "Experience successfully deleted!", messagestatus: "mute" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "ExperienceUpdateListAction failed", messagestatus: "mute"}))
      })
  }
}
// VACANCY_DELETE  
export const VacancyDeleteAction = (id, t) => {
  return (dispatch) => {
    dispatch(request())
    axios.delete(`/en/api/e1/vacancy-delete/${id}/`)
      .then(response => {
          dispatch(success({messagetext: t('message.Vacancydeletesuccess'), messagestatus: "success" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: t('message.Vacancydeleteerror'), messagestatus: "error" }))
      })
  }
}
// RESUME_CREATE  
export const ResumeCreateAction = (data) => {
  return (dispatch) => {
    dispatch(request())
    axios.post('/en/api/s1/create-resume', data)
      .then(response => {
          dispatch(success({messagetext: "Resume successfully created!", messagestatus: "success" }))
      })
      .catch(error => {
          dispatch(failure(error.message))
      })
  }
}
// RESUME_UPDATE  
export const ResumeUpdateAction = (id, data, t) => {
  return (dispatch) => {
    dispatch(request())
    axios.patch(`/en/api/s1/resume-update/${id}/`, data)
      .then(response => {
          dispatch(success({messagetext: t('message.resumeupdatesuccess'), messagestatus: "success" }))
      })
      .catch(error => {
          dispatch({messagetext: t('message.resumeupdateerror'), messagestatus: "error" })
      })
  }
}
// APPLYED_STUDENTS_LIST  
export const GetApplyedStudents = (data) => {
  return (dispatch) => {
    dispatch(request())
    dispatch(fetchApplyedStudentsSuccess('clear'))
          dispatch(apprequest())
    data.map(id => {
      axios.patch(`/en/api/s1/student-detail/${id}/`)
      .then(response => {
        dispatch(fetchApplyedStudentsSuccess(response.data))
        dispatch(success({messagetext: "Applyed students uploaded successfully!", messagestatus: "success" }))
      })
      .catch(error => {
          dispatch(failure({messagetext: "GetApplyedStudents failed", messagestatus: "mute"}))
          dispatch(appfailure())
      })
    })
  }
}
export const fetchApplyedStudentsSuccess = data => {
  return {
    type: APPYED_STUDENTS_SUCCESS,
    payload: data
  }
}