import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AboutAction } from "../redux";
import parse from "html-react-parser";
import {useTranslation} from 'react-i18next'
import creating from '../img/center creating.jpg'
import task1 from '../img/center task2.png'
import task2 from '../img/center task1.png'
import task from '../img/center task4.jpg'
import aim from '../img/center aim.jpg'
import person1 from '../img/person1.jpg'
import person2 from '../img/person2.png'
import person3 from '../img/person3.jpg'
import person4 from '../img/person4.jpg'
import person5 from '../img/person5.jpg'
import { FcOvertime, FcPhone, FcAddressBook, FcOrganization  } from "react-icons/fc"

const AboutUs = () => {
    const Image = [person1, person2, person3, person4, person5]
    const {t}=useTranslation();
    const dispatch = useDispatch()
    useEffect(() => {
    dispatch(AboutAction(t('header.lang')))
    }, [t('header.lang')]);
    const aboutinfo = useSelector(state => state.ghost.aboutinfo)
    console.log(aboutinfo);
    return (
        <>
            <div className="container mx-auto xl:my-11 md:my-6 my-4 ">
                <div className="flex xs:flex-row flex-col flex-wrap justify-around md:py-10 py-5 2xl:px-10 w-full ">
                    <div className="lg:w-6/12 xs:w-7/12 xl:py-12 xl:px-10 py-5 xs:px-2 px-0 rounded-lg bg-white " >
                        <p className="xl:text-2xl sm:text-lg xs:text-base text-lg inline-block py-1 px-2 font-bold bg-blue-light  text-white" data-aos="fade-down">
                            {t('aboutus.part1')}
                        </p>
                        <p className="xl:text-base sm:text-sm xs:text-xs text-base xl:mt-6 xl:p-4 mt-2 p-2 " data-aos="fade-down">
                            {t('aboutus.part2')}
                        </p>
                    </div>
                    <div class="xs:w-5/12 " data-aos="fade-down">
                        <img src={creating} alt="about-info-img" className="xs:h-full w-full  rounded-lg object-cover "/>
                    </div>
                </div>
                <div className="flex xs:flex-row flex-col flex-wrap justify-around items-center md:py-10 py-5 2xl:px-10 w-full">
                    <div class="xs:w-5/12" data-aos="fade-down">
                        <img src={aim} alt="about-info-img" className=" w-full rounded-lg object-cover "/>
                    </div>
                    <div className="lg:w-6/12 xs:w-7/12 xl:py-12 xl:px-10 py-5 px-2 rounded-lg bg-white " >
                        <p className="xl:text-2xl sm:text-lg xs:text-base text-lg inline-block py-1 px-2 font-bold bg-blue-light  text-white" data-aos="fade-down" >
                            {t('aboutus.part3')}
                        </p>
                        <p className="xl:text-base sm:text-sm xs:text-xs text-base xl:mt-6 xl:p-4 mt-2 p-2 " data-aos="fade-down">
                            {t('aboutus.part4')}
                        </p>
                        <p className="xl:text-base sm:text-sm xs:text-xs text-base xl:mt-6 xl:p-4 mt-2 p-2 " data-aos="fade-down">
                            {t('aboutus.part5')}
                        </p>
                    </div>
                </div>
                <div className="flex xs:flex-row flex-col flex-wrap justify-around items-center md:py-10 py-5 2xl:px-10 w-full">
                    <div className="lg:w-6/12 xs:w-7/12 xl:py-12 xl:px-10 py-5 px-2 rounded-lg bg-white  " >
                        <p className="xl:text-2xl sm:text-lg xs:text-base text-lg inline-block py-1 px-2 font-bold bg-blue-light  text-white" data-aos="fade-down">
                            {t('aboutus.part6')}
                        </p>
                        <ul className="xl:text-base sm:text-sm xs:text-xs text-base xl:mt-6 xl:p-4 mt-2 p-2  list-disc" data-aos="fade-down">
                            {
                                t('aboutus.part7', {returnObjects: true}).map((card, index) => {
                                    const { text } = card;
                                    return(
                                        <li key={index} className="ml-4"> {text} </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div class="xs:w-5/12">
                        <img src={task2} alt="about-info-img" className="w-full xl:h-[305px] sm:h-[260px] xs:h-[205px] xl:mt-6 mt-2 object-cover rounded-lg " data-aos="fade-down"/>
                        <img src={task} alt="about-info-img" className="w-full xl:h-[305px] sm:h-[260px] xs:h-[205px] xl:mt-6 mt-2 object-cover rounded-lg " data-aos="fade-down"/>
                        <img src={task1} alt="about-info-img" className="w-full xl:h-[305px] sm:h-[260px] xs:h-[205px] xl:mt-6 mt-2 xl:mb-10 mb-5 object-cover rounded-lg " data-aos="fade-down"/>
                    </div>
                </div>
                <div className="my-20" data-aos="fade-down">
                    <p className="xl:text-2xl  sm:text-lg xs:text-base text-lg font-medium text-[#303030]" >{t('aboutus.part8')}</p>
                    <p className="xl:text-2xl sm:text-xl xs:text-lg text-lg leading-tight xl:font-medium font-semibold text-[#1b1b1b] xl:w-[850px] sm:w-[650px] xs:w-[580px] w-full my-2" > {t('aboutus.part9')} </p>
                </div>
                <div className="flex flex-col">
                    <div className="flex justify-center">
                        <div className="group w-[350px] h-[450px] overflow-hidden " data-aos="fade-down">
                            <img className="w-[350px] h-[360px] object-cover group-hover:brightness-75" src={person1} alt="Karimov Valijon Alievich" />
                            <div className="group-hover:-translate-y-full bg-white transition-all group-hover:bg-opacity-60 p-2 ">
                                <p className="lg:text-2xl xs:text-xl text-2xl font-semibold" >{t('aboutus.part10', {returnObjects: true})[0].name}</p>
                                <p className="lg:text-xl xs:text-lg text-xl mt-1 text-blue-light" >{t('aboutus.part10', {returnObjects: true})[0].position}</p>
                                <div className="invisible group-hover:visible lg:text-lg xs:text-base text-lg font-medium mt-2">
                                    <p className="flex items-center" > <FcOvertime className="text-xl mr-2" /> {t('aboutus.part10', {returnObjects: true})[0].time} </p>
                                    <p className="flex items-center" > <FcPhone className="text-xl mr-2 -rotate-90 " /> {t('aboutus.part10', {returnObjects: true})[0].phone}</p>
                                    <p className="flex items-center" > <FcAddressBook className="text-xl mr-2"/> {t('aboutus.part10', {returnObjects: true})[0].mail}</p>
                                    <p className="flex items-center" > <FcOrganization className="text-xl mr-2" /> {t('aboutus.part10', {returnObjects: true})[0].location}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-around gap-20 mt-8" data-aos="fade-down">
                        {
                            t('aboutus.part10', {returnObjects: true}).map((card, index) => {
                                const {name, position, time, phone, mail, location} = card
                                return(
                                    <>
                                        {
                                            index!==0 && 
                                            (
                                                <div className="group w-[330px] h-[450px] overflow-hidden " >
                                                    <img className="w-[330px] h-[340px] object-cover group-hover:brightness-75" src={Image[index]} alt="Karimov Valijon Alievich" />
                                                    <div className="group-hover:-translate-y-full bg-white transition-all group-hover:bg-opacity-60 p-2 ">
                                                        <p className="lg:text-2xl xs:text-xl text-2xl font-semibold" >{name}</p>
                                                        <p className="lg:text-xl xs:text-lg text-xl mt-1 text-blue-light" >{position}</p>
                                                        <div className="invisible group-hover:visible lg:text-lg xs:text-base text-lg font-medium mt-2">
                                                            <p className="flex items-center" > <FcOvertime className="text-xl mr-2" /> {time}</p>
                                                            <p className="flex items-center" > <FcPhone className="text-xl mr-2 -rotate-90 " />  {phone} </p>
                                                            <p className="flex items-center" > <FcAddressBook className="text-xl mr-2"/> {mail} </p>
                                                            <p className="flex items-center" > <FcOrganization className="text-xl mr-2" /> {location} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUs;
