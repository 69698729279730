import React, { useRef } from "react";
import { useClickOutside } from "../../hooks/clickOutside";

const WarningModal = ({ title, toggleShow, value, callbackFunc }) => {
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    toggleShow(false);
  });
  return (
    <div
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      className="fixed top-0 left-0 w-screen h-screen flex justify-center z-100"
    >
      <div ref={wrapperRef} className="min-w-[250px] h-min bg-white py-[10px] px-[20px] mt-[10%]">
        <div className="lg-text text-center">{title}</div>
        <div className="flex justify-between mt-[15px]">
            <button className="bg-blue-light text-white py-[5px] px-[25px]" onClick={() => {
                callbackFunc(value)
                toggleShow(false)
            }}>Ha</button>
            <button className="bg-red-dark text-white py-[5px] px-[25px]" onClick={() => {
                toggleShow(false)
            }}>Yo'q</button>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
