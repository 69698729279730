import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { VacancyDeleteAction, VacancyListAction } from "../../redux";
import { useTranslation } from "react-i18next";
import WarningModal from "../WarningModal";

const EmployerSidebar = () => {
  const { t } = useTranslation();
  const { vacancylist } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [employerVacancyList, setEmployerVacancyList] = useState([]);
  const [vacancyId, setVacancyId] = useState('');
  const [toggleShow, setToggleShow] = useState(false);

  useEffect(() => {
    dispatch(VacancyListAction());
  }, []);

  useEffect(() => {
    setEmployerVacancyList(vacancylist);
  }, [vacancylist]);

  function DeleteVacancy(id) {
    dispatch(VacancyDeleteAction(id, t));
    setTimeout(() => {
      dispatch(VacancyListAction());
    }, 500);
  }
  

  return (
    <>
      {toggleShow && <WarningModal title={'O\'chirilsinmi'} value={vacancyId} callbackFunc={DeleteVacancy} toggleShow={setToggleShow} />}
      <div>
        <div className="flex justify-between items-center my-[15px]">
          <div className="flex items-center">
            <h3 className="lg-text sm:xl-text font-bold text-grey">
              {t("jobOffers.vacancy")}
            </h3>
          </div>
          <Link
            to={"/create-vacancy"}
            className="w-auto h-auto ml-[15px] bg-blue-light py-[10px] px-[15px] hover:bg-blue-white sm-text sm:md-text text-white text-center font-bold"
          >
            {t("buttons.vacancyadd")}
          </Link>
        </div>
        <div className="grid md:grid-cols-3 gap-[15px]">
          {employerVacancyList.length ? (
            employerVacancyList?.map((vacancy) => (
              <div
                key={vacancy.id}
                className={`col-span-1 w-full flex flex-col group hover:shadow-lg shadow-md border border-grey-light hover:border-blue-light rounded-sm p-[15px]`}
                data-aos="fade-down"
              >
                <Link
                  to={`/offers/job${vacancy?.id}`}
                  className="w-full flex flex-col"
                >
                  <div className="flex items-center">
                    <div className="felx">
                      <h4 className="lg-text font-semibold group-hover:text-blue-light mt-[10px]">
                        {vacancy.title}
                      </h4>
                      <p className="text-grey">{vacancy.category}</p>
                    </div>
                  </div>
                  {/* info */}
                  <div className="w-full flex justify-between items-end mt-[15px]">
                    <div className="flex flex-col">
                      <p className="text">{vacancy.salary} UZS</p>
                      <p className="sm-text text-grey">{vacancy.region}</p>
                    </div>
                  </div>
                  <div className="md-text">{vacancy.updated_at}</div>
                </Link>
                {/* edit and delete */}
                <div className="flex w-full mt-[15px]">
                  <Link
                    to={`/employer/edit-vacancy${vacancy.id}`}
                    className="w-1/2 border border-grey-light text-grey font-bold flex justify-center items-center hover:text-white hover:bg-blue-light"
                  >
                    Tahrirlash
                    <FaRegEdit className="md-text cursor-pointer my-[15px] ml-[5px]" />
                  </Link>
                  <button
                    onClick={() => {
                      setToggleShow(true);
                      setVacancyId(vacancy.id);
                    }}
                    className="w-1/2 border border-grey-light text-grey font-bold flex justify-center items-center hover:text-white hover:bg-red-dark"
                  >
                    O'chirish
                    <MdDelete className="md-text cursor-pointer my-[15px] ml-[5px]" />
                  </button>
                </div>
                {/* count */}
                <div className="absolute top-0 right-0 m-[10px] flex items-center cursor-default">
                  <div className="xs-text mr-[5px]">
                    qiziqish bildirganlar soni
                  </div>
                  <div className="w-[20px] h-[20px] flex justify-center items-center rounded-full bg-blue-light text-white md-text">
                    {vacancy.applier_count}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="md-text">Sizda ish o'rinlari yo'q</div>
          )}
        </div>
      </div>
    </>
  );
};

export default EmployerSidebar;
