import { useEffect, useRef, useState } from 'react';
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';
import {useClickOutside} from '../../hooks/clickOutside'
import {useTranslation} from 'react-i18next'


const Dropdown = ({ icon = null, customClass, width = '150px', langs=[], onChange}) => {
  const {i18n}=useTranslation();
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    setShowDrop(false)
  });
  const [lang, setLang] = useState(langs[0]);
  const [showDrop, setShowDrop] = useState(false);

  useEffect(() => {
    const lang = langs.find(l => l.value === i18n.language)
    setLang(lang)
  }, [i18n.language]);
  
  return (
    <div ref={wrapperRef} className={`w-[${width}] flex justify-end relative z-20`}>
        <button 
          onClick={() => setShowDrop(!showDrop)}
          className={`${customClass} w-max flex items-center justify-center border border-grey-light rounded-sm py-[5px] px-[5px] lg:py-[8px] lg:px-[8px] transition ease-in delay-75 hover:shadow-sm hover:shadow-teal-bright`}>
          {lang.flag && <img src={lang.flag} alt="flag" className="w-[24px] h-[24px] mr-[5px]" />}
          <p className='sm-text'>{lang.title}</p>
          {icon === 'down' ? <VscChevronDown className='ml-[5px]' /> : icon === 'up' && <VscChevronUp className='ml-[5px]' /> }
        </button>
        <ul className={`${showDrop ? 'block' : 'hidden'} absolute top-full border border-grey-light rounded-sm mt-[3px] bg-white`}>
          {langs.length ?langs.map((lang, i) => {
            return (
              <li
                onClick={() => {
                  setLang(lang)
                  setShowDrop(false)
                  onChange(lang.value)
                }} 
                key={i} 
                className={`transition ease-in duration-150 w-full flex items-center py-[7px] px-[10px] cursor-pointer hover:bg-blue-light hover:text-white`}>
                {lang.flag && <img src={lang.flag} alt="flag" className="w-[24px] h-[24px] mr-[5px]" />}
                <p className='sm-text'>{lang.title}</p>
              </li>
            )
          }): ''}
        </ul>
    </div>
  )
}

export default Dropdown;