import { useState } from "react";
import { VscChevronDown } from "react-icons/vsc";

const Selections = ({
  selections = [],
  title,
  button = false,
  buttonTitle = "text",
  selectType = "single",
  customClass = "",
  callback = () => {},
  value,
  values = [],
}) => {
  const [toggle, setToggle] = useState(true);

  return (
    <div className={`${customClass} h-min border border-grey-light rounded-sm`}>
      <button
        onClick={() => setToggle(!toggle)}
        className="w-full flex justify-between items-center p-[5px] cursor-pointer bg-white-light"
      >
        <div className="lg-text text-blue-light font-semibold">{title}</div>
        <VscChevronDown
          className={`${
            !toggle ? "rotate" : "-rotate-180"
          } transition-all duration-300 xl-text`}
        />
      </button>
      <div className="">
        <div className={`${!toggle && "hidden"}`}>
          {selections.length
            ? selections?.map((selection, i) => {
                return (
                  <button
                    onClick={() => {
                      if (selectType === "single") {
                        value === selection.value || value === selection.title
                          ? callback(null)
                          : selection.title
                          ? callback(selection.title)
                          : selection.value && callback(selection.value);
                      } else {
                        values?.includes(i)
                          ? callback(values.filter((item) => item !== i + 1))
                          : callback([...values, i + 1]);
                      }
                    }}
                    key={i}
                    className={`flex items-center cursor-pointer p-[5px] group`}
                  >
                    <div
                      className={`${
                        (value === selection.value ||
                          value === selection.title) &&
                        "bg-blue-light"
                      } ${
                        values?.includes(i) && "bg-blue-light"
                      } min-w-[15px] h-[15px] border border-grey-light rounded-full group-hover:border-blue-light`}
                    ></div>
                    <div
                      className={`${
                        value - 1 === i ? "text-blue-light" : "text-grey"
                      } ml-[5px] md-text text-left group-hover:text-blue-light`}
                    >
                      {selection.label || selection.value || selection.title}
                    </div>
                  </button>
                );
              })
            : ""}
        </div>
      </div>
      {button && (
        <button
          className={`${
            values.length
              ? "bg-blue-light text-white"
              : "hover:translate-y-[0] bg-grey-light"
          } w-full xs:w-1/2 lg:w-full py-[5px] text-grey mt-[10px] rounded-md transition ease-in hover:translate-y-[-2px] mx-auto block`}
        >
          {buttonTitle}
        </button>
      )}
    </div>
  );
};

export default Selections;
