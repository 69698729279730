import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api_url } from "../../static";
import Dropdown from "../DropDown";
import { languages } from "../../static";
import { useTranslation } from "react-i18next";
import { HiUserCircle } from "react-icons/hi";
import { LogoutAction } from "../../redux";
import { ImExit } from "react-icons/im";
import { useClickOutside } from "../../hooks/clickOutside";

const Header = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { ghost, app } = useSelector((state) => state);
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const [showUserNav, setShowUserNav] = useState(false);
  const userNavRef = useRef();
  useClickOutside(userNavRef, () => setShowUserNav(false));

  return (
    <div className="w-full shadow-md mt-[100px] py-[5px]">
      <div className="container mx-auto grid grid-cols-12">
        {/* logo and languages */}
        <div className="col-span-3 flex justify-between items-center">
          <img
            src={`${
              ghost.mainlist.logo && api_url + ghost.mainlist.logo.image
            }`}
            alt="logo"
            onClick={() => navigate("/")}
            className="min-w-[150px] max-w-[150px] h-[50px] cursor-pointer"
          />
          <Dropdown langs={languages} icon={"down"} onChange={changeLanguage} />
        </div>
        {/* navigations */}
        <div className="col-span-7">
          <ul className="h-full flex items-center justify-evenly text-grey">
            {t("header.menuItems", { returnObjects: true }).map(
              (menuItem, index) => {
                const { menuTitle, pageURL } = menuItem;
                return (
                  <li
                    key={index}
                    onClick={() => navigate(pageURL)}
                    className={
                      "font-bold cursor-pointer hover:border-b-[2px] hover:border-blue-light hover:text-blue-light" +
                      // "border-b-[2px] border-white hover:border-blue-light hover:border-b-[2px]" +
                      ` ${
                        pageURL === "candidates" && app.role === "Student"
                          ? "hidden"
                          : ""
                      }`
                    }
                  >
                    {menuTitle}
                  </li>
                );
              }
            )}
          </ul>
        </div>
        {/* user */}
        <div
          ref={userNavRef}
          className="relative col-span-2 flex justify-end items-center cursor-pointer"
        >
          <div
            onClick={() => setShowUserNav(!showUserNav)}
            className="flex items-center"
          >
            <div
              style={{
                backgroundImage: `url(${
                  app?.studentuser?.image || app?.employeruser?.image
                })`,
              }}
              className="w-[45px] h-[45px] bg-red bg-cover bg-center rounded-full border border-grey-light"
            ></div>
            <div className="ml-[5px] text-grey">{app?.studentuser?.full_name || app?.employeruser?.name}</div>
          </div>
          <div
            className={`absolute top-full right-0 bg-white w-[150px] max-h-0 border-l border-r border-grey-light ${
              showUserNav && "max-h-[500px]"
            } overflow-hidden transition-all ease-in-out duration-300 mt-[5px] shadow-md`}
          >
            <ul>
              <li>
                <div
                  onClick={() => {
                    app.role === "Employer"
                      ? navigate("/employer-profile")
                      : navigate("/student-profile");
                    setShowUserNav(false);
                  }}
                  className="flex items-center hover:bg-blue-light text-grey border-y border-grey-light hover:text-white py-[10px] px-[15px]"
                >
                  <HiUserCircle className="xl-text mr-[5px]" />
                  Profile
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    dispatch(LogoutAction(navigate, t));
                    setShowUserNav(false);
                  }}
                  className="flex items-center hover:bg-red-dark text-grey border-y border-grey-light hover:text-white py-[10px] px-[15px]"
                >
                  <ImExit className="lg-text mr-[5px]" />
                  {t("header.button1")}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
