import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Select from "react-select";
import { VscFileSymlinkFile, VscCheckAll } from "react-icons/vsc";
import axios from "axios";
import {useTranslation} from 'react-i18next'
import { ToastContainer, toast } from "react-toastify";

const Apply = () => {
  const { t } = useTranslation();
  const options = [
    { value: "Complaint", label: "Shikoyat" },
    { value: "Offer", label: "Taklif" },
    { value: "Application", label: "Ariza" },
  ];
  const [selectedOption, setSelectedOption] = useState(options[2].value);
  const [success, setSuccess] = useState(false);
  const [file, setFile] = useState(null);
  const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];

  function SendApply(fields) {
    const formData = new FormData();
    file && formData.append("file", file);
    formData.append("appeal_type", selectedOption);
    formData.append("fullname", fields.fullname);
    formData.append("email", fields.email);
    formData.append("phone", fields.phone);
    formData.append("description", fields.description);
    formData.append("address", fields.address);
    axios
      .post("en/api/m1/appeal-create", formData)
      .then((res) => {
        setSuccess(true);
        toast.success(t('message.appealcreatesuccess'), {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        setSuccess(false);
        toast.error(t('message.appealcreateerror'), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="container mx-auto my-[30px]">
        <h1 className="xl2-text text-blue-light font-bold">
          {t("sidebar.sidebartext1")}
        </h1>
        <div className="grid lg:grid-cols-12 gap-[15px] my-[15px]">
          {success ? (
            <div className="col-span-12 lg:col-span-10 flex items-center">
              <VscCheckAll className="xl2-text text-green" />
              <div className="xl-text text-green ml-[15px]">
                {t("message.apply")}
              </div>
            </div>
          ) : (
            <div className="col-span-12 lg:col-span-10">
              <Formik
                initialValues={{
                  fullname: "",
                  phone: "",
                  email: "",
                  address: "",
                  description: "",
                }}
                validationSchema={Yup.object().shape({
                  fullname: Yup.string().required("fullname is required"),
                  phone: Yup.string().required("Phone number is required").test({name: 'max', exclusive: true, params: {max: 13}, message: 'Phone number must be 13', test: value => value.length === 13}),
                  address: Yup.string().required("Address is required"),
                  description: Yup.string().required("description is required"),
                  email: Yup.string()
                    .email("Email is invalid")
                    .required("Email is required"),
                })}
                onSubmit={(fields) => {
                  SendApply(fields);
                }}
              >
                {({ errors, touched, handleBlur, handleChange }) => (
                  <Form className="grid grid-cols-1 md:grid-cols-2 gap-[15px]">
                    <div className="col-span-1">
                      <label
                        className="text-grey font-semibold"
                        htmlFor="fullname"
                      >
                        {t("contacts.name")}
                      </label>
                      <Field
                        name="fullname"
                        type="text"
                        placeholder={t("contacts.name")}
                        className={
                          "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                          (errors.fullname && touched.fullname
                            ? " border-red"
                            : "")
                        }
                      />
                    </div>
                    <div className="col-span-1">
                      <label
                        className="text-grey font-semibold"
                        htmlFor="phone"
                      >
                        {t("studentProfile.phone")}
                      </label>
                      <Field name="phone" >
                        {({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={phoneNumberMask}
                            name="phone"
                            id="phone"
                            placeholder="Telefon raqamingizni kiriting"
                            type="tel"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                              (errors.phone && touched.phone
                                ? " border-red"
                                : "")
                            }
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-span-1">
                      <label
                        className="text-grey font-semibold"
                        htmlFor="email"
                      >
                        {t("studentProfile.mail")}
                      </label>
                      <Field
                        name="email"
                        type="text"
                        placeholder="career@mail.com"
                        className={
                          "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                          (errors.email && touched.email ? " border-red" : "")
                        }
                      />
                    </div>
                    <div className="col-span-1">
                      <label
                        className="text-grey font-semibold"
                        htmlFor="address"
                      >
                        {t("createCV.location")}
                      </label>
                      <Field
                        name="address"
                        type="text"
                        placeholder="Shaxar, Tuman, Ko'cha, Uy"
                        className={
                          "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                          (errors.address && touched.address
                            ? " border-red"
                            : "")
                        }
                      />
                    </div>
                    <div className="col-span-1">
                      <label
                        className="text-grey font-semibold"
                        htmlFor="applyType"
                      >
                        {t("apply.applytype")}
                      </label>
                      <Select
                        options={options}
                        defaultValue={options[2]}
                        onChange={(e) => setSelectedOption(e.value)}
                      />
                    </div>
                    <div className="col-span-1">
                      <label
                        className="text-grey font-semibold"
                        htmlFor="description"
                      >
                        {t("apply.apply")}
                      </label>
                      <Field
                        name="description"
                        type="text"
                        as="textarea"
                        placeholder={t("apply.apply")}
                        className={
                          "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                          (errors.description && touched.description
                            ? " border-red"
                            : "")
                        }
                      />
                    </div>
                    <div className="col-span-1 lg:col-span-2 text-center relative border border-dashed border-grey-light rounded-sm py-[15px] group hover:border-blue-light">
                      {file ? (
                        <div className="text-grey md-text">{file.name}</div>
                      ) : (
                        <>
                          <VscFileSymlinkFile className="text-[50px] mx-auto text-grey group-hover:text-blue-light" />
                          <div className="text-grey lg-text font-semibold">
                            {t("apply.file")}
                            <span className="text-blue-light">
                              {t("apply.file1")}
                            </span>
                          </div>
                        </>
                      )}
                      <input
                        type="file"
                        className="absolute top-0 left-0 w-full h-full opacity-0  cursor-pointer"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />
                    </div>
                    {/* submit */}
                    <div className="col-span-1 lg:col-span-2">
                      <button
                        type="submit"
                        className="bg-blue-white hover:bg-blue-light text-white border mr-[15px] border-grey-light py-[5px] px-[10px] rounded-sm transition"
                      >
                        {t("buttons.send")}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {/* SIDEBAR */}
          <div className="col-span-12 lg:col-span-2">
            <Sidebar apply={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apply;
