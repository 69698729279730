import React from "react";

const Input = ({ type = "text", id, placeholder, customClass, change, value }) => {

  return (
    <input
      onChange={change}
      type={type}
      id={id}
      placeholder={placeholder}
      value={value}
      className={`focus:outline-blue-light focus:border-r-0 border border-grey-light rounded-sm py-[10px] px-[15px] ${customClass}`}
    />
  );
};

export default Input;
