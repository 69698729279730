import React from 'react'
import { useSelector } from 'react-redux'
import Header from '../components/Header/Header'

const Example = () => {

  return (
    <div>
      <Header />
    </div>
  )
}

export default Example