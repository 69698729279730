import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useRef, useState } from "react";
import { useClickOutside } from "../../hooks/clickOutside";
import { degrees } from "../../static";
import { useDispatch } from "react-redux";
import {
  ProfileAction,
  EducationCreateListAction,
  ExperienceCreateListAction,
} from "../../redux/Action/appAction";

const EditResumeModal = ({ mode, modal, sendFileds, values }) => {
  const [still, setStill] = useState(false);
  const dispatch = useDispatch();
  const educationModalRef = useRef();
  useClickOutside(educationModalRef, () => {
    modal(false);
  });
  const workingModalRef = useRef();
  useClickOutside(workingModalRef, () => {
    modal(false);
  });

  return (
    <div className="container mx-auto">
      {/* Education */}
      {mode === "education" ? (
        <Formik
          enableReinitialize={true}
          initialValues={{
            unv_title: "",
            specialty: "",
            begin_date: "",
            finish_date: null,
            status: false,
            degree: degrees[0].value,
          }}
          validationSchema={Yup.object().shape({
            unv_title: Yup.string().required(),
            specialty: Yup.string().required(),
            begin_date: Yup.string().required(),
            finish_date: !still && Yup.string().required(),
            degree: Yup.string().required(),
          })}
          onSubmit={async (fields) => {
            const data = await { ...fields, status: still };
            await dispatch(EducationCreateListAction(data));
            await dispatch(ProfileAction());
            await modal(false);
          }}
          render={({ errors, touched }) => (
            <Form
              style={{ backgroundColor: "rgba(0, 0, 0, 0.50)" }}
              className={`${
                false && "hidden"
              } fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-50`}
            >
              <div
                ref={educationModalRef}
                className="bg-white grid grid-cols-1 md:grid-cols-2 gap-[15px] p-[15px]"
              >
                <div className="col-span-1">
                  <label
                    className="text-grey font-semibold"
                    htmlFor="unv_title"
                  >
                    Oliygoh nomi
                  </label>
                  <Field
                    name="unv_title"
                    type="text"
                    placeholder="TDIU"
                    className={
                      "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                      (errors.unv_title && touched.unv_title
                        ? " border-red"
                        : "")
                    }
                  />
                </div>
                <div className="col-span-1">
                  <label
                    className="text-grey font-semibold"
                    htmlFor="specialty"
                  >
                    Mutaxassisligi
                  </label>
                  <Field
                    name="specialty"
                    type="text"
                    placeholder="Ekonomika"
                    className={
                      "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                      (errors.specialty && touched.specialty
                        ? " border-red"
                        : "")
                    }
                  />
                </div>
                <div className="col-span-1">
                  <label className="text-grey font-semibold" htmlFor="degree">
                    Daraja
                  </label>
                  <Field
                    name="degree"
                    type="text"
                    as="select"
                    className={
                      "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                      (errors.degree && touched.degree ? " border-red" : "")
                    }
                  >
                    {degrees?.map((degree, index) => {
                      return (
                        <option key={index} value={degree.value}>
                          {degree.value}
                        </option>
                      );
                    })}
                  </Field>
                </div>
                <div className="col-span-1"></div>
                {/* begin_date */}
                <div className="col-span-1">
                  <label
                    className="text-grey font-semibold"
                    htmlFor="begin_date"
                  >
                    Boshlangan sanagiz
                  </label>
                  <Field
                    name="begin_date"
                    type="date"
                    className={
                      "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                      (errors.begin_date && touched.begin_date
                        ? " border-red"
                        : "")
                    }
                  />
                </div>
                {/* finish_date */}
                <div className="col-span-1">
                  <label
                    className="text-grey font-semibold"
                    htmlFor="finish_date"
                  >
                    Yakunlangan sanagiz
                  </label>
                  <Field
                    name="finish_date"
                    type="date"
                    disabled={still}
                    className={
                      "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                      (errors.finish_date && touched.finish_date
                        ? " border-red"
                        : "")
                    }
                  />
                  <div className="sm-text flex items-center">
                    <input
                      onChange={(e) => setStill(e.target.checked)}
                      id="still"
                      type="checkbox"
                      className="cursor-pointer mr-[5px]"
                    />
                    <label htmlFor="still" className="cursor-pointer">
                      Hozir ham o‘qiyman
                    </label>
                  </div>
                </div>
                <div className="col-span-1 lg:col-span-2">
                  <button
                    type="submit"
                    className="bg-blue-white hover:bg-blue-light text-white border mr-[15px] border-grey-light py-[5px] px-[10px] rounded-sm transition"
                  >
                    Yuborish
                  </button>
                  <button
                    type="reset"
                    className="bg-blue-white hover:bg-blue-light text-white border  border-grey-light py-[5px] px-[10px] rounded-sm transition"
                  >
                    O'chirish
                  </button>
                </div>
              </div>
            </Form>
          )}
        />
      ) : (
        <Formik
          initialValues={{
            title: "",
            specialty: "",
            begin_date: "",
            finish_date: null,
            status: false,
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required(),
            specialty: Yup.string().required(),
            begin_date: Yup.string().required(),
            finish_date: !still && Yup.string().required(),
          })}
          onSubmit={async (fields) => {
            const data = await { ...fields, status: still };
            await dispatch(ExperienceCreateListAction(data));
            await dispatch(ProfileAction());
            await modal(false);
          }}
          render={({ errors, touched }) => (
            <Form
              style={{ backgroundColor: "rgba(0, 0, 0, 0.50)" }}
              className={`${
                false && "hidden"
              } fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-50`}
            >
              <div
                ref={workingModalRef}
                className="bg-white grid grid-cols-1 md:grid-cols-2 gap-[15px] p-[15px]"
              >
                <div className="col-span-1">
                  <label className="text-grey font-semibold" htmlFor="title">
                    Ish Joyingiz
                  </label>
                  <Field
                    name="title"
                    type="text"
                    placeholder="TDIU"
                    className={
                      "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                      (errors.title && touched.title ? " border-red" : "")
                    }
                  />
                </div>
                <div className="col-span-1">
                  <label
                    className="text-grey font-semibold"
                    htmlFor="specialty"
                  >
                    Mutaxassisligi
                  </label>
                  <Field
                    name="specialty"
                    type="text"
                    placeholder="Ekonomika"
                    className={
                      "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                      (errors.specialty && touched.specialty
                        ? " border-red"
                        : "")
                    }
                  />
                </div>
                {/* begin_date */}
                <div className="col-span-1">
                  <label
                    className="text-grey font-semibold"
                    htmlFor="begin_date"
                  >
                    Boshlangan sanagiz
                  </label>
                  <Field
                    name="begin_date"
                    type="date"
                    className={
                      "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                      (errors.begin_date && touched.begin_date
                        ? " border-red"
                        : "")
                    }
                  />
                </div>
                {/* finish_date */}
                <div className="col-span-1">
                  <label
                    className="text-grey font-semibold"
                    htmlFor="finish_date"
                  >
                    Yakunlangan sanagiz
                  </label>
                  <Field
                    name="finish_date"
                    type="date"
                    disabled={still}
                    className={
                      "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                      (errors.finish_date && touched.finish_date
                        ? " border-red"
                        : "")
                    }
                  />
                  <div className="sm-text flex items-center">
                    <input
                      onChange={(e) => setStill(e.target.checked)}
                      id="still"
                      type="checkbox"
                      className="cursor-pointer mr-[5px]"
                    />
                    <label htmlFor="still" className="cursor-pointer">
                      Hozir ham o‘qiyman
                    </label>
                  </div>
                </div>
                <div className="col-span-1 lg:col-span-2">
                  <button
                    type="submit"
                    className="bg-blue-white hover:bg-blue-light text-white border mr-[15px] border-grey-light py-[5px] px-[10px] rounded-sm transition"
                  >
                    Qo'shish
                  </button>
                  <button
                    type="reset"
                    className="bg-blue-white hover:bg-blue-light text-white border  border-grey-light py-[5px] px-[10px] rounded-sm transition"
                  >
                    Tozalash
                  </button>
                </div>
              </div>
            </Form>
          )}
        />
      )}
    </div>
  );
};

export default EditResumeModal;
