import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import React, { useEffect, useMemo, useState } from "react";
import { FaUserGraduate } from "react-icons/fa";
import {useTranslation} from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import { EmployerProfileAction, EmployerUpdateAction, } from "../redux/Action/appAction";

const CVInfoPhoto = ({ photo, setPhoto }) => {
  const {t}=useTranslation();
  return useMemo(() => {
    return (
      <div className="w-full md:w-4/12 mr-[15px] mb-[30px] md:mb-0">
        <div
          style={{
            backgroundImage: `url(${
              typeof photo === "string"
                ? photo
                : photo && URL.createObjectURL(photo)
            })`,
          }}
          className={`relative w-full h-[160px] md:h-[250px] bg-cover bg-center flex flex-col justify-center items-center rounded-sm border border-grey-light hover:bg-grey-light cursor-pointer`}
        >
          {!photo ? (
            <>
              <FaUserGraduate className="text-[40px] md:text-[50px] text-grey" />
              <p className="text-grey mt-[10px] lg-text">{t('createCV.photo')}</p>{" "}
            </>
          ) : (
            ""
          )}
          <input
            onChange={(e) => setPhoto(e.target.files[0])}
            type="file"
            className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
          />
        </div>
      </div>
    );
  }, [photo, setPhoto, t]);
};

const EmployerEditPage = () => {
  const {t}=useTranslation();
  const [photo, setPhoto] = useState(null);
  const dispatch = useDispatch();
  const employer = useSelector((state) => state.app.employeruser);
  useEffect(() => {
    setPhoto(employer?.image);
    window.scroll(0, 0);
  }, [employer]);
  const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];

  // const mdParser = new MarkdownIt();

  return (
    <div className="container mx-auto my-[30px]">
      <CVInfoPhoto photo={photo} setPhoto={setPhoto} />
      <Formik
        initialValues={{
          name: employer?.name,
          phone: employer?.phone,
          email: employer?.email,
          address: employer?.address,
          description: employer?.description,
          status: employer?.status,
          website: employer?.website,
          telegram: employer?.telegram,
          instagram: employer?.instagram,
          facebook: employer?.facebook,
          linkedin: employer?.linkedin,
          youtube: employer?.youtube,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email(),
        //   website: Yup.string().url(),
        //   telegram: Yup.string().url(),
        //   instagram: Yup.string().url(),
        //   facebook: Yup.string().url(),
        //   linkedin: Yup.string().url(),
        //   youtube: Yup.string().url(),
        })}
        onSubmit={async (fields) => {
          if (typeof photo !== "string") {
            const formData = await new FormData();
            await formData.append("image", photo);
            await dispatch(EmployerUpdateAction(employer.id, formData, t));
          }
          await dispatch(EmployerUpdateAction(employer.id, fields, t));
          await dispatch(EmployerProfileAction(employer.id));
        }}
      >
        {({ errors, touched, handleBlur, handleChange }) => (
          <Form className="grid grid-cols-1 md:grid-cols-2 gap-[15px]">
            {/* name */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="name">
                {t('EmployerEditPage.name')}
              </label>
              <Field
                name="name"
                type="text"
                placeholder={t('EmployerEditPage.name')}
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.name && touched.name ? " border-red" : "")
                }
              />
            </div>
            {/* phone */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="phone">
                {t('contacts.phone')}
              </label>
              <Field name="phone" >
                {({ field }) => (
                  <MaskedInput
                    {...field}
                    mask={phoneNumberMask}
                    id="phone"
                    type="tel"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                      (errors.phone && touched.phone ? " border-red" : "")
                    }
                  />
                )}
              </Field>
            </div>
            {/* email */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="email">
              {t('contacts.mail')}
              </label>
              <Field
                name="email"
                type="email"
                placeholder="email"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.email && touched.email ? " border-red" : "")
                }
              />
            </div>
            {/* address */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="address">
              {t('contacts.address')}
              </label>
              <Field
                name="address"
                type="text"
                placeholder={t('contacts.address')}
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.address && touched.address ? " border-red" : "")
                }
              />
            </div>
            {/* description */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="description">
              {t('EmployerEditPage.definition')}
              </label>
              <Field
                name="description"
                type="text"
                placeholder={t('EmployerEditPage.definition')}
                as="textarea"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px] h-[150px]" +
                  (errors.description && touched.description
                    ? " border-red"
                    : "")
                }
              />
            </div>
            {/* website */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="website">
                Web-sayt
              </label>
              <Field
                name="website"
                type="text"
                placeholder="website"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.website && touched.website ? " border-red" : "")
                }
              />
            </div>
            {/* telegram */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="telegram">
                Telegram
              </label>
              <Field
                name="telegram"
                type="text"
                placeholder="Manzil"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.telegram && touched.telegram ? " border-red" : "")
                }
              />
            </div>
            {/* instagram */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="instagram">
                Instagram
              </label>
              <Field
                name="instagram"
                type="text"
                placeholder="Manzil"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.instagram && touched.instagram ? " border-red" : "")
                }
              />
            </div>
            {/* facebook */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="facebook">
                facebook
              </label>
              <Field
                name="facebook"
                type="text"
                placeholder="Manzil"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.facebook && touched.facebook ? " border-red" : "")
                }
              />
            </div>
            {/* linkedin */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="linkedin">
                linkedin
              </label>
              <Field
                name="linkedin"
                type="text"
                placeholder="Manzil"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.linkedin && touched.linkedin ? " border-red" : "")
                }
              />
            </div>
            {/* youtube */}
            <div className="col-span-1">
              <label className="text-grey font-semibold" htmlFor="youtube">
                youtube
              </label>
              <Field
                name="youtube"
                type="text"
                placeholder="Manzil"
                className={
                  "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                  (errors.youtube && touched.youtube ? " border-red" : "")
                }
              />
            </div>

            {/* submit */}
            <div className="col-span-1 lg:col-span-2">
              <button
                type="submit"
                className="bg-blue-white hover:bg-blue-light text-white border mr-[15px] border-grey-light py-[5px] px-[10px] rounded-sm transition"
              >
                {t('buttons.send')} 
              </button>
              <button
                type="reset"
                className="bg-blue-white hover:bg-blue-light text-white border  border-grey-light py-[5px] px-[10px] rounded-sm transition"
              >
                {t('buttons.delate')} 
              </button>
            </div>
          </Form>
        )}
      </Formik>

    </div>
  );
};

export default EmployerEditPage;
