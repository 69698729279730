import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { EmployerProfileAction } from "../redux/Action/appAction";
import ChangeVacancy from "../components/EditVacancy";
import EmployerSidebar from "../components/EmployerSidebar";

const EditVacancy = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(EmployerProfileAction());
    window.scroll(0, 0);
  }, []);

  return (
    <div className="container mx-auto grid grid-cols-12 sm:gap-[30px] my-[30px]">
      {/* SIDEBAR */}
      <div className="col-span-12 order-2">
        <EmployerSidebar />
      </div>
      {/* MAIN */}
      <div className="relative col-span-12">
        <ChangeVacancy />
      </div>
    </div>
  );
};

export default EditVacancy;
