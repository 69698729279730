import { useEffect, useMemo, useRef, useState } from "react";
import { FaUserGraduate } from "react-icons/fa";
import { VscAdd, VscRemove } from "react-icons/vsc";
import Button from "../components/Button";
import Input from "../components/Input";
import { useClickOutside } from "../hooks/clickOutside";
import DatePicker from "react-date-picker";
import Select from "react-select";
import moment from "moment";
import { degrees } from "../static/index";
import axios from "axios";
import { api_url } from "../static/index";
import {useTranslation} from 'react-i18next'
import { useNavigate } from "react-router-dom";

const CVInfoPhoto = ({ photo, setPhoto }) => {
  const {t}=useTranslation();
  return useMemo(() => {
    return (
      <div className="w-full md:w-2/12 mr-[15px] mb-[30px] md:mb-0">
        <div
          style={{
            backgroundImage: `url(${photo && URL.createObjectURL(photo)})`,
          }}
          className={`relative w-full h-[160px] md:h-[250px] bg-cover bg-center flex flex-col justify-center items-center rounded-sm border border-grey-light hover:bg-grey-light cursor-pointer`}
        >
          {!photo ? (
            <>
              <FaUserGraduate className="text-[40px] md:text-[50px] text-grey" />
              <p className="text-grey mt-[10px] lg-text">{t('createCV.photo')}</p>{" "}
            </>
          ) : (
            ""
          )}
          <input
            onChange={(e) => setPhoto(e.target.files[0])}
            type="file"
            className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
          />
        </div>
      </div>
    );
  }, [photo || t('createCV.photo')]);
};

const CreateCV = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  const navigate = useNavigate();
  const [cvPhoto, setCvPhoto] = useState(null);
  // CV INFO
  const [cvInfo, setCvInfo] = useState({
    phone: "",
    email: "",
    current_address: "",
    skill: "",
    foreign_language: "",
    hobby: "",
    description: "",
    porfolio_link: "",
    linkedin: "",
    telegram: "",
  });
  function EmailValidation(value) {
    if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(value)) {
      setCvInfo({ ...cvInfo, email: value });
      setErrEmail(false);
    } else {
      setCvInfo({ ...cvInfo, email: value });
      setErrEmail(true);
    }
  }
  function PhoneValidation(value) {
    if (value.length < 10 && /^[0-9\s]*$/.test(value)) {
      setCvInfo({ ...cvInfo, phone: value });
      setErrPhone(false);
    }
    if (value.length !== 9) {
      setErrPhone(true);
    } else {
      setErrPhone(false);
    }
  }
  async function CreateCV() {
    CVErrorCheck();
    let resume_education = educations.map((education) => {
      return {
        unv_title: education.title,
        specialty: education.speciality,
        begin_date: moment(education.educationDate.start).format("YYYY-MM-DD"),
        finish_date: education.educationDate.still
          ? null
          : moment(education.educationDate.end).format("YYYY-MM-DD"),
        status: education.educationDate.still,
        degree: education.degree,
      };
    });
    let resume_experience = workingPlaces.map((workingPlace) => {
      return {
        title: workingPlace.title,
        specialty: workingPlace.speciality,
        begin_date: moment(workingPlace.workingDate.start).format("YYYY-MM-DD"),
        finish_date: workingPlace.workingDate.still
          ? null
          : moment(workingPlace.workingDate.end).format("YYYY-MM-DD"),
        status: true,
      };
    });
    const formData = new FormData();
    formData.append("image", cvPhoto);
    const data = {
      ...cvInfo,
      resume_education: resume_education,
      resume_experience: resume_experience,
    };
    await axios
      .post(`${api_url}en/api/s1/create-resume`, data)
      .then(res => {
        axios.patch(`${api_url}en/api/s1/resume-update/${res.data.id}/`, formData);
        navigate("/student-profile");
      })
      .catch((err) => {
      });
  }
  // cv errors
  const [errPhone, setErrPhone] = useState(false);
  const [errEmail, setErrEmail] = useState(false);
  const [errCurrent_address, setErrCurrent_address] = useState(false);
  const [errSkill, setErrSkill] = useState(false);
  const [errLang, setErrLang] = useState(false);
  const [errHobby, setErrHobby] = useState(false);
  const [errDescription, setErrDescription] = useState(false);
  function CVErrorCheck() {
    cvInfo.phone === "" ? setErrPhone(true) : setErrPhone(false);
    cvInfo.email === "" ? setErrEmail(true) : setErrEmail(false);
    cvInfo.current_address === ""
      ? setErrCurrent_address(true)
      : setErrCurrent_address(false);
    cvInfo.skill === "" ? setErrSkill(true) : setErrSkill(false);
    cvInfo.foreign_language === "" ? setErrLang(true) : setErrLang(false);
    cvInfo.hobby === "" ? setErrHobby(true) : setErrHobby(false);
    cvInfo.description === ""
      ? setErrDescription(true)
      : setErrDescription(false);
  }
  // MODALS
  const educationModalRef = useRef();
  useClickOutside(educationModalRef, () => {
    setEducationModal(false);
  });
  const workingModalRef = useRef();
  useClickOutside(workingModalRef, () => {
    setWorkingPlaceModal(false);
  });
  const [workingPlaceModal, setWorkingPlaceModal] = useState(false);
  const [educationModal, setEducationModal] = useState(false);
  // WORKING_PLACE
  const [workingPlaces, setWorkingPlaces] = useState([]);
  const [workingPlace, setWorkingPlace] = useState({
    title: "",
    speciality: "",
    workingDate: {
      start: null,
      end: null,
      still: false,
    },
  });
  function SaveWorkingPlace() {
    modalWorkErrorCheck();
    if (
      workingPlace.title !== "" &&
      workingPlace.speciality !== "" &&
      workingPlace.workingDate.start !== null &&
      (workingPlace.workingDate.end !== null ||
        workingPlace.workingDate.still !== false)
    ) {
      setWorkingPlaces([...workingPlaces, workingPlace]);
      setWorkingPlace({
        title: "",
        speciality: "",
        workingDate: {
          start: null,
          end: null,
          still: false,
        },
      });
      setWorkingPlaceModal(false);
    }
  }
  function RemoveWorkingPlace(e) {
    const newWorkingPlaces = workingPlaces.filter(
      (item) => item.workingDate.start !== e
    );
    setWorkingPlaces(newWorkingPlaces);
  }
  // Modal erorr working
  const [errModalWorkingTitle, setErrModalWorkingTitle] = useState(false);
  const [errModalWorkingSpeciality, setErrModalWorkingSpeciality] =
    useState(false);
  const [errModalWorkingDateStart, setErrModalWorkingDateStart] =
    useState(false);
  const [errModalWorkingDateEnd, setErrModalWorkingDateEnd] = useState(false);
  function modalWorkErrorCheck() {
    workingPlace.title === ""
      ? setErrModalWorkingTitle(true)
      : setErrModalWorkingTitle(false);
    workingPlace.speciality === ""
      ? setErrModalWorkingSpeciality(true)
      : setErrModalWorkingSpeciality(false);
    workingPlace.workingDate.start === null
      ? setErrModalWorkingDateStart(true)
      : setErrModalWorkingDateStart(false);
    workingPlace.workingDate.still === true
      ? setErrModalWorkingDateEnd(false)
      : workingPlace.workingDate.end === null
      ? setErrModalWorkingDateEnd(true)
      : setErrModalWorkingDateEnd(false);
  }
  // EDUCATIONS
  const [educations, setEducations] = useState([]);
  const [education, setEducation] = useState({
    title: "",
    speciality: "",
    educationDate: {
      start: null,
      end: null,
      still: false,
    },
    degree: degrees[0].value,
  });
  function SaveEducation() {
    modalEduErrorCheck();
    if (
      education.title !== "" &&
      education.speciality !== "" &&
      education.educationDate.start !== null &&
      (education.educationDate.end !== null ||
        education.educationDate.still !== false)
    ) {
      setEducations([...educations, education]);
      setEducation({
        title: "",
        speciality: "",
        educationDate: {
          start: null,
          end: null,
          still: false,
        },
      });
      setEducationModal(false);
    }
  }
  function RemoveEducation(e) {
    const newEducations = educations.filter(
      (item) => item.educationDate.start !== e
    );
    setEducations(newEducations);
  }
  // Modal erorr education
  const [errModalEduTitle, setErrModalEduTitle] = useState(false);
  const [errModalEduSpeciality, setErrModalEduSpeciality] = useState(false);
  const [errModalEduDateStart, setErrModalEduDateStart] = useState(false);
  const [errModalEduDateEnd, setErrModalEduDateEnd] = useState(false);
  const [errModalEduDegree, setErrModalEduDegree] = useState(false);

  function modalEduErrorCheck() {
    education.title === ""
      ? setErrModalEduTitle(true)
      : setErrModalEduTitle(false);
    education.degree === null
      ? setErrModalEduDegree(true)
      : setErrModalEduDegree(false);
    education.speciality === ""
      ? setErrModalEduSpeciality(true)
      : setErrModalEduSpeciality(false);
    education.educationDate.start === null
      ? setErrModalEduDateStart(true)
      : setErrModalEduDateStart(false);
    education.educationDate.still === true
      ? setErrModalEduDateEnd(false)
      : education.educationDate.end === null
      ? setErrModalEduDateEnd(true)
      : setErrModalEduDateEnd(false);
  }
  const {t}=useTranslation();
  return (
    <div className="">
      <div
        className="container mx-auto flex flex-col md:flex-row my-[30px]"
        data-aos="fade-down"
      >
        {/* photo */}
        <CVInfoPhoto photo={cvPhoto} setPhoto={setCvPhoto} />
        {/* info */}
        <div className="w-full md:w-10/12 md:ml-[15px] flex flex-col">
          {/* phone & birthday */}
          <div className="flex flex-col md:flex-row md:gap-[30px]">
            <div className="w-full md:w-1/2 flex flex-col">
              <label htmlFor="phone" className="lg-text text-grey mb-[5px]">
                {t('createCV.tell')}
                <span className="text-red md-text ml-[2px]">*</span>
              </label>
              <Input
                change={(e) => {
                  PhoneValidation(e.target.value);
                }}
                value={cvInfo.phone}
                type={"tel"}
                id={"phone"}
                placeholder={"99 123 45 67"}
                customClass={`${errPhone && "border-red"}`}
              />
            </div>
            <div className="w-full md:w-1/2 flex flex-col mt-[15px] md:mt-0">
              <label htmlFor="email" className="lg-text text-grey mb-[5px]">
                {t('createCV.mail')}
                <span className="text-red md-text ml-[2px]">*</span>
              </label>
              <Input
                change={(e) => {
                  EmailValidation(e.target.value);
                }}
                value={cvInfo.email}
                type={"email"}
                id={"email"}
                placeholder={"info@info.com"}
                customClass={`${errEmail && "border-red"}`}
              />
            </div>
          </div>
          {/* address */}
          <div className="flex flex-col mt-[15px]">
            <label htmlFor="address" className="lg-text text-grey mb-[5px]">
                {t('createCV.location')}
              <span className="text-red md-text ml-[2px]">*</span>
            </label>
            <Input
              change={(e) => {
                setCvInfo({ ...cvInfo, current_address: e.target.value });
                setErrCurrent_address(false);
              }}
              value={cvInfo.current_address}
              id={"address"}
              placeholder={t('createCV.location_placeholder')}
              customClass={`${errCurrent_address && "border-red"}`}
            />
          </div>
          {/* skill & foreing languages */}
          <div className="flex flex-col md:flex-row md:gap-[30px] mt-[15px]">
            <div className="w-full md:w-1/2 flex flex-col">
              <label htmlFor="skill" className="lg-text text-grey mb-[5px]">
              {t('createCV.skill')}
                <span className="text-red md-text ml-[2px]">*</span>
              </label>
              <Input
                change={(e) => {
                  setCvInfo({ ...cvInfo, skill: e.target.value });
                  setErrSkill(false);
                }}
                value={cvInfo.skill}
                type={"text"}
                id={"skill"}
                placeholder={t('createCV.skill_placeholder')}
                customClass={`${errSkill && "border-red"}`}
              />
            </div>
            <div className="w-full md:w-1/2 flex flex-col mt-[15px] md:mt-0">
              <label htmlFor="lang" className="lg-text text-grey mb-[5px]">
              {t('createCV.lang')}
                <span className="text-red md-text ml-[2px]">*</span>
              </label>
              <Input
                change={(e) => {
                  setCvInfo({ ...cvInfo, foreign_language: e.target.value });
                  setErrLang(false);
                }}
                value={cvInfo.foreign_language}
                type={"text"}
                id={"lang"}
                placeholder={t('createCV.lang_placeholder')}
                customClass={`${errLang && "border-red"}`}
              />
              <span className="sm-text text-blue mt-[5px]">
              {t('createCV.lang_warming')}
              </span>
            </div>
          </div>
          {/* hobby & about me */}
          <div className="flex flex-col md:flex-row md:gap-[30px] mt-[15px]">
            <div className="w-full md:w-1/2 flex flex-col">
              <label htmlFor="hobby" className="lg-text text-grey mb-[5px]">
              {t('createCV.hobby')}
                <span className="text-red md-text ml-[2px]">*</span>
              </label>
              <Input
                change={(e) => {
                  setCvInfo({ ...cvInfo, hobby: e.target.value });
                  setErrHobby(false);
                }}
                value={cvInfo.hobby}
                type={"text"}
                id={"hobby"}
                placeholder={t('createCV.hobby')}
                customClass={`${errHobby && "border-red"}`}
              />
            </div>
            <div className="w-full md:w-1/2 flex flex-col mt-[15px] md:mt-0">
              <label htmlFor="about_me" className="lg-text text-grey mb-[5px]">
              {t('createCV.about_me')}
                <span className="text-red md-text ml-[2px]">*</span>
              </label>
              <textarea
                onChange={(e) => {
                  setCvInfo({ ...cvInfo, description: e.target.value });
                  setErrDescription(false);
                }}
                type="text"
                id="about_me"
                placeholder={t('createCV.about_me_placeholder')}
                className={`${
                  errDescription ? "border-red" : "border-grey-light"
                } focus:outline-blue-light border rounded-sm py-[10px] px-[15px]`}
              />
            </div>
          </div>
          {/* links */}
          <div className="flex flex-col md:flex-row md:gap-[30px] mt-[15px]">
            <div className="w-full md:w-1/2 flex flex-col">
              <label
                htmlFor="portfolio_link"
                className="lg-text text-grey mb-[5px]"
              >
                {t('createCV.portfolio')}
              </label>
              <Input
                change={(e) => {
                  setCvInfo({ ...cvInfo, porfolio_link: e.target.value });
                }}
                value={cvInfo.porfolio_link}
                type={"text"}
                id={"portfolio_link"}
                placeholder={t('createCV.portfolio_placeholder')}
              />
            </div>
            <div className="w-full md:w-1/2 flex flex-col mt-[15px] md:mt-0">
              <label htmlFor="linkedin" className="lg-text text-grey mb-[5px]">
              Linkedin
              </label>
              <Input
                change={(e) => {
                  setCvInfo({ ...cvInfo, linkedin: e.target.value });
                }}
                value={cvInfo.linkedin}
                type={"text"}
                id={"linkedin"}
                placeholder={"https://www.linkedin.com/in/info-info"}
              />
            </div>
            <div className="w-full md:w-1/2 flex flex-col mt-[15px] md:mt-0">
              <label htmlFor="telegram" className="lg-text text-grey mb-[5px]">
              Telegram
              </label>
              <Input
                change={(e) => {
                  setCvInfo({ ...cvInfo, telegram: e.target.value });
                }}
                value={cvInfo.telegram}
                type={"text"}
                id={"telegram"}
                placeholder="https://t.me/Info"
              />
            </div>
          </div>
          {/* education & experience */}
          <div className="flex flex-col md:flex-row md:gap-[30px] mt-[15px]">
            <div className="w-full md:w-1/2 flex flex-col">
              <label htmlFor="" className="lg-text text-grey mb-[5px]">
              {t('createCV.experience')}
                <span className="text-red md-text ml-[2px]">*</span>
              </label>
              {/* experiences list */}
              <div
                className={`${
                  !workingPlaces.length && "hidden"
                } border border-grey-light rounded-sm my-[15px] px-[15px] py-[5px] text-grey`}
              >
                {workingPlaces.length
                  ? workingPlaces.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="my-[10px] flex justify-between items-center"
                        >
                          <div>
                            <h4 className="text-blue lg-text font-semibold">
                              {item.title}
                            </h4>
                            <div className="flex items-end">
                              <p className="md-text mr-[10px]">
                                {item.speciality}
                              </p>
                              <span className="sm-text">
                                {moment(item.workingDate.start).format(
                                  "MM.YYYY"
                                )}
                              </span>
                              <span className="sm-text mx-[3px]">-</span>
                              <span className="sm-text">
                                {item.workingDate.still
                                  ? "H.V"
                                  : moment(item.workingDate.end).format(
                                      "MM.YYYY"
                                    )}
                              </span>
                            </div>
                          </div>
                          <VscRemove
                            onClick={() =>
                              RemoveWorkingPlace(item.workingDate.start)
                            }
                            className="cursor-pointer xl-text hover:text-red-dark"
                          />
                        </div>
                      );
                    })
                  : ""}
              </div>
              <Button
                click={() => setWorkingPlaceModal(true)}
                text={t('createCV.experienceadd')}
                customClass={
                  "border-grey-light bg-blue-light text-white font-semibold flex-row-reverse justify-between items-center py-[10px] px-[15px] hover:text-white focus:outline-teal"
                }
                icon={<VscAdd className="font-semibold" />}
              />
            </div>
            <div className="w-full md:w-1/2 flex flex-col mt-[15px] md:mt-0">
              <label htmlFor="" className="lg-text text-grey mb-[5px]">
              {t('createCV.education')}
                <span className="text-red md-text ml-[2px]">*</span>
              </label>
              {/* education list */}
              <div
                className={`${
                  !educations.length && "hidden"
                } border border-grey-light rounded-sm my-[15px] px-[15px] py-[5px] text-grey`}
              >
                {educations.length
                  ? educations.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="my-[10px] flex justify-between items-center"
                        >
                          <div>
                            <h4 className="text-blue lg-text font-semibold">
                              {item.title}
                            </h4>
                            <div className="flex items-end">
                              <p className="md-text mr-[10px]">
                                {item.speciality}
                              </p>
                              <span className="sm-text">
                                {moment(item.educationDate.start).format(
                                  "MM.YYYY"
                                )}
                              </span>
                              <span className="sm-text mx-[3px]">-</span>
                              <span className="sm-text">
                                {item.educationDate.still
                                  ? "H.V"
                                  : moment(item.educationDate.end).format(
                                      "MM.YYYY"
                                    )}
                              </span>
                            </div>
                          </div>
                          <VscRemove
                            onClick={() =>
                              RemoveEducation(item.educationDate.start)
                            }
                            className="cursor-pointer xl-text hover:text-red-dark"
                          />
                        </div>
                      );
                    })
                  : ""}
              </div>
              <Button
                click={() => setEducationModal(true)}
                text={t('createCV.educationadd')}
                customClass={
                  "border-grey-light bg-blue-light text-white font-semibold flex-row-reverse justify-between items-center py-[10px] px-[15px] hover:text-white focus:outline-teal"
                }
                icon={<VscAdd className="font-semibold" />}
              />
            </div>
          </div>
          <Button
            click={CreateCV}
            text={t('buttons.create')}
            customClass={
              "w-[150px] border-none bg-blue-light text-white py-[10px] mt-[30px] ml-auto"
            }
          />
        </div>
      </div>
      {/* modal working-place */}
      <div
        style={{ backgroundColor: "rgba(0, 0, 0, 0.50)" }}
        className={`${
          !workingPlaceModal && "hidden"
        } fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-50`}
      >
        <div
          ref={workingModalRef}
          className="w-full md:w-auto bg-white border border-grey-light rounded-sm p-[15px]"
        >
          <p className="xl-text font-semibold">{t('createCV.experienceadd')}</p>
          <div className="flex flex-col mt-[15px]">
            <div className="flex flex-col md:flex-row md:gap-[30px]">
              <div className="w-full md:w-1/2 flex flex-col">
                <label
                  htmlFor="work_place"
                  className="lg-text text-grey mb-[5px]"
                >
                  {t('createCV.experiencelocation')}
                </label>
                <Input
                  change={(e) => {
                    setWorkingPlace({
                      ...workingPlace,
                      title: e.target.value,
                    });
                    setErrModalWorkingTitle(false);
                  }}
                  value={workingPlace.title}
                  type={"text"}
                  id={"work_place"}
                  placeholder={"Example kompaniyasi"}
                  customClass={`w-full md:w-[300px] ${
                    errModalWorkingTitle && "border-red"
                  }`}
                />
              </div>
              <div className="w-full md:w-1/2 flex flex-col mt-[15px] md:mt-0">
                <label
                  htmlFor="Specialist"
                  className="lg-text text-grey mb-[5px]"
                >
                  {t('createCV.experiencemajor')}
                </label>
                <Input
                  change={(e) => {
                    setWorkingPlace({
                      ...workingPlace,
                      speciality: e.target.value,
                    });
                    setErrModalWorkingSpeciality(false);
                  }}
                  value={workingPlace.speciality}
                  type={"text"}
                  id={"Specialist"}
                  placeholder={"Manager"}
                  customClass={`w-full md:w-[300px] ${
                    errModalWorkingSpeciality && "border-red"
                  }`}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:gap-[30px] mt-[30px]">
              <div className="w-full md:w-1/2 flex flex-col">
                <label
                  htmlFor="work_place"
                  className="lg-text text-grey mb-[5px]"
                >
                  {t('createCV.experiencestart')}
                </label>
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  locale={"en-EN"}
                  onChange={(value) => {
                    setWorkingPlace({
                      ...workingPlace,
                      workingDate: {
                        ...workingPlace.workingDate,
                        start: value,
                      },
                    });
                    setErrModalWorkingDateStart(false);
                  }}
                  value={workingPlace.workingDate.start}
                  className={`w-full md:w-[300px] ${
                    errModalWorkingDateStart && "border rounded-sm border-red"
                  }`}
                />
              </div>
              <div className="w-full md:w-1/2 flex flex-col mt-[15px] md:mt-0">
                <label
                  htmlFor="Specialist"
                  className="lg-text text-grey mb-[5px]"
                >
                  {t('createCV.experienceend')}
                </label>
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  locale={"en-EN"}
                  onChange={(value) => {
                    setWorkingPlace({
                      ...workingPlace,
                      workingDate: {
                        ...workingPlace.workingDate,
                        end: value,
                      },
                    });
                    setErrModalWorkingDateEnd(false);
                  }}
                  value={workingPlace.workingDate.end}
                  disabled={workingPlace.workingDate.still && true}
                  className={`w-full md:w-[300px] ${
                    errModalWorkingDateEnd && "border rounded-sm border-red"
                  }`}
                />
                <div className="flex">
                  <input
                    onChange={(e) =>
                      setWorkingPlace({
                        ...workingPlace,
                        workingDate: {
                          ...workingPlace.workingDate,
                          still: e.target.checked,
                        },
                      })
                    }
                    checked={workingPlace.workingDate.still}
                    type="checkbox"
                    id="working"
                    className="mt-[5px] rounded-full cursor-pointer mr-[5px]"
                  />
                  <label
                    htmlFor="working"
                    className="sm-text text-grey cursor-pointer mt-[2px]"
                  >
                    {t('createCV.experiencestatus')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <Button
            click={SaveWorkingPlace}
            text={t('buttons.save')}
            customClass={
              "w-[150px] border-none bg-blue-light text-white py-[10px] mt-[30px] ml-auto"
            }
          />
        </div>
      </div>
      {/* modal education */}
      <div
        style={{ backgroundColor: "rgba(0, 0, 0, 0.50)" }}
        className={`${
          !educationModal && "hidden"
        } fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-50`}
      >
        <div
          ref={educationModalRef}
          className="w-full md:w-auto bg-white border border-grey-light rounded-sm p-[15px]"
        >
          <p className="xl-text font-semibold">{t('createCV.educationadd')}</p>
          <div className="flex flex-col mt-[15px]">
            <div className="flex flex-col md:flex-row md:gap-[30px]">
              <div className="w-full md:w-1/2 flex flex-col">
                <label
                  htmlFor="work_place"
                  className="lg-text text-grey mb-[5px]"
                >
                  {t('createCV.educationname')}
                </label>
                <Input
                  change={(e) => {
                    setEducation({ ...education, title: e.target.value });
                    setErrModalEduTitle(false);
                  }}
                  value={education.title}
                  type={"text"}
                  id={"work_place"}
                  placeholder={"TDIU"}
                  customClass={`w-full md:w-[300px] ${
                    errModalEduTitle && "border-red"
                  }`}
                />
              </div>
              <div className="w-full md:w-1/2 flex flex-col mt-[15px] md:mt-0">
                <label
                  htmlFor="Specialist"
                  className="lg-text text-grey mb-[5px]"
                >
                  {t('createCV.educationmajor')}
                </label>
                <Input
                  change={(e) => {
                    setEducation({
                      ...education,
                      speciality: e.target.value,
                    });
                    setErrModalEduSpeciality(false);
                  }}
                  value={education.speciality}
                  type={"text"}
                  id={"Specialist"}
                  placeholder={"Ekonomika"}
                  customClass={`w-full md:w-[300px] ${
                    errModalEduSpeciality && "border-red"
                  }`}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:gap-[30px] mt-[15px]">
              <div className="w-full md:w-1/2 flex flex-col mt-[15px] md:mt-0">
                <label htmlFor="degree" className="lg-text text-grey mb-[5px]">
                {t('createCV.educationlevel')} 
                </label>
                <Select
                  options={degrees}
                  onChange={(e) => {
                    setEducation({
                      ...education,
                      degree: e.value,
                    });
                    setErrModalEduDegree(false);
                  }}
                  className={`${
                    errModalEduDegree && "border rounded-sm border-red"
                  } w-full md:w-[200px]`}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:gap-[30px] mt-[30px]">
              <div className="w-full md:w-1/2 flex flex-col">
                <label htmlFor="degree" className="lg-text text-grey mb-[5px]">
                {t('createCV.educationstart')}
                </label>
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  locale={"en-EN"}
                  onChange={(value) =>
                    setEducation({
                      ...education,
                      educationDate: {
                        ...education.educationDate,
                        start: value,
                      },
                    })
                  }
                  value={education.educationDate.start}
                  className={`w-full md:w-[300px] ${
                    errModalEduDateStart && "border rounded-sm border-red"
                  }`}
                />
              </div>
              <div className="w-full md:w-1/2 flex flex-col mt-[15px] md:mt-0">
                <label
                  htmlFor="Specialist"
                  className="lg-text text-grey mb-[5px]"
                >
                  {t('createCV.educationend')}
                </label>
                <DatePicker
                  dateFormat="yyyy/MM/dd"
                  locale={"en-EN"}
                  onChange={(value) =>
                    setEducation({
                      ...education,
                      educationDate: {
                        ...education.educationDate,
                        end: value,
                      },
                    })
                  }
                  value={education.educationDate.end}
                  disabled={education.educationDate.still && true}
                  className={`w-full md:w-[300px] ${
                    errModalEduDateEnd && "border rounded-sm border-red"
                  }`}
                />
                <div className="flex">
                  <input
                    onChange={(e) =>
                      setEducation({
                        ...education,
                        educationDate: {
                          ...education.educationDate,
                          still: e.target.checked,
                        },
                      })
                    }
                    type="checkbox"
                    id="studying"
                    className="mt-[5px] rounded-full cursor-pointer mr-[5px]"
                  />
                  <label
                    htmlFor="studying"
                    className="sm-text text-grey cursor-pointer mt-[2px]"
                  >
                    {t('createCV.educationstatus')} 
                  </label>
                </div>
              </div>
            </div>
          </div>
          <Button
            click={SaveEducation}
            text={t('buttons.save')}
            customClass={
              "w-[150px] border-none bg-blue-light text-white py-[10px] mt-[30px] ml-auto"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CreateCV;
