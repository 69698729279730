import React, { useEffect } from "react";
import {
  BsFacebook,
  BsTelegram,
  BsInstagram,
  BsYoutube,
  BsLinkedin,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  ApplyVacancyAction,
  EmployerDetailAction,
  EmployersListAction,
  OfferDetailAction,
  ProfileAction,
} from "../redux";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import {useTranslation} from 'react-i18next'
import { api_url2 } from "../static";

const Job = () => {
  const {t}=useTranslation();
  const dispatch = useDispatch();
  const { offer, employerdetail, studentuser, role } = useSelector(
    (state) => state.app
  );
  const { id } = useParams();
  const [isApplyed, setIsApplyed] = React.useState(false);
  useEffect(() => {
    if (role === "Student") {
      const data = studentuser?.vacancy.length
        ? studentuser.vacancy.map((item) => item.id)
        : [];
      const vacancy_id = parseInt(id);
      data?.length && data.includes(vacancy_id)
        ? setIsApplyed(true)
        : setIsApplyed(false);
    }
  }, [studentuser]);
  useEffect(() => {
    dispatch(OfferDetailAction(id));
    dispatch(EmployersListAction());
    window.scroll(0, 0);
  }, []);
  async function Apply() {
    const data = await {
      vacancy_id: id,
    };
    await dispatch(ApplyVacancyAction(data));
    await setTimeout(() => {
      dispatch(ProfileAction());
    }, 500);
  }
  useEffect(() => {
    offer?.employer?.id && dispatch(EmployerDetailAction(offer?.employer?.id));
  }, [offer]);

  return (
    <>
      {offer ? (
        <div
          className="container mx-auto flex flex-col xl:flex-row gap-[30px] my-[30px]"
          data-aos="fade-down"
        >
          {/* MAIN */}
          <main className="w-full flex flex-col">
            {/* top */}
            <div className="min-h-[200px] bg-white-light flex flex-col xs:flex-row items-center">
              {employerdetail?.image && (
                <div
                  style={{ backgroundImage: `url(${employerdetail?.image})` }}
                  className="min-w-[150px] h-[150px] bg-cover bg-center rounded-full mx-[15px]"
                ></div>
              )}
              <div className="xl2-text font-bold ml-[15px] uppercase text-grey text-center xs:text-left">
                {employerdetail?.name}
                <div className="sm-text">{offer?.region}</div>
              </div>
            </div>
            {/* content */}
            <div className="shadow-sm">
              <div className="p-[15px]">
                <div className="lg-text text-grey font-semibold">
                  {offer?.title} ({offer?.category})
                </div>
                {/* reqiurements */}
                <div className="flex flex-col justify-between mt-[15px]">
                  <div>
                    <h3 className="text-grey">Ish tajribasi</h3>
                    <p className="font-semibold">{offer?.experiance}</p>
                  </div>
                  <div className="mt-[15px]">
                    <h3 className="text-grey">Ish turi</h3>
                    <p className="font-semibold">
                      {offer?.job_time_type}, {offer?.online_ofline}
                    </p>
                  </div>
                  <div className="mt-[15px]">
                    <h3 className="text-grey">Ish haqi</h3>
                    <p className="font-semibold">{offer?.salary}</p>
                  </div>
                </div>
                {/* duties */}
                <div>
                  <h3 className="lg-text font-bold mt-[30px] text-grey">
                    Vazifalar
                  </h3>
                  <ul className="text-justify">
                    {offer?.description && parse(offer?.description)}
                  </ul>
                </div>
                {/* contacts */}
                <div>
                  <div className="flex flex-col mt-[15px]">
                    <div>
                      <h3 className="text-grey">Telefon raqam</h3>
                      <p className="font-semibold">{employerdetail?.phone}</p>
                    </div>
                    <div className="mt-[15px]">
                      <h3 className="text-grey">Elektron manzils</h3>
                      <p className="font-semibold">{employerdetail?.email}</p>
                    </div>
                    <div className="mt-[15px]">
                      <h3 className="text-grey">Manzil</h3>
                      <p className="font-semibold">{employerdetail?.address}</p>
                    </div>
                  </div>
                  {/*  */}
                  <div className="flex flex-col">
                    <div className="mt-[15px]">
                      <h3 className="text-grey">Web sahifa</h3>
                      <a
                        href={employerdetail?.website}
                        className="md-text text-blue-light cursor-pointer hover:underline"
                      >
                        {employerdetail?.website}
                      </a>
                    </div>
                    {/* social */}
                    <div className="w-full flex flex-col mt-[15px]">
                      <p className="md-text">
                        Bizning ijtimoiy tarmoqlardagi sahifalar:
                      </p>
                      <ul className="flex items-center mt-[10px]">
                        {employerdetail?.facebook && (
                          <li>
                            <button>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={employerdetail?.facebook}
                              >
                                <BsFacebook className="xl-text mr-[15px] hover:text-blue" />
                              </a>
                            </button>
                          </li>
                        )}
                        {employerdetail?.telegram && (
                          <li>
                            <button>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={employerdetail?.telegram}
                              >
                                <BsTelegram className="xl-text mr-[15px] hover:text-blue" />
                              </a>
                            </button>
                          </li>
                        )}
                        {employerdetail?.linkedin && (
                          <li>
                            <button>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={employerdetail?.linkedin}
                              >
                                <BsLinkedin className="xl-text mr-[15px] hover:text-blue" />
                              </a>
                            </button>
                          </li>
                        )}
                        {employerdetail?.instagram && (
                          <li>
                            <button>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={employerdetail?.instagram}
                              >
                                <BsInstagram className="xl-text mr-[15px] hover:text-red" />
                              </a>
                            </button>
                          </li>
                        )}
                        {employerdetail?.youtube && (
                          <li>
                            <button>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={employerdetail?.youtube}
                              >
                                <BsYoutube className="xl-text mr-[15px] hover:text-red" />
                              </a>
                            </button>
                          </li>
                        )}
                      </ul>
                      <div>
                      </div>
                    </div>
                    <div className="w-full flex justify-center xs:justify-end mt-[15px]">
                      {role === "Student" &&
                        (studentuser?.student_resume !== null ? (
                          <button
                            onClick={Apply}
                            className={`${
                              isApplyed
                                ? "bg-red-dark hover:bg-red"
                                : "bg-blue-light hover:bg-blue-white"
                            } w-[200px] text-white py-[5px]`}
                          >
                            {isApplyed ? "Cancle" : "Apply"}
                          </button>
                        ) : (
                          <div className="text-red">
                            please upload your resume to apply
                            <Link
                              to={"/createCv"}
                              className="text-blue-light hover:underline hover:text-blue-white"
                            >
                              {" "}
                              create resume
                            </Link>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* applyed Students */}
            {role === "Employer" ? (
              <div>
                <h3 className={`${!offer?.applier?.length && 'hidden'} text-grey`}>Applyed Students</h3>
                <div>
                  <ul>
                    {offer?.applier?.length
                      ? offer?.applier?.map((student) => {
                          return (
                            <li key={student.id}>
                              <Link
                                to={`/candidates/cv${student.id}`}
                                className="flex items-center my-[5px] group"
                              >
                                <div
                                  style={{
                                    backgroundImage: `url(${
                                      api_url2 + student.image
                                    })`,
                                  }}
                                  className="min-w-[50px] min-h-[50px] rounded-full bg-cover bg-center"
                                ></div>
                                <div className="ml-[5px] md-text text-grey group-hover:text-blue-light">
                                  {student.full_name}
                                </div>
                              </Link>
                            </li>
                          );
                        })
                      : ""}
                  </ul>
                </div>
              </div>
            ) : (
              ""
            )}
          </main>
        </div>
      ) : (
        " "
      )}
    </>
  );
};

export default React.memo(Job);
