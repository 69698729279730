import React, {useRef} from "react";
import { useClickOutside } from "../../hooks/clickOutside";

const ViewImage = ({ image, calbackFunc }) => {
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    calbackFunc(false);
  });

  return (
    <div
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-100"
    >
      <div
        ref={wrapperRef}
        className="w-full sm:w-2/3 h-2/3 sm:h-4/5 lg:h-full bg-center bg-cover"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    </div>
  );
};

export default ViewImage;
