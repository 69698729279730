import React, { useEffect, useMemo } from "react";
import { BiShowAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { NewsDetailAction } from "../redux";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import {useTranslation} from 'react-i18next'

const NewsById = () => {
  const {t}=useTranslation();
  const dispatch = useDispatch();
  const { newsdetail } = useSelector((state) => state.ghost);
  const { id } = useParams();
  useEffect(() => {
    dispatch(NewsDetailAction(id, t('header.lang')));
  }, [t('header.lang')]);
  return useMemo(() => {
    return (
      <div className="container mx-auto my-[30px]" data-aos="fade-down">
        <h1 className="flex justify-between items-center xl-text p-[15px] border border-t-0 border-r-0 border-teal">
          {newsdetail.title}
          <span className="sm-text text-grey">{newsdetail.date}</span>
          <span className=" flex sm-text text-grey mr-3">
            <BiShowAlt className="text-2xl text-[#1474B2] mr-2" />
            {newsdetail.views}
          </span>
        </h1>
        <div className="border border-t-0 border-l-0 border-teal p-[15px]">
          {newsdetail.description && parse(newsdetail.description)}
        </div>
      </div>
    );
  });
};

export default React.memo(NewsById);
