import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { api_url } from "../../static";
import { useTranslation } from "react-i18next";

const Poll = () => {
  const { t } = useTranslation();
  const [poll, setPoll] = useState([]);
  const opinionRef = useRef();
  useEffect(() => {
    axios
      .get(`${api_url}${t("header.lang")}/api/p1/poll-list`)
      .then((res) => {
        setPoll(res.data)
      })
      .catch((err) => console.log(err));
  }, [t("header.lang")]);
  const FetchPoll = () => {
    if (selected !== null || multiple.length) {
      const postOptions = selected ? selected : multiple;
      axios
        .post(`${api_url}en/api/p1/vote/`, {
          option: postOptions,
          poll: poll[0].id,
          description: opinionRef.current.value,
        })
        .then(() => {
          setShowPoll(false);
          sessionStorage.setItem("pollEnd", null);
        })
        .catch((err) => console.log(err));
    }
  };
  const [selected, setSelected] = useState(null);
  const [multiple, setMultiple] = useState([]);
  const [showPoll, setShowPoll] = useState(true);
  useEffect(() => {
    sessionStorage.getItem("pollEnd") === "null" && setShowPoll(false);
  }, [sessionStorage.getItem("pollEnd")]);

  return (
    <div
      className={`${
        sessionStorage.getItem("pollEnd") === "null" || !poll.length
          ? "hidden"
          : showPoll
          ? "translate-y-0"
          : "-translate-y-full"
      } overflow-hidden transition-all duration-500`}
    >
      <h4 className="xl-text text-grey font-bold p-[10px] bg-grey-light rounded-t-sm">
        {poll[0]?.title}
      </h4>
      <div className="h-min border border-grey-light rounded-b-sm p-[5px]">
        <p className="lg-text text-grey font-semibold mb-[10px]">
          {poll[0]?.description}
        </p>
        {poll[0]?.options?.length
          ? poll[0]?.options.map((question, i) => {
            return (
                <div
                  onClick={() => {
                    if (!poll[0]?.is_multiple) {
                      setSelected(question.id);
                    } else {
                      multiple.includes(question.id)
                        ? setMultiple(
                            multiple.filter((item) => item !== question.id)
                          )
                        : setMultiple([...multiple, question.id]);
                    }
                  }}
                  key={i}
                  className={`flex items-center cursor-pointer group`}
                >
                  <div
                    className={`${
                      selected === question.id && "bg-blue-light"
                    } ${
                      multiple.includes(question.id) && "bg-blue-light"
                    } min-w-[15px] min-h-[15px] border border-grey-light rounded-full group-hover:border-blue-light`}
                  ></div>
                  <div className="ml-[5px] md-text text-grey group-hover:text-blue-light">
                    {question.title}
                  </div>
                </div>
              );
            })
          : ""}
        <textarea
          name="description"
          id="description"
          rows="3"
          ref={opinionRef}
          className="w-full border border-grey-light focus:outline-blue-light mt-[10px] p-[5px]"
          placeholder="Opinion (optional)"
        ></textarea>
        <button
          onClick={FetchPoll}
          className={`${
            selected || multiple.length
              ? "bg-blue-light text-white"
              : "bg-grey text-white"
          } w-full xs:w-1/2 lg:w-full py-[5px] text-grey mt-[10px] rounded-sm mx-auto block`}
        >
          {t("buttons.vote")}
        </button>
      </div>
    </div>
  );
};

export default Poll;
