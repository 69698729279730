import { useEffect } from "react";
import { VscChevronLeft } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { EmployerProfileAction, LoginAction, ProfileAction } from "../redux";
import Dropdown from "../components/DropDown";
import uzFlag from "../assets/imgs/uz-flag.svg";
import ruFlag from "../assets/imgs/ru-flag.svg";
import enFlag from "../assets/imgs/en-flag.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import "react-toastify/ReactToastify.min.css";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import {useTranslation} from 'react-i18next'

const Register = () => {
  const {t, i18n}=useTranslation();
  const dispatch = useDispatch();
  const { ghost } = useSelector((state) => state);
  const role = useSelector((state) => state.app.role);
  const langs = [
    {
      Id: 0,
      title: "O'zbekcha",
      flag: uzFlag,
      value: "uz"
    },
    {
      Id: 1,
      title: "Русский",
      flag: ruFlag,
      value: "ru"
    },
    {
      Id: 2,
      title: "English",
      flag: enFlag,
      value: "en"
    },
  ];

  const navigate = useNavigate();
  const requestSend = (loginState, navigate) => {
    dispatch(LoginAction(loginState, navigate, t));
    };

  useEffect(() => {
    role === "Student" ? dispatch(ProfileAction()) : role === "Employer" && dispatch(EmployerProfileAction())
  },[role])
  const changeLanguage =(lang)=>{
    i18n.changeLanguage(lang);
}
  return ghost.loading ? (
    <Loading />
  ) : (
    <div className="w-screen h-screen bg-white-light">
      <div className="page-animation fixed top-0 left-0 w-screen h-screen bg-white-light flex flex-col items-center z-50">
        {/* top */}
        <div className="w-[90%] sm:w-[70%] md:w-[600px] h-min mt-[50px] flex justify-between">
          <Link to="/" className="flex items-center cursor-pointer group">
            <VscChevronLeft className="xl-text group-hover:-translate-x-[3px] group-hover:text-grey transition-all" />
            <p className="lg-text group-hover:text-grey">{t('login.home')}</p>
          </Link>
          <Dropdown langs={langs} icon="down" onChange={changeLanguage} />
        </div>
        {/*  */}
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().required("email is required"),
            password: Yup.string().required("password number is required"),
          })}
          onSubmit={(fields) => {
            requestSend(fields, navigate);
          }}
        >
          {({ errors, touched }) => (
            <Form className="w-[90%] sm:w-[70%] md:w-[600px] mt-[30px]">
              <div className="w-full">
                <label className="text-grey font-semibold" htmlFor="email">
                {t('login.email')}
                </label>
                <Field
                  autoComplete="on"
                  name="email"
                  type="text"
                  placeholder={t('login.email')}
                  className={
                    "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.email && touched.email ? " border-red" : "")
                  }
                />
              </div>
              <div className="w-full mt-[15px]">
                <label className="text-grey font-semibold" htmlFor="password">
                {t('login.password')}
                </label>
                <Field
                  autoComplete="on"
                  name="password"
                  type="password"
                  placeholder="********"
                  className={
                    "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.password && touched.password ? " border-red" : "")
                  }
                />
              </div>
              {/* submit */}
              <div className="col-span-1 lg:col-span-2 mt-[15px]">
                <button
                  type="submit"
                  className="w-full bg-blue-white hover:bg-blue-light text-white border mr-[15px] border-grey-light py-[10px] px-[10px] rounded-sm transition"
                >
                  {t('header.button')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default Register;
