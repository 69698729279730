const Button = ({ icon = null, customClass, width = "", text, click, type }) => {
  
  return (
    <button
      onClick={click}
      type={type}
      className={`${customClass} flex justify-center items-center hover:bg-blue-white border border-grey-light rounded-sm transition ease-in delay-75 hover:translate-y-[-2px]`}
    >
      {icon && icon}
      <p className="px-[5px]">{text}</p>
    </button>
  );
};

export default Button;
