import React from "react";
import { Link } from "react-router-dom";
import {useTranslation} from 'react-i18next'
import telegamBg from "../../assets/imgs/tg-bg.svg";

const Sidebar = ({apply}) => {
  const {t}=useTranslation();
  return (
    <div className="w-full">
      {apply && (
        <div className="w-full flex flex-col rounded-sm p-[15px] bg-blue-light bg-center shadow-lg bg-cover text-white">
          <div className="">{t('sidebar.sidebartext1')}</div>
          <Link
            to="/apply"
            className="sm-text text-blue-light w-max rounded-sm bg-white hover:bg-opacity-30 hover:text-white mt-[5px] p-[3px] px-[15px]"
          >
             {t('buttons.write')}
          </Link>
        </div>
      )}
      {/*  */}
      <div
        style={{ backgroundImage: `url(${telegamBg})` }}
        className="w-full flex flex-col rounded-sm p-[15px] bg-blue bg-center shadow-lg bg-cover text-white mt-[15px]"
      >
        <div className="">{t('sidebar.sidebartext2')}</div>
        <a
          href="https://t.me/s/tsueuzofficial"
          className="sm-text text-blue-light w-max rounded-sm bg-white hover:bg-opacity-30 hover:text-white mt-[5px] p-[3px]"
        >
          {t('buttons.subscribe')}
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
