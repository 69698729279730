import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmployerProfileAction} from "../redux/Action/appAction";
import { BsFacebook, BsInstagram, BsLinkedin, BsTelegram, BsYoutube} from "react-icons/bs";
import parse from "html-react-parser";
import EmployerSidebar from "../components/EmployerSidebar";
import { useTranslation } from "react-i18next";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

const EmployerPage = () => {
  const { t } = useTranslation();
  const employer = useSelector((state) => state.app.employeruser);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(EmployerProfileAction());
    window.scroll(0, 0);
  }, []);

  return (
    <div className="container mx-auto grid grid-cols-12 sm:gap-[30px] my-[30px]">
      {/* SIDEBAR */}
      <div className="col-span-12 order-2">
        <EmployerSidebar />
      </div>
      {/* MAIN */}
      <div className="relative col-span-12">
        {/* edit */}
        <Link to={"/employer-edit"} className="absolute top-0 right-0 p-[5px]">
          <FaRegEdit className="text-grey xl-text hover:text-blue-light cursor-pointer" />
        </Link>
        <main className="w-full flex flex-col">
          {/* top */}
          <div className="min-h-[200px] bg-white-light flex flex-col xs:flex-row items-center">
            <img
              src={employer?.image}
              alt="Company-logo"
              className="w-[150px] h-auto mx-[15px]"
            />
            <div className="xl2-text font-bold ml-[15px] uppercase text-grey text-center xs:text-left">
              {employer?.name}
            </div>
          </div>
          {/* content */}
          <div className="shadow-sm p-[30px]">
            <p className="md-text text-grey mt-[15px]">
              {employer?.description && parse(employer?.description)}
            </p>
            <div className="flex flex-col mt-[15px]">
              <div>
                <h3 className="text-grey">{t("contacts.phone")}</h3>
                <p className="font-semibold">{employer?.phone}</p>
              </div>
              <div className="mt-[15px]">
                <h3 className="text-grey">{t("contacts.mail")}</h3>
                <p className="font-semibold">{employer?.email}</p>
              </div>
              <div className="mt-[15px]">
                <h3 className="text-grey">{t("contacts.address")}</h3>
                <p className="font-semibold">{employer?.address}</p>
              </div>
            </div>
            {/* social */}
            <div className="w-full flex flex-col mt-[15px]">
              <p className="md-text">{t("footer")}</p>
              <ul className="flex items-center mt-[10px]">
                {employer?.facebook && (
                  <li>
                    <button>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={employer?.facebook}
                      >
                        <BsFacebook className="xl-text mr-[15px] hover:text-blue" />
                      </a>
                    </button>
                  </li>
                )}
                {employer?.telegram && (
                  <li>
                    <button>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={employer?.telegram}
                      >
                        <BsTelegram className="xl-text mr-[15px] hover:text-blue" />
                      </a>
                    </button>
                  </li>
                )}
                {employer?.linkedin && (
                  <li>
                    <button>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={employer?.linkedin}
                      >
                        <BsLinkedin className="xl-text mr-[15px] hover:text-blue" />
                      </a>
                    </button>
                  </li>
                )}
                {employer?.instagram && (
                  <li>
                    <button>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={employer?.instagram}
                      >
                        <BsInstagram className="xl-text mr-[15px] hover:text-red" />
                      </a>
                    </button>
                  </li>
                )}
                {employer?.youtube && (
                  <li>
                    <button>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={employer?.youtube}
                      >
                        <BsYoutube className="xl-text mr-[15px] hover:text-red" />
                      </a>
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default EmployerPage;
