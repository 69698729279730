import React from "react";
import AddVacancy from "../components/CreateVacancy";
import EmployerSidebar from "../components/EmployerSidebar";

const CreateVacancy = () => {
  return (
    <div className="container mx-auto grid grid-cols-12 sm:gap-[30px] my-[30px]">
      {/* SIDEBAR */}
      <div className="col-span-12 order-2">
        <EmployerSidebar />
      </div>
      {/* MAIN */}
      <div className="relative col-span-12">
        <AddVacancy />
      </div>
    </div>
  );
};
export default CreateVacancy;