import React, { useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { CategoryAction, VRegionAction, OfferCreateAction, EmployerProfileAction } from "../../redux/Action/appAction";
import { job_experiance, job_remote, job_time } from "../../static";
import { useNavigate } from "react-router-dom";
import {useTranslation} from 'react-i18next'


const CreateVacancy = () => {
  const {t}=useTranslation();
  const navigate = useNavigate();
  const categoryList = useSelector((state) => state.app.category.results);
  const regionList = useSelector((state) => state.app.region.results);
  const dispatch = useDispatch();
  const salaryMask = [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
  ];
  const job_intern = [
    {
      name: "Bor",
      value: true,
    },
    {
      name: "Yo'q",
      value: false,
    },
  ];
  useEffect(() => {
    dispatch(CategoryAction());
    dispatch(VRegionAction());
  }, []);
  useEffect(() => {
    dispatch(EmployerProfileAction());
  }, []);


  

  return (
    <div className="container mx-auto mb-[30px]">
      <h2 className="xl-text text-grey font-bold mb-[15px]">
        {t('createVacancy.addvacancy')}
      </h2>
      <div>
        {categoryList && regionList && <Formik
          initialValues={{
            title: "",
            salary: "",
            description: "",
            job_time_type: job_time[0]?.value,
            online_ofline: job_remote[0]?.value,
            internship: job_intern[0]?.value,
            experiance: job_experiance[0]?.value,
            region: regionList[0]?.id,
            category: categoryList[0]?.id,
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required("required"),
            salary: Yup.number().required("required"),
            description: Yup.string().required("required"),
            category: Yup.string().required("required"),
            region: Yup.string().required("required"),
            job_time_type: Yup.string().required("required"),
            online_ofline: Yup.string().required("required"),
            internship: Yup.string().required("required"),
            experiance: Yup.string().required("required"),
          })}
          onSubmit={(fields) => {
            dispatch(OfferCreateAction({...fields}, t));
            navigate("/employer-profile");
          }}
        >
          {({ errors, touched }) => (
            <Form className="grid grid-cols-1 md:grid-cols-2 gap-[15px]">
              {/* category select */}
              <div>
                <label className={`text-grey font-semibold`} htmlFor="category">
                  {t('changeVacancy.jobcategory')}
                </label>
                <Field
                  name="category"
                  type="text"
                  className={
                    "w-full form-control bg-white text-grey focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.category && touched.category ? " border-red" : "")
                  }
                  as="select"
                >
                  {categoryList?.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div>
                <label className={`text-grey font-semibold`} htmlFor="region">
                {t('changeVacancy.region')}
                </label>
                <Field
                  name="region"
                  type="text"
                  className={
                    `w-full form-control bg-white text-grey focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]` +
                    (errors.region && touched.region ? " border-red" : "")
                  }
                  as="select"
                >
                  {regionList?.map((region) => {
                    return (
                      <option key={region.id} value={region.id} >
                        {region.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="col-span-1">
                <label className={`text-grey font-semibold`} htmlFor="title">
                  {t('changeVacancy.title')}
                </label>
                <Field
                  name="title"
                  type="text"
                  placeholder={t('changeVacancy.title')}
                  className={
                    "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.title && touched.title ? " border-red" : "")
                  }
                />
              </div>
              <div className="col-span-1">
                <label className={`text-grey font-semibold`} htmlFor="salary">
                  {t('changeVacancy.salary')}
                </label>
                <Field
                  name="salary"
                  type="number"
                  placeholder={t('changeVacancy.salary')}
                  className={
                    "w-full form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.email && touched.email ? " border-red" : "") +
                    (errors.salary && touched.salary ? " border-red" : "")
                  }
                />
              </div>
              <div className="col-span-1">
                <label
                  className={`text-grey font-semibold" htmlFor="address`}
                  htmlFor="job_time_type"
                >
                  {t('changeVacancy.employmenttype')}
                </label>
                <Field
                  name="job_time_type"
                  type="text"
                  className={
                    "w-full form-control bg-white text-grey focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.job_time_type && touched.job_time_type
                      ? " border-red"
                      : "")
                  }
                  as="select"
                >
                  {job_time?.map((time) => {
                    return (
                      <option key={time.value} value={time.value}>
                        {time.label}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="col-span-1">
                <label
                  className={`text-grey font-semibold"`}
                  htmlFor="online_ofline"
                >
                  {t('changeVacancy.jobtype')}
                </label>
                <Field
                  name="online_ofline"
                  type="text"
                  placeholder={t('changeVacancy.jobtype')}
                  className={
                    "w-full form-control bg-white text-grey focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.online_ofline && touched.online_ofline
                      ? " border-red"
                      : "")
                  }
                  as="select"
                >
                  {job_remote?.map((job) => {
                    return (
                      <option key={job.value} value={job.value}>
                        {job.label}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="col-span-1">
                <label
                  className={`text-grey font-semibold`}
                  htmlFor="internship"
                >
                  {t('changeVacancy.practice')}
                </label>
                <Field
                  name="internship"
                  type="text"
                  placeholder={t('changeVacancy.practice')}
                  className={
                    "w-full form-control bg-white text-grey focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.internship && touched.internship
                      ? " border-red"
                      : "")
                  }
                  as="select"
                >
                  {job_intern?.map((intern) => {
                    return (
                      <option key={intern.value} value={intern.value}>
                        {intern.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="col-span-1">
                <label
                  className={`text-grey font-semibold`}
                  htmlFor="experiance"
                >
                  {t('changeVacancy.experience')}
                </label>
                <Field
                  name="experiance"
                  type="text"
                  placeholder={t('changeVacancy.experience')}
                  className={
                    "w-full form-control bg-white text-grey focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.experiance && touched.experiance
                      ? " border-red"
                      : "")
                  }
                  as="select"
                >
                  {job_experiance?.map((experiance) => {
                    return (
                      <option key={experiance.value} value={experiance.value}>
                        {experiance.label}
                      </option>
                    );
                  })}
                </Field>
              </div>
              <div className="col-span-1">
                <label
                  className={`text-grey font-semibold`}
                  htmlFor="description"
                >
                  {t('changeVacancy.definition')}
                </label>
                <Field
                  name="description"
                  type="text"
                  as="textarea"
                  placeholder={t('changeVacancy.definition')}
                  className={
                    "w-full h-[250px] form-control focus:outline-blue-light border border-grey-light rounded-sm py-[10px] px-[15px]" +
                    (errors.description && touched.description
                      ? " border-red"
                      : "")
                  }
                />
              </div>
              {/* submit */}
              <div className="col-span-1 lg:col-span-2">
                <button
                  type="submit"
                  className="bg-blue-white hover:bg-blue-light text-white border mr-[15px] border-grey-light py-[5px] px-[10px] rounded-sm transition"
                >
                  {t('buttons.add')}
                </button>
                <button
                  type="reset"
                  className="bg-blue-white hover:bg-blue-light text-white border  border-grey-light py-[5px] px-[10px] rounded-sm transition"
                >
                  {t('buttons.clear')}
                </button>
              </div>
            </Form>
          )}
        </Formik>  
        }
      </div>
    </div>
  );
};

export default CreateVacancy;
